import React, {useEffect, useState} from "react";
import {Trash2} from "react-feather";
import {useDispatch, useSelector} from "react-redux";
import MoneyFormat from "../../layout/MoneyFormat";
import {getMaterialSpends} from "../../../actions/material";
import {Link} from "react-router-dom";
import {getForProductProductions, setDeleteProductProductionId} from "../../../actions/product";
import CustomPagePagination from "../../layout/CustomPagePagination";

const ProductProductions = () => {
    const dispatch = useDispatch();
    const {
        product,
        product_productions
    } = useSelector(state => state.product);

    const {data, meta } = product_productions;

    const {id} = product;

    useEffect(() => {
        if (id) {
            dispatch(getMaterialSpends(id, {}));
        }
    }, [getMaterialSpends, id]);

    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const setDeleteProduction = (id) => {
        dispatch(setDeleteProductProductionId(id, product_productions.data));
    };


    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('from_date', event.target.value);
        url.searchParams.set('to_date', to_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getForProductProductions(id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('to_date', event.target.value);
        url.searchParams.set('from_date', from_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getForProductProductions(id, params))
    };

    return (
        <>
            <div className="w-100 table-responsive px-2">
                <div className="mb-2 align-items-center d-flex
                                                        justify-content-between">
                    <div className="mb-3">
                        <label htmlFor="date1"
                               className="mb-2">dan</label>
                        <input onChange={onChangeFromDate}
                               className="form-control"
                               id="date1" type="date"/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date2"
                               className="mb-2">gacha</label>
                        <input onChange={onChangeToDate}
                               className="form-control"
                               id="date2" type="date"/>
                    </div>
                </div>

                <table className="table custom-table table-hover">
                    <thead className="table-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Sklad nomi</th>
                        <th scope="col">Chiqaruvchi</th>
                        <th scope="col">Miqdori</th>
                        <th scope="col">Tan narxi(so'm)</th>
                        <th scope="col">Summa(so'm)</th>
                        <th scope="col">Sana</th>
                        <th scope="col">Amallar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data?.map((item, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <Link
                                        to={`/warehouses/${product?.warehouse?.id}`}>
                                        {product?.warehouse?.name}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={`/users/${item?.producer?.id}`}>
                                        {item?.producer?.name}
                                    </Link>

                                </td>
                                <td>
                                    {item?.quantity}
                                </td>
                                <td>
                                    <MoneyFormat
                                        value={item?.net_price || 0}
                                        thousandSeparator={true}
                                    />
                                </td>
                                <td>
                                    <MoneyFormat
                                        value={item?.net_price * item?.quantity || 0}
                                        thousandSeparator={true}
                                    />
                                </td>
                                <td>{item.date}</td>
                                <td>
                                    <div className="actions">
                                        <Trash2
                                            onClick={() => setDeleteProduction(item.id)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    <tr key={"total_productions"}>
                        <th scope="row">*</th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            {data?.reduce((acc, cur) => acc + cur.quantity, 0)}
                        </td>
                        <td>
                            *
                        </td>
                        <td>
                            <MoneyFormat
                                value={data?.reduce((acc, cur) => acc + cur.net_price * cur.quantity, 0) || 0}
                                thousandSeparator={true}
                                decimal={0}
                            />
                        </td>
                        <td>-</td>
                        <td>Jami</td>
                    </tr>
                    </tbody>
                </table>

                <CustomPagePagination
                    numberOfPages={meta?.last_page}
                    getPageItems={getForProductProductions}
                    current_page={meta?.current_page}
                />

            </div>
        </>
    )
}

export default ProductProductions;