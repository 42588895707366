import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {getFirm} from "../../actions/firms";

const FirmPage = () => {

    useEffect(() => {
        dispatch(getFirm(id));
    }, [getFirm]);

    const dispatch = useDispatch();

    const {id} = useParams();

    const {firm} = useSelector(state => state.firm);

    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Firma</strong> ma'lumotlari: </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className="d-flex align-items-center">
                                                <div className="card-title m-2">Firma nomi:</div>
                                                <div className="text-muted m-0">{firm?.name}</div>
                                            </div>

                                            <div className="d-flex">
                                                <div className="card-title m-2">Manzili:</div>
                                                <div className="text-muted text-start m-2">{firm?.address}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-header border-top">
                                    <h5 className="card-title mb-0">Firma boshqaruvchisi:</h5>
                                </div>
                                <div className="card-body text-center">
                                    <div className="row">
                                        <div className="col-md-12 ms-2">

                                            <div className="d-flex align-items-center">
                                                <div className="card-title m-2">Ismi:</div>
                                                <div className="text-muted m-0">{firm?.manager?.name}</div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div className="card-title m-2">Email:</div>
                                                <div className="text-muted m-0">{firm?.manager?.email}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12 col-lg-8">
                            <h4 className="h4 mb-3"> skladlar </h4>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-body p-0 w-100 table-responsive">
                                    <table className="table custom-table table-hover">
                                        <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nomi</th>
                                            <th scope="col">Skladchi</th>
                                            <th scope="col">Sklad turi</th>
                                        </tr>
                                        </thead>

                                        <tbody className="p-0 m-0">

                                        {
                                            firm?.warehouses?.map((item, index) => (
                                                <tr key={`warehouse${index}`}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.name}</td>
                                                    <td>{item.storekeeper?.name}</td>
                                                    <td>{item.type}</td>
                                                </tr>
                                            ))
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">

                        <div className="col-md-12 col-lg-8">
                            <h4 className="h4 mb-3"> xodimlar </h4>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-body p-0 w-100 table-responsive">
                                    <table className="table custom-table table-hover">
                                        <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Ism</th>
                                            <th scope="col">Familiya</th>
                                            <th scope="col">INN</th>
                                            <th scope="col">Manzil</th>
                                            <th scope="col">Telefon</th>
                                            <th scope="col">Kasbi</th>
                                            {/*<th scope="col">Balans</th>*/}
                                            <th scope="col">Holati</th>
                                        </tr>
                                        </thead>

                                        <tbody className="p-0 m-0">

                                        {
                                            firm?.employees?.map((item, index) => (
                                                <tr key={`employee${index}`}>
                                                    <th scope="row">{index + 1} </th>
                                                    <td>{item.first_name}</td>
                                                    <td>{item.last_name}</td>
                                                    <td>{item.inn}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.profession}</td>
                                                    {/*<td>{item.balance}</td>*/}
                                                    <td>{item?.status == 'hired' ? 'Faol' : 'Ishlamayapti'}</td>
                                                </tr>
                                            ))
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </div>
    );
};

export default FirmPage;