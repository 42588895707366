import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {getProductTemplates} from "../../actions/product_template";
import ProductTemplateCreateForm from "./ProductTemplateCreateForm";
import ProductTemplateItem from "./ProductTemplateItem";
import CustomPagination from "../layout/CustomPagination";
import ProductTemplateDeleteForm from "./ProductTemplateDeleteForm";
import ProductTemplateUpdateForm from "./UpdateTemplateUpdateForm";
import SearchForm from "../layout/SearchForm";
// import { useHistory, useLocation } from "react-router-dom";
// import {Accordion} from "react-bootstrap";
// import {AvField, AvForm} from "availity-reactstrap-validation";
// import Button from "react-bootstrap/Button";
// import {Multiselect} from "multiselect-react-dropdown";
// import {ArrowLeft, FilePlus} from "react-feather";

const ProductTemplates = ({getProductTemplates, product_template}) => {

    const {product_templates, loading, numberOfPages, product_template_units, currentPage} = product_template;

    const [show, setShow] = useState(false);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>

            <main className="content">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3">Ishlab chiqarish mahsulot<strong> shablonlari</strong></h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <ProductTemplateCreateForm/>
                        </div>

                    </div>

                    <h4>Mahsulotlar shablonlar ro'yxati</h4>


                    <div className="row mb-2">
                        <SearchForm getPageItems={getProductTemplates}/>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 d-flex">

                            <div className="card w-100 table-responsive">
                                <table className="table custom-table table-hover">
                                    <thead className="table-light">
                                    <tr>
                                        <th scope="col ">#</th>
                                        <th scope="col">Shablon</th>
                                        <th scope="col">Tayyorlanadian mahsulotlar</th>
                                        <th scope="col">Amallar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        product_templates.map((item, key) => (
                                            <ProductTemplateItem key={key} product_template={item}/>
                                        ))
                                    }

                                    </tbody>
                                </table>

                            </div>

                        </div>


                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 d-flex">
                                <CustomPagination
                                    numberOfPages={numberOfPages}
                                    getPageItems={getProductTemplates}
                                    current_page={currentPage}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </main>

            <ProductTemplateDeleteForm/>

            <ProductTemplateUpdateForm/>


        </Fragment>
    );
};


ProductTemplates.propTypes =
    {
        getProductTemplates: PropTypes.func.isRequired,
        product_template: PropTypes.object.isRequired,
    };

const mapStateToProps = (state) => (
    {
        product_template: state.product_template,
    }
);

export default connect(mapStateToProps,
    {
        getProductTemplates
    }
)(ProductTemplates);
