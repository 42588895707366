import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addUser, getUserRolIdData, clearErrors, getFirmOptions} from '../../actions/user';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {getFirmIdData} from "../../actions/employee";
import {getParams} from "../../utils/hooks/getParams";


const UserCreateForm = ({addUser, getUserRolIdData, getFirmOptions, handleShow, handleClose, show, user}) => {

    const {error, users, loading, firm_options} = user || {};

    useEffect(() => {
        getFirmOptions();
    }, [getFirmOptions]);

    const defaultValues = {
        name: '',
        email: '',
        role_id: '',
        password: ''
    };
    const [inputs, setInputs] = useState(defaultValues);

    const [errors, setErrors] = useState();

    const formRef = useRef();

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [users]);

    useEffect(() => {
        getUserRolIdData();
    }, [getUserRolIdData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        addUser(inputs, getParams());
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Yangi foydalanuvchi qo'shish</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <select name="firm_id"
                                    value={inputs?.firm_id || ''}
                                    id="firm_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    firm_options.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name">Ism</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email">Email</label>
                            <input
                                name="email"
                                className={'form-control'}
                                type="email"
                                value={inputs?.email || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.email}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password">Parol</label>
                            <input
                                name="password"
                                className={'form-control'}
                                type="password"
                                value={inputs?.password || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.password}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="role_id">Rol</label>
                            <select className="form-control"
                                    name={'role_id'} id={'role_id'}
                                    onChange={handleChange}
                                    value={inputs?.role_id || ''}
                                    onKeyUp={e => e.target.oldValue = e.target.value}>
                                <option value={''}>Tanlang</option>
                                {
                                    user?.user_rol_id_data.map((item, index) => (
                                        <option key={index} value={item.id}>{item.title}</option>
                                    ))
                                }
                            </select>
                            <div className="error">
                                {errors?.role_id}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={handleClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Qo'shish</Button>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

UserCreateForm.propTypes = {
    addUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getUserRolIdData: PropTypes.func.isRequired,
    getFirmOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});


export default connect(mapStateToProps, {addUser, getUserRolIdData, clearErrors, getFirmOptions})(UserCreateForm);
