import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_EMPLOYEE,
    EMPLOYEE_ERROR,
    GET_EMPLOYEE,
    GET_EMPLOYEES,
    REMOVE_DELETE_EMPLOYEE,
    REMOVE_UPDATE_EMPLOYEE,
    SET_DELETE_EMPLOYEE_ID,
    SET_UPDATE_EMPLOYEE_ID,
    GET_FIRM_ID_DATA,
    GET_FILTERED_EMPLOYEE_SALARY,
    CLEAR_ERRORS,
    EXPENSE_ERROR,
    GET_PROFESSION_OPTIONS, GET_EMPLOYEE_TRANSACTIONS
} from './types';
import setAuthToken from "../utils/setAuthToken";
import {setLoading, unsetLoading} from "./products_transaction";
import {request} from "../utils/service";

export const getFirmIdData = () => async (dispatch) => {
    try {
        const res = await axios.get(`/firms?is_all=true`);
        dispatch({
            type: GET_FIRM_ID_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getEmployee = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/employees/${id}`);
        dispatch({
            type: GET_EMPLOYEE,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }

};

export const getEmployees = (page = 1, filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/employees?page=${page}${queryParams}`);
        dispatch({
            type: GET_EMPLOYEES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const getProfessionOptions = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/employees/professions/`);
        dispatch({
            type: GET_PROFESSION_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const getEmployeeTransactions = (id, filters = {}) => async (dispatch) => {
    try {
        let queryParams = '?';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }

        const res = await axios.get(`/employee/${id}/transactions/${queryParams}`);
        dispatch({
            type: GET_EMPLOYEE_TRANSACTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addEmployee = (formData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/employees/', formData, config);

        dispatch(getEmployees(filters.page, filters));

        dispatch({
            type: ADD_EMPLOYEE,
            payload: res.data,
        });

        dispatch(setAlert('Xodim yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: EMPLOYEE_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Xodim yaratilmadi !", 'danger'));
        }
    }
};

export const deleteEmployee = (deleteEmployeeID, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/employees/${deleteEmployeeID}`, config);

        dispatch({
            type: REMOVE_DELETE_EMPLOYEE
        });

        dispatch(getEmployees(filters.page, filters));

        dispatch(setAlert("Xodim o'chirildi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: EMPLOYEE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updateEmployee = (updateEmployeeID, updateformData, filters) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/employees/${updateEmployeeID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_EMPLOYEE});

        dispatch(getEmployees(filters.page, filters));

        dispatch(setAlert('Xodim tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: EMPLOYEE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteEmployeeId = (employeeID) => async (dispatch) => {

    try {
        if (employeeID !== null) {
            dispatch({
                type: SET_DELETE_EMPLOYEE_ID,
                payload: {
                    employeeID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_EMPLOYEE
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateEmployeeId = (employeeID) => async (dispatch) => {

    try {
        if (employeeID !== null) {
            dispatch({
                type: SET_UPDATE_EMPLOYEE_ID,
                payload: {
                    employeeID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_EMPLOYEE});


    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


// download report
export const downloadSingleEmployeeReport = (filters = {}) => async (dispatch) => {
    filters.object = 'employee';
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index == 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/reports/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};







