import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import MoneyFormat from "../../layout/MoneyFormat";
import {getEmployeeTransactions} from "../../../actions/employee";
import moment, {now} from "moment";
import {useHistory} from "react-router-dom";


const EmployeeTransactions = ({employee, employee_transactions}) => {
    const dispatch = useDispatch();

    const {id} = employee;

    const [from_date, setFromDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
    const [to_date, setToDate] = useState(moment().format("YYYY-MM-DD"));

    useEffect(() => {
        if (id) {
            dispatch(getEmployeeTransactions(id, {from_date, to_date}));
        }
    }, [getEmployeeTransactions, id]);

    const history = useHistory();

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getEmployeeTransactions(id, params))

        const url = new URL(window.location);
        url.searchParams.set('from_date', params.from_date);
        url.searchParams.set('to_date', params.to_date);
        window.history.pushState({}, '', url);

    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getEmployeeTransactions(id, params));

        const url = new URL(window.location);
        url.searchParams.set('from_date', params.from_date);
        url.searchParams.set('to_date', params.to_date);
        window.history.pushState({}, '', url);
    };

    return (
        <>

            <div className="w-100 table-responsive px-2">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <h4>
                            Ishlab chiqarishlar
                        </h4>
                    </div>
                </div>

                <div className="mb-2 align-items-center d-flex
                                                        justify-content-between">
                    <div className="mb-3">
                        <label htmlFor="date1"
                               className="mb-2">dan</label>
                        <input onChange={onChangeFromDate}
                               value={from_date}
                               className="form-control"
                               id="date1" type="date"/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date2"
                               className="mb-2">gacha</label>
                        <input onChange={onChangeToDate}
                               className="form-control"
                               value={to_date}
                               id="date2" type="date"/>
                    </div>
                </div>


                <table className="table custom-table table-hover">
                    <thead className="table-dark">
                    <tr>
                        <th scope="col">#</th>
                        {/*<th scope="col">ID</th>*/}
                        <th scope="col">Ishlagan(so'm)</th>
                        <th scope="col">Olgan(so'm)</th>
                        <th scope="col">Sana</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        employee_transactions?.map((item, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <MoneyFormat
                                        value={item?.earning_amount}
                                    />
                                </td>
                                <td>
                                    <MoneyFormat value={item?.payment_amount}/>
                                </td>
                                <td>{item.date}</td>
                            </tr>
                        ))
                    }
                    {
                        <tr key={'total'}>
                            <th scope="row">*</th>
                            <th>
                                {

                                    <MoneyFormat
                                        value={employee_transactions?.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.earning_amount), 0)}
                                    />
                                }
                            </th>
                            <th>
                                {
                                    <MoneyFormat
                                        value={employee_transactions?.reduce((acc, cur) => acc + parseFloat(cur.payment_amount), 0)}
                                    />
                                }
                            </th>
                            <th>
                                Jami
                                {/*{*/}
                                {/*    <MoneyFormat*/}
                                {/*        value={employee_transactions?.reduce((acc, cur) => acc + cur.earning_amount, 0) - employee_transactions?.reduce((acc, cur) => acc + cur.payment_amount, 0)}*/}
                                {/*    />*/}

                                {/*}*/}
                            </th>
                        </tr>
                    }
                    </tbody>
                </table>

            </div>
        </>
    )
}

export default EmployeeTransactions;