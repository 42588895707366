import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import WarehouseDeleteForm from "./WarehouseDeleteForm";
import WarehouseUpdateForm from "./WarehouseUpdateForm";
import WarehouseItem from "./WarehouseItem";
import WarehouseCreateForm from "./WarehouseCreateForm";
import {getWarehouses} from "../../actions/warehouse";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import FilterByFirm from "../suppliers_material_transactions/FilterByFirm";
import FilterByType from "../suppliers_material_transactions/FilterByType";
// import { useHistory, useLocation } from "react-router-dom";

const Warehouses = (props) => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {warehouses, loading, numberOfPages, currentPage, total_warehouses} = useSelector(state => state.warehouse);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> skladlar ({total_warehouses}) </h1>
                    </div>
                </div>

                <div className="row">

                    <div className="col-3">
                        <FilterByFirm getPageItems={getWarehouses}/>
                    </div>
                    <div className="col-3">
                        <FilterByType getPageItems={getWarehouses}/>
                    </div>
                    <div className="col-3">
                        <SearchForm getPageItems={getWarehouses}/>
                    </div>

                    <div className="col-md-3">
                        <div className="crud_actions text-end">
                            {
                                role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' || role_name == 'storekeeper' ? (
                                    <button className="btn btn-primary" onClick={handleShow}>
                                        Sklad <Plus/>
                                    </button>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Firma</th>
                                    <th scope="col">Skladchi</th>
                                    <th scope="col">Sklad turi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {warehouses.map((item, index) => (
                                    <WarehouseItem key={`warehouse${item.id}`} number={index + 1} warehouse={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getWarehouses}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <WarehouseCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <WarehouseDeleteForm/>

            <WarehouseUpdateForm/>


        </Fragment>
    );
};


Warehouses.propTypes = {
    getWarehouses: PropTypes.func.isRequired,
    warehouse: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    warehouse: state.warehouse,
});

export default connect(mapStateToProps, {getWarehouses})(Warehouses);
