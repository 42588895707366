import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addSupplier, clearErrors, getAvailableManagers} from "../../actions/supplier";
import Select from "react-select";
import {AvField} from "availity-reactstrap-validation";

const SupplierCreateForm = ({
                                auth,
                                supplier: {error, loading, suppliers},
                                addSupplier,
                                getAvailableManagers,
                                handleShow,
                                handleClose,
                                clearErrors,
                                show
                            }) => {


    const {role_name} = auth?.user || {};

    useEffect(() => {
        getAvailableManagers();
    }, [getAvailableManagers]);

    const formRef = useRef();

    const defaultValues = {
        name: '',
        contractor: '',
        phone: '',
        client_id: '',
        bank_account: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            contractor: inputs.contractor,
            phone: inputs.phone,
            inn: inputs.inn,
            bank_account: inputs.bank_account,
        };
        addSupplier(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error?.errors) {
                if (error && error?.errors) {
                    Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                }
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [suppliers]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi yetkazib beruvchi qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>


                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="contractor">Tashkilot xodimi</label>
                            <input
                                name="contractor"
                                className={'form-control'}
                                type="text"
                                value={inputs?.contractor || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.contractor}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="phone">Telefon</label>
                            <input
                                name="phone"
                                className={'form-control'}
                                type="text"
                                value={inputs?.phone || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.phone}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="inn">INN</label>
                            <input
                                name="inn"
                                className={'form-control'}
                                type="text"
                                value={inputs?.inn || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.inn}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="bank_account">Bank hisob raqami</label>
                            <input
                                name="bank_account"
                                className={'form-control'}
                                type="text"
                                value={inputs?.bank_account || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />
                            <div className="error">
                                {errors?.bank_account}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>
                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

SupplierCreateForm.propTypes = {
    addSupplier: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    supplier: state.supplier,
    auth: state.auth
});


export default connect(mapStateToProps, {addSupplier, getAvailableManagers, clearErrors})(SupplierCreateForm);
