import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteUnitId, setUpdateUnitId} from "../../actions/unit";
import {Link, useHistory} from 'react-router-dom'
import {Edit, Eye, Trash2} from "react-feather";

const UnitItem = ({
                      auth,
                      setDeleteUnitId,
                      setUpdateUnitId,
                      unit: {
                          id, name, symbol, value,
                          date: created_at
                      },
                      showActions,
                      number
                  }) => {

    const history = useHistory();

    const {role_name} = auth?.user || {};

    const setDeleteUnit = (id) => {
        setDeleteUnitId(id);
    };

    const setUpdateUnit = (id) => {
        setUpdateUnitId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{symbol}</td>
                <td>{value}</td>
                <td>
                    <div className="actions">
                        {/*<Link to={`employees/${id}`}>*/}
                        {/*    <Eye/>*/}
                        {/*</Link>*/}
                        <Edit onClick={() => setUpdateUnit(id)}/>
                        {/*{*/}
                        {/*    role_name == 'director' || role_name == 'superadmin' ? (*/}
                        {/*    ) : ''*/}
                        {/*}*/}
                        {
                            role_name == 'director' || role_name == 'superadmin' ? (
                                <Trash2 onClick={() => setDeleteUnit(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

UnitItem.defaultProps = {
    showActions: true,
};

UnitItem.propTypes = {
    unit: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteUnitId, setUpdateUnitId})(
    UnitItem
);
