import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import setAuthToken from '../utils/setAuthToken';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_PROFILE, SET_LOADING, UNSET_LOADING,
} from './types';

export const loadUser = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        dispatch({type: SET_LOADING});
        const res = await axios.get('/auth/user');
        dispatch({
            type: USER_LOADED,
            payload: res.data.data,
        });
        dispatch({type: UNSET_LOADING});
    } catch (err) {

        dispatch({
            type: AUTH_ERROR,
        });
    }
};

export const register = ({name, email, password, password_confirmation}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({name, email, password, password_confirmation});

    try {
        const res = await axios.post('/auth/register', body, config);

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });

        dispatch(loadUser());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch(logout());
        const errors = err.response.data.errors;

        if (errors) {
            // errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
            for (const prop in errors) {
                dispatch(setAlert(errors[prop][0], 'danger'));
            }
        } else {
            dispatch(setAlert(`You're offline!`, 'danger'));
        }


        dispatch({
            type: REGISTER_FAIL,
        });
    }
};

export const login = (email, password) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json"
        },
    };

    const body = JSON.stringify({email, password});

    try {
        const res = await axios.post('/auth/login/', body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });

        dispatch(loadUser());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: LOGIN_FAIL,
        });
    }
};

export const logout = () => (dispatch) => {
    dispatch({
        type: CLEAR_PROFILE,
    });
    dispatch({
        type: LOGOUT,
    });
};

export const updateProfile = (updateData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.patch('/auth/profile', updateData, config);

        dispatch(setAlert("Profile updated", 'success'));

        dispatch(loadUser());

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


