import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addFirm, clearErrors, getAvailableManagerUsers} from "../../actions/firms";

const FirmCreateForm = ({
                            firm: {available_firms, error, loading, firms},
                            addFirm,
                            getAvailableManagerUsers,
                            handleShow,
                            handleClose,
                            show
                        }) => {

    useEffect(() => {
        getAvailableManagerUsers();
    }, [getAvailableManagerUsers]);

    const formRef = useRef();

    const defaultValues = {
        name: '',
        manager_id: '',
        address: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            manager_id: inputs.manager_id,
            address: inputs.address,
        };
        addFirm(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [firms]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi firma qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="manager_id">Menejer</label>
                            <select name="manager_id"
                                    value={inputs?.manager_id || ''}
                                    id="manager_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    available_firms.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="error">
                                {errors?.manager_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="address">Manzil</label>
                            <input
                                name="address"
                                className={'form-control'}
                                type="text"
                                value={inputs?.address || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.address}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

FirmCreateForm.propTypes = {
    addFirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    firm: state.firm,
});


export default connect(mapStateToProps, {addFirm, getAvailableManagerUsers, clearErrors})(FirmCreateForm);
