import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    addProductTemplate,
    getMaterials,
    getProducts,
    getProductTemplateUnitAlternatives,
    getProductTemplateUnits
} from "../../actions/product_template";
import {Accordion} from "react-bootstrap";
import {ArrowLeft} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";

const ProductTemplateCreateForm = ({
                                       product_template_units,
                                       product_template_unit_alternatives,
                                       products,
                                       materials,
                                       addProductTemplate,
                                       getProductTemplateUnits,
                                       getProductTemplateUnitAlternatives,
                                       getMaterials,
                                       getProducts,
                                       handleShow,
                                       handleClose,
                                       show
                                   }) => {

        const [text, setText] = useState('');
        useEffect(() => {
            getProductTemplateUnits();
            getProducts();
            getMaterials();
            getProductTemplateUnitAlternatives();
        }, [getProductTemplateUnits, getProducts, getMaterials, getProductTemplateUnitAlternatives]);

        useEffect(() => {
            setMaterialOptions(materials);
        }, [materials]);


        // const [productID, setProductID] = useState(null);
        const [product_name, setProductName] = useState('');
        const [description, setDescription] = useState('');
        const [unit_id, setUnitId] = useState(null);

        const [materialOptions, setMaterialOptions] = useState(materials);
        const [selectedDynamicMaterialOptions, setSelectedDynamicMaterialOptions] = useState([]);
        const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);

        const onSelectMaterial = (data) => {
            setSelectedDynamicMaterialOptions(data);
        };

        const onRemoveMaterial = (data) => {
            setSelectedDynamicMaterialOptions(data)
        };

        const onClickMoveSelectedMaterials = () => {
            // console.log(selectedDynamicMaterialOptions)
            if (selectedDynamicMaterialOptions.length > 0) {
                setSelectedMaterialOptions([...selectedMaterialOptions, ...selectedDynamicMaterialOptions]);
                let ids = selectedDynamicMaterialOptions.map(({id}) => id);
                const filteredMaterials = materialOptions.filter((item) => !ids.includes(item.id));
                setMaterialOptions(filteredMaterials);
                setSelectedDynamicMaterialOptions([]);
            }
        };


        const onChangeInput = (e) => {
            let material_id = parseInt(e.target.dataset.id);
            const items = selectedMaterialOptions.map((item) => {
                if (item.id === material_id) {
                    item[e.target.getAttribute('name')] = Number(e.target.value);
                }
                return item;
            });
            setSelectedMaterialOptions(items);
        };


        const onSubmit = (e) => {
            e.preventDefault();
            const data = {
                // product_id: Number(productID),
                product_name: product_name,
                description: description,
                unit_id: unit_id,
                materials: selectedMaterialOptions
            };


            addProductTemplate(data);
            setSelectedMaterialOptions([]);
            setMaterialOptions(materials);
            setUnitId(product_template_units[0].id);
            setProductName('');
            setDescription('');

        };

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header bg-transparent border-0">
                        {/*<h5 className="card-title mt-2 mb-2">Yangi mahsulot shablon qo'shish</h5>*/}
                        <h5 className="card-title mt-2 mb-2">Yangi mahsulot qo'shish</h5>
                    </div>
                    <div className="card-body">

                        _
                        <form className="product_template__form" onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Mahsulot nomi(yaratiladi)
                                </label>
                                <input className={"form-control"} type="text"
                                       onChange={(e) => setProductName(e.target.value)} value={product_name}/>
                            </div>

                            <div className="mb-3">
                                <div className="row materials__mover">
                                    <div className="col-lg-5 col-md-12">
                                        <label className="form-label mb-2">Tarkibi</label>

                                        <div className="card p-2">
                                            <Accordion>
                                                {
                                                    selectedMaterialOptions.length > 0 ?
                                                        selectedMaterialOptions?.map((item, index) => (
                                                            <Accordion.Item key={index} eventKey={item.id}>
                                                                <Accordion.Header>{item.name}</Accordion.Header>
                                                                <Accordion.Body>

                                                                    <label htmlFor="ID">Maerial ID</label>
                                                                    <input
                                                                        className="form-control my-2"
                                                                        type="text"
                                                                        name="id"
                                                                        id="ID"
                                                                        value={item.id}
                                                                        required
                                                                        disabled
                                                                    />


                                                                    {/*<label htmlFor="unit_id">Sarflash o'lchov*/}
                                                                    {/*    birligi</label>*/}
                                                                    {/*<select name="unit_id"*/}
                                                                    {/*        className="form-control"*/}
                                                                    {/*        defaultValue={2}*/}
                                                                    {/*        onChange={onChangeInput}>*/}
                                                                    {/*    <option defaultValue={null}>Mahsulot tanlang*/}
                                                                    {/*    </option>*/}
                                                                    {/*    {*/}
                                                                    {/*        product_template_units.map((item, key) => (*/}
                                                                    {/*            <option key={key}*/}
                                                                    {/*                    defaultValue={key === 0 ? true : false}*/}
                                                                    {/*                    value={item.id}>{item.name}</option>*/}
                                                                    {/*        ))*/}
                                                                    {/*    }*/}
                                                                    {/*</select>*/}

                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Recipient's username"
                                                                               aria-label="Recipient's username"
                                                                               aria-describedby="basic-addon2"/>
                                                                        <span className="input-group-text"
                                                                              id="unit_value">  </span>
                                                                    </div>
                                                                    {/*<label*/}
                                                                    {/*    htmlFor="unit_value">Qiymati(soni)</label>*/}
                                                                    {/*<input*/}
                                                                    {/*    className="form-control my-2"*/}
                                                                    {/*    type="number"*/}
                                                                    {/*    name="unit_value"*/}
                                                                    {/*    id={`unit_value${item.id}`}*/}
                                                                    {/*    data-id={item.id}*/}
                                                                    {/*    value={item.unit_value}*/}
                                                                    {/*    onChange={onChangeInput}*/}
                                                                    {/*    required*/}
                                                                    {/*/>*/}


                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        ))

                                                        :
                                                        <h6 className="text-secondary">O'ng tomondan material
                                                            tanlab qo'shing!</h6>
                                                }
                                            </Accordion>
                                        </div>


                                    </div>

                                    <div className="col-md-12 justify-content-center col-lg-2 text-center">
                                        <br/>
                                        <br/>
                                        <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                                className="btn-primary py-2 px-3">
                                            <ArrowLeft/>
                                        </button>
                                    </div>

                                    <div className="col-lg-5 col-md-12">

                                        <div className="mt-2">
                                            <label htmlFor="" className="mb-2">Material</label>
                                            <Multiselect
                                                className="form-control mt-2"
                                                options={materialOptions}
                                                displayValue="name"
                                                onSelect={onSelectMaterial}
                                                onRemove={onRemoveMaterial}
                                                selectedValues={selectedDynamicMaterialOptions}
                                                placeholder="Materiallarni tanlang"
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Qisqa
                                    ma'lumot </label>
                                <textarea className="form-control" vale={description}
                                          onChange={(e) => setDescription(e.target.value)} name="description"
                                          id="description"
                                          rows="4"></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="unit_id" className="form-label">
                                    Mahsulot sarflash o'lchov birligi
                                </label>
                                <select className="form-control" value={unit_id}
                                        onChange={(e) => setUnitId(e.target.value)} name="unit_id"
                                        id="unit_id">
                                    <option defaultValue={null}>Tanlang</option>
                                    {
                                        product_template_units.map((item, key) => (
                                            <option key={key}
                                                    defaultValue={key === 0 ? true : false}
                                                    value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary">Qo'shish <i
                                    className="align-middle"
                                    data-feather="plus"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
;

ProductTemplateCreateForm.propTypes = {
    addProductTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    product_template: state.product_template,
    product_template_units: state.product_template.product_template_units,
    product_template_unit_alternatives: state.product_template.product_template_unit_alternatives,
    products: state.product_template.products,
    materials: state.product_template.materials,
});


export default connect(mapStateToProps, {
    addProductTemplate,
    getProductTemplateUnits,
    getProductTemplateUnitAlternatives,
    getProducts,
    getMaterials
})(ProductTemplateCreateForm);
