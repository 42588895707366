import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import {
    getSupplierOptions,
} from "../../actions/material";
import Select from 'react-select';
import {FormGroup, Input, Label} from "reactstrap";
import {Accordion, Form} from "react-bootstrap";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";
import {
    addOrUpdateSupplierTransaction,
    clearErrors
} from "../../actions/suppliers_transaction";
import moment from "moment";

const AddSupplierTransactionForm = ({
                                        loading,
                                        error,
                                        supplier_options,
                                        firm_options,
                                        clearErrors,
                                        getSupplierOptions,
                                        addOrUpdateSupplierTransaction,
                                        getMaterialOptions,
                                    }) => {

    useEffect(() => {
        getFirmOptions();
        getSupplierOptions();
    }, [getFirmOptions, getSupplierOptions]);

    const [supplierOptions, setSupplierOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);

    const formRef = useRef();
    const defaultValues = {
        supplier_id: '',
        type: 'received',
        amount: '',
        date: '',
    };
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let options = [];
        supplier_options?.forEach((item) => options.push({
            label: item?.name,
            value: item?.id
        }));
        setSupplierOptions(options);
    }, [supplier_options])

    useEffect(() => {

        let options = [];
        firm_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setFirmOptions(options);
    }, [firm_options]);

    const handleChange = e => {
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const handleSelect = (data, type) => {
        if (type.name == 'firm_id') {
            getMaterialOptions({firm_id: data.value});
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };
    const handleRadio = (e) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = getParams(true)
        if (await addOrUpdateSupplierTransaction(inputs, params)) {
            setErrors({});
            setInputs({type: 'received'});
        } else {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    };

    useEffect(() => {

        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error.hasOwnProperty('errors')) {
                    Object.keys(error.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                }
                setErrors(errorObject);

            } else {
                clearErrors();
                setErrors({});
                setInputs(defaultValues);
                setInputs({type: 'received'});
            }
        }
    }, [error, loading]);

    return (
        <Fragment>

            <div className="col-12">

                <Form onSubmit={handleSubmit} ref={formRef}>

                    <FormGroup>
                        <Label for="supplier_id">
                            Yatkazuvchi(поставщик)
                        </Label>
                        <Select
                            name={'supplier_id'}
                            options={supplierOptions}
                            onChange={handleSelect}
                            value={getSelectedOption(inputs?.supplier_id, supplierOptions)}
                            required
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.supplier_id}
                        </div>
                    </FormGroup>

                    <div className="mb-2 form-group">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input"
                                   type="radio"
                                   id="type1"
                                   value="received"
                                   onChange={handleRadio}
                                   name={'type'}
                                   checked={inputs?.type == 'received' ? true : false}
                            />
                            <label className="form-check-label" htmlFor="type1">Kirim</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="type2"
                                name={'type'}
                                onChange={handleRadio}
                                value="paid"
                                checked={inputs?.type == 'paid' ? true : false}
                            />
                            <label className="form-check-label" htmlFor="type2">Chiqim</label>

                        </div>
                        <div className="error">
                            {errors?.type}
                        </div>
                    </div>

                    <div className="mb-2">
                        <label htmlFor="amount">Summa</label>
                        <Input
                            name="amount"
                            value={inputs.amount || ''}
                            type="number"
                            placeholder={1000}
                            onChange={handleChange}
                            required
                        />
                        <div className="error">
                            {errors?.amount}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="date">Sana</label>
                        <input
                            name="date"
                            className={'form-control'}
                            type="date"
                            value={inputs?.date || ''}
                            placeholder={'kiriting'}
                            onChange={handleChange}
                            onKeyUp={e => e.target.oldValue = e.target.value}
                            max={moment().format('YYYY-MM-DD')}
                        />

                        <div className="error">
                            {errors?.date}
                        </div>
                    </div>

                    <div className="mb-2">
                        <Button className="" variant="primary" type="submit">Qo'shish</Button>
                    </div>
                </Form>

            </div>
        </Fragment>
    );
};

AddSupplierTransactionForm.propTypes = {
    addOrUpdateSupplierTransaction: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getSupplierOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    material: state.material,
    materials: state.material.materials,
    error: state.material.error,
    loading: state.material.loading,
    supplier_options: state.material.supplier_options,
    firm_options: state.user.firm_options,
});


export default connect(mapStateToProps, {
    addOrUpdateSupplierTransaction,
    clearErrors,
    getSupplierOptions,
    getFirmOptions,
})(AddSupplierTransactionForm);
