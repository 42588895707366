import {
    ADD_WAREHOUSE,
    AVAILABLE_STROKEEPER_USER_ERROR,
    CLEAR_ERRORS,
    GET_AVAILABLE_STROKEEPER_USER,
    GET_FILTERED_WAREHOUSE_THINGS,
    GET_WAREHOUSE,
    GET_WAREHOUSES,
    REMOVE_DELETE_WAREHOUSE,
    REMOVE_UPDATE_WAREHOUSE,
    SET_DELETE_WAREHOUSE_ID,
    SET_UPDATE_WAREHOUSE_ID,
    WAREHOUSE_ERROR
} from '../actions/types';

const initialState = {
    warehouses: [],
    available_warehouse_users: [],
    available_warehouse_firms: [],
    filtered_warehouse_things_data_date: null,
    update_warehouse: null,
    delete_warehouse: null,
    loading: false,
    total_warehouses: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_AVAILABLE_STROKEEPER_USER:

            return {
                ...state,
                ...payload,
                loading: false
            };

        case AVAILABLE_STROKEEPER_USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_WAREHOUSE:
            return {
                ...state,
                warehouse: payload.data,
                error: null,
                loading: false,
            };

        case GET_WAREHOUSES:
            return {
                ...state,
                warehouses: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_warehouses: payload.meta.total,
                error: null,
                loading: false,
            };

        case GET_FILTERED_WAREHOUSE_THINGS:
            return {
                ...state,
                filtered_warehouse_things_data_date: payload.data,
                loading: false,
            };

        case ADD_WAREHOUSE:
            return {
                ...state,
                warehouses: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_WAREHOUSE:
            return {
                ...state,
                delete_warehouse: null,
            };

        case REMOVE_UPDATE_WAREHOUSE:
            return {
                ...state,
                update_warehouse: null,
            };

        case SET_DELETE_WAREHOUSE_ID:

            return {
                ...state,
                delete_warehouse: state.warehouses.find((item) => item.id === action.payload.warehouseID)
            };

        case SET_UPDATE_WAREHOUSE_ID:
            return {
                ...state,
                update_warehouse: state.warehouses.find((item) => item.id === action.payload.warehouseID)
            };

        case WAREHOUSE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
