import React, {Component, useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';
import {ArrowDownRight, ArrowUpRight, DollarSign} from "react-feather";
import MoneyFormat from "../layout/MoneyFormat";
import {useDispatch, useSelector} from "react-redux";
import {getIncomeAndExpenses} from "../../actions/user";
import moment from "moment";

const ExpensePieChart = (props) => {

    const {expenses, incomes} = useSelector(state => state.user);

    const dispatch = useDispatch();
    const [month, setMonth] = useState(moment().format("YYYY-MM"));
    useEffect(() => {
        dispatch(getIncomeAndExpenses());
    }, [])

    const handleMonthInput = (e) => {
        console.log(e);
        e.preventDefault();
        console.log(e.target.value);
        setMonth(e.target.value);
        dispatch(getIncomeAndExpenses({month: e.target.value}));
    }

    return (
        <div className="card flex-fill">
            <div className="card-header d-flex align-content-center justify-content-between">
                <div className="col-auto d-inline-flex align-items-baseline">
                    <h6>Oylik kirim-chiqimlar</h6>
                </div>
                <div className="col-auto">
                    <input type="month" value={month} className={"form-control"} onChange={handleMonthInput}/>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col mt-0">
                        <h5 className="card-title">Kirim</h5>
                    </div>

                    <div className="col-auto">
                        <div className="stat text-primary"
                             title={"Sotuvlar va transport yetkazmalari"}>
                            <DollarSign/>
                        </div>
                    </div>
                </div>
                <h4 className="mt-1 mb-3">
                    <MoneyFormat value={incomes?.current?.toFixed(0)}/>
                </h4>

                <div className="mb-0">
                    <span className="text-success">
                        {/*<ArrowUpRight size="16"/>*/}
                        <MoneyFormat
                            value={(parseInt(incomes?.current) - parseInt(incomes?.previous)).toFixed(0)}/>
                    </span>
                    {/*<span className="text-muted">(O'tgan oy farqi)</span>*/}
                </div>
            </div>
            <hr/>
            <div className="card-body">
                <div className="row">
                    <div className="col mt-0">
                        <h5 className="card-title">Chiqim</h5>
                    </div>

                    <div className="col-auto">
                        <div className="stat text-primary"
                             title={"Material(Xom ashyo), Hodimlar oyliklari va harajatlar"}>
                            <DollarSign/>
                        </div>
                    </div>
                </div>
                <h4 className="mt-1 mb-3">
                    <div className="text-danger">
                        <MoneyFormat value={expenses?.current?.toFixed(0)}/>
                            so'm
                    </div>
                </h4>
                <div className="mb-0">
                    <span className="badge text-dark">
                        {/*<ArrowDownRight size="16"/>*/}
                        <MoneyFormat
                            value={(parseInt(expenses?.current) - parseInt(expenses?.previous)).toFixed(2)}/>
                        so'm
                    </span>
                    {/*<span className="text-muted">(O'tgan oy farq)</span>*/}
                </div>
            </div>
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '350px'
}
export default ExpensePieChart;