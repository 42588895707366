import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getAvailableStorekeeperUsers,
    getFilteredWarehouseThings,
    getWarehouse,
    updateWarehouse
} from "../../actions/warehouse";
import {Link, useParams} from 'react-router-dom';
import {Col, Nav, Row, Tab} from 'react-bootstrap'
import avatar from "../../img/avatar.jpg";
import GoBackButton from "../layout/GoBackButton";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import spare_part from "../../reducers/spare_part";
import Moment from "react-moment";
import MoneyFormat from "../layout/MoneyFormat";
import {getWarehouseType} from "../../utils/hooks/getParams";

const WarehousePage = (props) => {

    useEffect(() => {
        dispatch(getWarehouse(id));

    }, [getWarehouse]);

    useEffect(() => {
        dispatch(getAvailableStorekeeperUsers());
    }, []);

    const dispatch = useDispatch();

    const {id} = useParams();
    const [warehouseThingItems, setWarehouseThingItems] = useState([]);


    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const {
        warehouse, available_warehouse_users,
        available_warehouse_firms, filtered_warehouse_things_data_date
    } = useSelector(state => state.warehouse);
    const {
        auth
    } = useSelector(state => state);

    const {role_name} = auth?.user || {};

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        dispatch(getFilteredWarehouseThings(warehouse.id, warehouse?.type, event.target.value, to_date))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        dispatch(getFilteredWarehouseThings(warehouse.id, warehouse?.type, from_date, event.target.value))
    };

    /*useEffect(() => {
        if (filtered_warehouse_things_data_date?.transactions?.length >= 0) {
            setProductItems(filtered_warehouse_things_data_date?.transactions);
        } else {
            setProductItems(warehouse?.products);
        }
    }, [filtered_warehouse_things_data_date, warehouse, from_date, to_date]);*/


    useEffect(() => {
        if (warehouse?.type === 'part') {

            if (filtered_warehouse_things_data_date?.spare_parts?.length >= 0) {
                setWarehouseThingItems(filtered_warehouse_things_data_date?.spare_parts);
            } else {
                setWarehouseThingItems(warehouse?.spare_parts);
            }

        } else {
            if (warehouse?.type === 'product') {
                if (filtered_warehouse_things_data_date?.products?.length >= 0) {
                    setWarehouseThingItems(filtered_warehouse_things_data_date?.products);
                } else {
                    setWarehouseThingItems(warehouse?.products);
                }
            } else {
                if (filtered_warehouse_things_data_date?.materials?.length >= 0) {
                    setWarehouseThingItems(filtered_warehouse_things_data_date?.materials);
                } else {
                    setWarehouseThingItems(warehouse?.materials);
                }
            }
        }
    }, [filtered_warehouse_things_data_date, warehouse, from_date, to_date]);

    const [storekeeperOptions, setStorekeeperOptions] = useState([]);
    const [storekeeper_id, setStorekeeperId] = useState(null);
    const [defaultStorekeeper, setDefaultStorekeeper] = useState({value: ''});

    useEffect(() => {
        available_warehouse_users.forEach((item) => setStorekeeperOptions(storekeeperOptions => [...storekeeperOptions, {
            label: `${item.name}`,
            value: item.id
        }]));
    }, [available_warehouse_users]);


    useEffect(() => {
        if (warehouse) {
            if (warehouse?.storekeeper_id) {
                setStorekeeperId(warehouse?.storekeeper_id);
                setDefaultStorekeeper({
                    label: `${warehouse?.storekeeper?.name}`,
                    value: warehouse?.storekeeper_id
                })
            } else {
                setStorekeeperId('');
                setStorekeeperId({
                    label: 'Tanlang',
                    value: ''
                })
            }
        }
    }, [warehouse, getWarehouse]);

    const onSelect = (data) => {
        setDefaultStorekeeper(data);
        setStorekeeperId(data.value);
    };

    const onSubmit = (event, value) => {

        value = {
            ...value,
            storekeeper_id: storekeeper_id
        };
        setStorekeeperId(null);
        dispatch(updateWarehouse(warehouse.id, value));
        dispatch(getWarehouse(id));
    };


    return (
        <div>
            <div className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>{warehouse?.name}</strong> skladi sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>


                    <div className="row">

                        <div className="card px-4 py-3">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={3} md={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"
                                                          className="text-center fw-bold">Haqida</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"
                                                          className="text-center fw-bold">Skladchi</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third" className="text-center fw-bold">

                                                    {
                                                        warehouse?.type === 'part' ?
                                                            "Zapchastlar"
                                                            :
                                                            warehouse?.type === 'product' ?
                                                                "Mahsulotlar"
                                                                :
                                                                "Materiallar"
                                                    }


                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9} md={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">

                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <h5 className="card-title mb-0">Sklad ma'lumotlari</h5>
                                                    </div>
                                                    <div className="card-body text-center">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <img src={avatar} alt="Christina Mason"
                                                                     className="img-fluid rounded-circle m-2 mb-2"
                                                                     width="128" height="128"/>
                                                                <h5 className="card-title mb-0">{warehouse?.name}</h5>
                                                            </div>
                                                            <div className="col-md-9">

                                                                <div className="d-flex align-items-center">
                                                                    <div className="card-title m-2">Nomi:</div>
                                                                    <div
                                                                        className="text-muted m-0">{warehouse?.name}</div>
                                                                </div>

                                                                <div className="d-flex align-items-center">
                                                                    <div className="card-title m-2">Sklad turi:</div>
                                                                    <div
                                                                        className="text-muted m-0">{getWarehouseType(warehouse?.type)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-header border-top">
                                                        <h5 className="card-title mb-0">Firma:</h5>
                                                    </div>

                                                    <div className="card-body text-center">
                                                        <div className="row">
                                                            <div className="col-md-12 ms-2">

                                                                <div className="d-flex align-items-center">
                                                                    <div className="card-title m-2">Firma nomi:</div>
                                                                    <div
                                                                        className="text-muted m-0">{warehouse?.firm?.name}</div>
                                                                </div>

                                                                <div className="d-flex align-items-center">
                                                                    <div className="card-title m-2">Manzil:</div>
                                                                    <div
                                                                        className="text-muted m-0">{warehouse?.firm?.address}</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">

                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <h5 className="card-title mb-0">Skladchi ma'lumotlari</h5>
                                                    </div>
                                                    <div className="card-body text-center">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <img src={avatar} alt="Christina Mason"
                                                                     className="img-fluid rounded-circle m-2 mb-2"
                                                                     width="128" height="128"/>
                                                                <h5 className="card-title mb-0">{warehouse?.storekeeper?.name}</h5>
                                                            </div>
                                                            <div className="col-md-9">

                                                                <div className="d-flex align-items-center">
                                                                    <div className="card-title m-2">Ismi:</div>
                                                                    <div
                                                                        className="text-muted m-0">{warehouse?.storekeeper?.name}</div>
                                                                </div>

                                                                <div className="d-flex align-items-center">
                                                                    <div className="card-title m-2">Email:</div>
                                                                    <div
                                                                        className="text-muted m-0">{warehouse?.storekeeper?.email}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="third">

                                                <div className="w-100 table-responsive px-2">

                                                    {/*<div*/}
                                                    {/*    className="mb-2 align-items-center d-flex justify-content-between">*/}

                                                    {/*    <div className="mb-3">*/}
                                                    {/*        <label htmlFor="date1" className="mb-2">dan</label>*/}
                                                    {/*        <input value={from_date} onChange={onChangeFromDate}*/}
                                                    {/*               className="form-control" id="date1" type="date"/>*/}
                                                    {/*    </div>*/}

                                                    {/*    <div className="mb-3">*/}
                                                    {/*        <label htmlFor="date2" className="mb-2">gacha</label>*/}
                                                    {/*        <input value={to_date} onChange={onChangeToDate}*/}
                                                    {/*               className="form-control" id="date2" type="date"/>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}

                                                    <table className="table custom-table table-hover">
                                                        <thead className="table-dark">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Nomi</th>
                                                            <th scope="col">Miqdori</th>
                                                            <th scope="col">Narxi</th>
                                                            <th scope="col">Sana</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            warehouseThingItems?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>
                                                                        {
                                                                            warehouse?.type === 'part' ?
                                                                                <Link
                                                                                    to={`/spare-parts/${item.id}`}> {item.name} </Link>
                                                                                : warehouse?.type === 'product' ?
                                                                                    <Link
                                                                                        to={`/products/${item.id}`}> {item.name} </Link>
                                                                                    :
                                                                                    <Link
                                                                                        to={`/materials/${item.id}`}> {item.name} </Link>
                                                                        }

                                                                    </td>

                                                                    <td>{item.quantity}</td>
                                                                    <td>
                                                                        <MoneyFormat value={item.price}/>
                                                                        so'm
                                                                    </td>
                                                                    <td>
                                                                        <Moment
                                                                            format="YYYY-MM-DD">{item.created_at}</Moment>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>

                                                </div>

                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default WarehousePage;