import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {
    addProduct,
    clearErrors,
    getUnitOptions,
    getMaterialOptions,
    getWarehouseOptions
} from "../../actions/product";
import {Accordion} from "react-bootstrap";
import {ArrowLeft, Plus, X} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";
import {isArray} from "chart.js/helpers";
import {Input} from "reactstrap";

const ProductCreateForm = ({
                               unit_options,
                               firm_options,
                               products,
                               loading,
                               error,
                               warehouse_options,
                               material_options,
                               handleClose,
                               show,
                               getUnitOptions,
                               addProduct,
                               getMaterialOptions,
                               getWarehouseOptions,
                           }) => {

    useEffect(() => {
        getUnitOptions();
        getFirmOptions();
    }, [getUnitOptions, getMaterialOptions, getFirmOptions]);

    const formRef = useRef();

    const defaultValues = {
        name: '',
        description: '',
        unit_id: '',
        warehouse_id: '',
        is_listed_in_report: true,
        is_listed_in_products_table: true,
        materials: []
    };


    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);


    useEffect(() => {
        let options = [];
        warehouse_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        if (e.target.name === 'is_listed_in_report' || e.target.name === 'is_listed_in_products_table'){
            setInputs(prevState => ({...prevState, [e.target.name]: !inputs[e.target.name]}));
        }else{
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
        }
        console.log(inputs);
    };

    // const handlech = e => {
    //     setErrors({});
    //     e.target.oldValue = e.target.value;
    //     setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    // };


    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);

    }, [products]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };
    useEffect(() => {
        material_options.map(item => {
            item.unit_value = 0;
            return item;
        });
        setMaterialOptions(material_options);
    }, [material_options]);


    const [materialOptions, setMaterialOptions] = useState(material_options);
    const [selectedDynamicMaterialOptions, setSelectedDynamicMaterialOptions] = useState([]);
    const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);

    const onSelectMaterial = (data) => {
        setSelectedDynamicMaterialOptions(data);
    };

    const onRemoveMaterial = (data) => {
        setSelectedDynamicMaterialOptions(data)
    };


    const onClickMoveSelectedMaterials = () => {
        // console.log(selectedDynamicMaterialOptions)
        if (selectedDynamicMaterialOptions.length > 0) {
            setSelectedMaterialOptions([...selectedMaterialOptions, ...selectedDynamicMaterialOptions]);
            let ids = selectedDynamicMaterialOptions.map(({id}) => id);
            const filteredMaterials = materialOptions.filter((item) => !ids.includes(item.id));
            setMaterialOptions(filteredMaterials);
            setSelectedDynamicMaterialOptions([]);
        }
    };

    const onChangeInput = (e) => {
        let material_id = parseInt(e.target.dataset.id);
        const cleanItems = [];

        const items = selectedMaterialOptions.map((item) => {
            if (item.id === material_id) {
                item[e.target.getAttribute('name')] = e.target.value;
            }
            cleanItems.push({
                id: item.id,
                [e.target.getAttribute('name')]: parseFloat(item.unit_value)
            });
            return item;
        });
        setSelectedMaterialOptions(items);

        setErrors({});
        setInputs(prevState => ({...prevState, materials: [...cleanItems]}));
    };


    const onClickRemoveFromSelectedMaterials = (id) => {
        const filteredSelectedMaterials = selectedMaterialOptions.filter(item => item.id !== Number(id));
        setSelectedMaterialOptions([...filteredSelectedMaterials]);
        let ids = filteredSelectedMaterials.map(({id}) => id);
        const filteredMaterials = material_options.filter((item) => !ids.includes(item.id));

        const cleanItems = inputs.materials;
        const filteredItems = cleanItems.filter((item) => item.id != id);
        setInputs(prevState => ({...prevState, materials: [...filteredItems]}));
        setMaterialOptions(filteredMaterials);
        setSelectedDynamicMaterialOptions([]);
    }

    const handleSelect = (data, type) => {
        setErrors({});
        if (type.name == 'firm_id') {
            getWarehouseOptions({firm_id: data.value});
            getMaterialOptions({firm_id: data.value});
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            is_listed_in_report: inputs.is_listed_in_report,
            is_listed_in_products_table: inputs.is_listed_in_products_table,
            warehouse_id: inputs.warehouse_id,
            unit_id: inputs.unit_id,
            description: inputs.description,
            materials: inputs.materials
        };
        const params = getParams(true)
        addProduct(data, params);

    };

    useEffect(() => {
        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error?.errors) {
                    if (error && error?.errors) {
                        Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                    }
                    setErrors(errorObject);
                }

            }
        }
    }, [error, loading]);


    useEffect(() => {
        setSelectedDynamicMaterialOptions([]);
        setSelectedMaterialOptions([]);
        setMaterialOptions([]);
        setMaterialOptions(material_options);
    }, [products])


    return (
        <Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi mahsulot qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Mahsulot nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={getSelectedOption(inputs?.firm_id, firmOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Sklad</label>
                            <Select
                                name={'warehouse_id'}
                                options={warehouseOptions}
                                value={getSelectedOption(inputs?.warehouse_id, warehouseOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.warehouse_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="row materials__mover">
                                <div className="col-lg-5 col-md-12">
                                    <label className="form-label mb-2">Tarkibi</label>

                                    <div className="card p-2">
                                        <Accordion>
                                            {
                                                isArray(selectedMaterialOptions) ? selectedMaterialOptions.map((item, index) => (
                                                        <Accordion.Item key={index} eventKey={item.id}>
                                                            <Accordion.Header>
                                                                {item.name} ({item?.in_stock} {item.unit?.symbol})
                                                            </Accordion.Header>
                                                            <Accordion.Body>

                                                                <div className="form-group ">

                                                                    <div
                                                                        className="input-group mb-1 justify-content-between">
                                                                        <label htmlFor="ID">Hozirgi tan narxi</label>
                                                                        <X className={'pointered-icon'}
                                                                           onClick={e => onClickRemoveFromSelectedMaterials(item.id)}
                                                                           color={'red'}
                                                                        />
                                                                    </div>
                                                                    <input
                                                                        className="form-control my-2 float-right"
                                                                        type="text"
                                                                        name="id"
                                                                        id="net_price"
                                                                        value={item.net_price || 0}
                                                                        required
                                                                        disabled
                                                                    />
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Miqdori"
                                                                               name='unit_value'
                                                                               type={'integer'}
                                                                               value={item.unit_value}
                                                                               data-id={item.id}
                                                                               id={`unit_value${item.id}`}
                                                                               required
                                                                               onChange={onChangeInput}
                                                                        />
                                                                        <span className="input-group-text"
                                                                              id="unit_value">
                                                                        {item.unit_alternative?.symbol}
                                                                    </span>
                                                                    </div>
                                                                </div>

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))
                                                    :
                                                    <h6 className="text-secondary">
                                                        O'ng tomondan material tanlab qo'shing!
                                                    </h6>
                                            }
                                        </Accordion>
                                    </div>


                                </div>

                                <div className="col-md-12 justify-content-center col-lg-2 text-center">
                                    <br/>
                                    <br/>
                                    <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                            className="btn-primary py-2 px-3">
                                        <ArrowLeft/>
                                    </button>
                                </div>

                                <div className="col-lg-5 col-md-12">

                                    <div className="mt-2">
                                        <label htmlFor="" className="mb-2">Materiallar</label>
                                        <Multiselect
                                            className="form-control mt-2"
                                            options={materialOptions}
                                            displayValue="name"
                                            onSelect={onSelectMaterial}
                                            onRemove={onRemoveMaterial}
                                            selectedValues={selectedDynamicMaterialOptions}
                                            placeholder="Materiallarni tanlang"
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Qisqa
                                ma'lumot </label>
                            <textarea className="form-control" value={inputs?.description}
                                      onChange={handleChange} name="description"
                                      id="description"
                                      rows="4"></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="unit_id" className="form-label">
                                Mahsulot sarflash o'lchov birligi
                            </label>
                            <select className="form-control" value={inputs?.unit_id}
                                    onChange={handleChange} name="unit_id"
                                    id="unit_id">
                                <option defaultValue={''}>Tanlang</option>
                                {
                                    unit_options && unit_options.map((item, key) => (
                                        <option key={key}
                                                defaultValue={key === 0 ? true : false}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="mb-3 form-check">
                            <Input
                                type="checkbox"
                                value={inputs.is_listed_in_report}
                                checked={inputs.is_listed_in_report}
                                name={"is_listed_in_report"}
                                onChange={handleChange}
                                // onChange={(e) => setInputs({...inputs, is_listed_in_report: e.target.value})}
                            />
                            <label htmlFor="is_listed_in_report" className="form-label">
                                Otchet jadvalida ko'rsatilsin
                            </label>
                        </div>

                        <div className="mb-3 form-check">
                            <Input
                                type="checkbox"
                                value={inputs?.is_listed_in_products_table}
                                checked={inputs.is_listed_in_products_table}
                                name={"is_listed_in_products_table"}
                                onChange={handleChange}
                            />
                            <label htmlFor="is_listed_in_products_table" className="form-label pl-4 "
                                   onClick={(e) => setInputs({...inputs, is_listed_in_products_table: !inputs.is_listed_in_products_table})}
                            >
                                Elektron Mahsulotlar jadvalida ko'rinsin
                            </label>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">
                                Qo'shish <Plus/>
                            </Button>
                        </div>

                    </form>

                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

ProductCreateForm.propTypes = {
    addProduct: PropTypes.func.isRequired,
    getUnitOptions: PropTypes.func.isRequired,
    getMaterialOptions: PropTypes.func.isRequired,
    getWarehouseOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    product: state.product,
    unit_options: state.product.unit_options,
    firm_options: state.user.firm_options,
    warehouse_options: state.product.warehouse_options,
    material_options: state.product.material_options,
    products: state.product.products,
    loading: state.product.loading,
    error: state.product.error,
});


export default connect(mapStateToProps, {
    addProduct,
    clearErrors,
    getUnitOptions,
    getMaterialOptions,
    getWarehouseOptions
})(ProductCreateForm);
