import avatar from "../../../img/avatar.jpg";
import React from "react";
import SingleClientReport from "../../clients/SingleClientReport";
import SingleSupplierReport from "../SingleSupplierReport";

const SupplierAbout = ({supplier}) => {

    const {id} = supplier;
    
    return (
        <>

            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title mb-0">Yetkazib beruvchi
                        ma'umotlari</h5>
                </div>
                <div className="card-body text-center">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={avatar} alt="Christina Mason"
                                 className="img-fluid rounded-circle m-2 mb-2"
                                 width="128" height="128"/>
                            <h5 className="card-title mb-0">{supplier?.name} </h5>
                            <div className="text-muted mb-2">{supplier?.email}</div>
                        </div>
                        <div className="col-md-9">

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">Tashkilot hodimi:</div>
                                <div
                                    className="text-muted m-0">{supplier?.contractor}</div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">INN:</div>
                                <div
                                    className="text-muted m-0">{supplier?.inn}</div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">Phone:</div>
                                <div
                                    className="text-muted m-0">{supplier?.phone}</div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">Bank akkount:</div>
                                <div
                                    className="text-muted m-0">{supplier?.bank_account}</div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-header">Hisobot chiqarish(<b>Kirim va To'langan</b>)</div>
                <div className="card-body">
                    <SingleSupplierReport id={id}/>
                </div>
            </div>
        </>
    )
}

export default SupplierAbout;