import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import EmployeeForm from './EmployeeCreateForm';
import EmployeeItem from './EmployeeItem';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getEmployees} from '../../actions/employee';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from '../layout/CustomPagination';
import SearchForm from '../layout/SearchForm';
import EmployeeDeleteForm from "./EmployeeDeleteForm";
import EmployeeUpdateForm from "./EmployeeUpdateForm";
import FilterByFirm from "../suppliers_material_transactions/FilterByFirm";
import FilterByProfession from "./FilterByProfession";

const Employees = (props) => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {employees, loading, numberOfPages, currentPage, total_employees} = useSelector(state => state.employee);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> xodimlar({total_employees}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <FilterByFirm getPageItems={getEmployees}/>
                    </div>
                    <div className="col-md-3">
                        <SearchForm getPageItems={getEmployees}/>
                    </div>

                    <div className="col-md-3">
                        <FilterByProfession getPageItems={getEmployees}/>
                    </div>

                    <div className="col-md-3">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Xodim
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-plus align-middle mr-3">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </button>
                        </div>
                    </div>


                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Ism</th>
                                    <th scope="col">Familiya</th>
                                    <th scope="col">INN</th>
                                    <th scope="col">Manzil</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Firma nomi</th>
                                    <th scope="col">Kasbi</th>
                                    {/*<th scope="col">Balans</th>*/}
                                    <th scope="col">Holati</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    employees.map((item, index) => (
                                        <EmployeeItem key={item.id} number={index + 1} employee={item}/>
                                    ))
                                }
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getEmployees}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <EmployeeForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <EmployeeDeleteForm/>

            <EmployeeUpdateForm/>

        </Fragment>
    );
};

Employees.propTypes = {
    getEmployees: PropTypes.func.isRequired,
    employee: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    employee: state.employee,
});

export default connect(mapStateToProps, {getEmployees})(Employees);
