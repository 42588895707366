import {
    ADD_SALE, CLEAR_ERRORS, GET_CLIENT_OPTIONS,
    GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA, GET_PRODUCT_OPTIONS,
    GET_SALE,
    GET_SALES, GET_SPECIAL_CLIENT,
    REMOVE_DELETE_SALE,
    REMOVE_UPDATE_SALE,
    SALE_ERROR,
    SET_DELETE_SALE_ID,
    SET_UPDATE_SALE_ID
} from '../actions/types';

const initialState = {
    sales: [],
    sale: {},
    product_options: [],
    client_ID_DATA: [],
    product_ID_DATA: [],
    seller_ID_DATA: [],
    client_options: [],
    special_client_materials: [],
    update_sale: null,
    delete_sale: null,
    loading: false,
    numberOfPages: 1,
    total_sales: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA:
            return {
                ...state,
                ...payload,
                loading: false,
            };

        case GET_SALE:
            return {
                ...state,
                sale: payload.data,
                error: null,
                loading: false,
            };

        case GET_SPECIAL_CLIENT:
            return {
                ...state,
                special_client_materials: payload.data,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_OPTIONS:
            return {
                ...state,
                product_options: payload.data,
                error: null,
                loading: false,
            };

        case GET_CLIENT_OPTIONS:
            return {
                ...state,
                client_options: payload.data,
                error: null,
                loading: false,
            };

        case GET_SALES:
            return {
                ...state,
                sales: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_sales: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_SALE:
            return {
                ...state,
                sales: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_SALE:
            return {
                ...state,
                delete_sale: null,
            };

        case REMOVE_UPDATE_SALE:
            return {
                ...state,
                update_sale: null,
            };

        case SET_DELETE_SALE_ID:

            return {
                ...state,
                delete_sale: state.sales.find((item) => item.id === action.payload.saleID)
            };

        case SET_UPDATE_SALE_ID:
            return {
                ...state,
                update_sale: state.sales.find((item) => item.id === action.payload.saleID)
            };

        case SALE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
