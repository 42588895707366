import './wdyr';
import Axios from "axios";
import React, {Fragment, useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Routes from './components/routing/Routes';

// Components
import Sidebar from './components/layout/Sidebar';
import Landing from './components/layout/Landing';


import {loadUser} from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './app.css';
import './custom.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Alert from './components/layout/Alert';
import Loader from "react-js-loader";
import PropTypes from "prop-types";
import {connect} from "react-redux";
// import store from "./store";
// import checkLoading from "./utils/checkLoading";
import loadingStatus from "./utils/checkLoading";

let url = `${process.env.REACT_APP_API_URL}`;


if (process.env.REACT_APP_MODE === 'prod') {
    url = `${process.env.REACT_APP_PRODUCTION_APP_API_URL}`;
}

if (process.env.REACT_APP_MODE == 'stage') {
    url = `${process.env.REACT_APP_STAGING_APP_API_URL}`;
}
Axios.defaults.baseURL = url;
// Axios.defaults.baseURL = "http://xatp13.backend";

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const loaderWrapperStyles = {
    backgroundColor: '#2980B9',
    width: "100vw",
    height: "100vh",
    position: "absolute",
    left: 0, top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 111
};

const App = ({loadUser}) => {
    // const [loading, setLoading] = useState(true);
    // store.subscribe(() => {
    //     let condition = false;
    //     const reducers = store.getState();
    //     for (const stateKey in reducers) {
    //         console.log(reducers[stateKey].loading, stateKey)
    //         if (reducers[stateKey].loading === true) {
    //             condition = true;
    //         }
    //     }
    //     setLoading(condition);
    // });
    // useEffect(() => {
    //     // setLoading(checkLoading())
    //     
    // }, [loading])
    useEffect(() => {
        loadUser();
        // setLoading(false);
    }, []);

    return (
        <Fragment>
            <Alert/>
            {/*<div className="loader-wrapper">*/}
            {/*    <Loader type="spinner-default" bgColor={"#FFFFFF"} title={"Yuklanmoqda..."}*/}
            {/*            color={'#FFFFFF'}*/}
            {/*            size={100}/>*/}
            {/*</div>*/}
            <Sidebar/>
            <Switch>
                <Route exact path="/" component={Landing}/>
                <Route component={Routes}/>
            </Switch>
            <ToastContainer autoClose={5000} limit={1}/>
        </Fragment>
    );
};
App.propTypes = {
    loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});


export default connect(mapStateToProps, {loadUser})(App);

// export default App;
