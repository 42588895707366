import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {connect, useDispatch} from "react-redux";
import {AvField, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {getAvailableClients, setUpdateSupplierId, updateSupplier} from "../../actions/supplier";
import {Label} from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import {getParams} from "../../utils/hooks/getParams";

const SupplierUpdateForm = ({auth, supplier: {update_supplier, currentPage}}) => {

    const {role_name} = auth?.user || {};

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAvailableClients(update_supplier?.client_id));
        if (update_supplier?.is_special_client === 1) {
        }
    }, [update_supplier]);

    const [client_id, setClientId] = useState(null);

    const closeModal = () => {
        dispatch(setUpdateSupplierId(null));
    };

    let condition = false;
    if (update_supplier !== null)
        condition = true;

    const onSubmit = (event, value) => {

        value.phone = value.phone.toString();
        value.bank_account = Number(value.bank_account);
        value.is_special_client = Number(value.is_special_client);

        value = {
            ...value,
            client_id: client_id
        };
        setUpdateSupplierId(null);
        dispatch(updateSupplier(update_supplier.id, value, getParams()));
    };

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Yetkazib beruvchini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_supplier} onValidSubmit={onSubmit}>


                        <AvField
                            name="name"
                            label="Nomi"
                            type="text"
                            required
                        />

                        <AvField
                            name="contractor"
                            label="Tashkilot xodimi"
                            type="text"
                            required
                        />

                        <AvField
                            name="phone"
                            label="Telefon"
                            type="text"
                            required
                        />

                        <AvField
                            name="inn"
                            label="INN"
                            type="text"
                            required
                        />

                        <AvField
                            name="bank_account"
                            label="Bank hisob raqami"
                            type="number"
                            required
                        />
                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

SupplierUpdateForm.propTypes = {
    getAvailableClients: PropTypes.func.isRequired,
    updateSupplier: PropTypes.func.isRequired,
    supplier: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    supplier: state.supplier,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    setUpdateSupplierId,
    updateSupplier,
    getAvailableClients,
})(SupplierUpdateForm);

// export default SupplierUpdateForm;