import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import ExpenseTemplateDeleteForm from "./ExpenseTemplateDeleteForm";
import ExpenseTemplateUpdateForm from "./ExpenseTemplateUpdateForm";
import ExpenseTemplateCreateForm from "./ExpenseTemplateCreateForm";
import ExpenseTemplateItem from "./ExpenseTemplateItem";
import {getExpenseTemplates} from "../../actions/expense_template";
import SearchForm from "../layout/SearchForm";
import CustomPagination from "../layout/CustomPagination";
import {getExpenses} from "../../actions/expense";
import {Plus} from "react-feather";

const ExpenseTemplates = (props) => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {
        expense_templates,
        loading,
        numberOfPages,
        currentPage,
        total_expense_templates
    } = useSelector(state => state.expense_template);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getExpenseTemplates();
    }, []);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3">
                            <strong></strong>
                            Harajat shablonlari({total_expense_templates || ''})
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getExpenseTemplates}/>
                    </div>
                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Harajat shabloni <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Kategoriya</th>
                                    <th scope="col">Turi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {expense_templates.map((item, index) => (
                                    <ExpenseTemplateItem key={`expense-template${item.id}`} index={index + 1}
                                                         expense_template={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getExpenseTemplates}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <ExpenseTemplateCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <ExpenseTemplateDeleteForm/>

            <ExpenseTemplateUpdateForm/>


        </Fragment>
    );
};


ExpenseTemplates.propTypes = {
    getExpenseTemplates: PropTypes.func.isRequired,
    expense_template: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    expense_template: state.expense_template,
});

export default connect(mapStateToProps, {getExpenseTemplates})(ExpenseTemplates);
