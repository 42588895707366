import {
    ADD_USER, CLEAR_ERRORS,
    DELETE_USER,
    GET_FILTERED_USERS, GET_STATISTICS,
    GET_USER, GET_USER_ACTIVITIES,
    GET_USER_ROL_ID_DATA,
    GET_USERS,
    REMOVE_DELETE_USER,
    REMOVE_UPDATE_USER,
    SET_DELETE_USER_ID,
    SET_SEARCH_QUERY,
    SET_UPDATE_USER_ID,
    USER_ERROR,
    GET_FIRM_ID_DATA, GET_INCOME_AND_EXPENSES
} from '../actions/types';

const initialState = {
    statistics: [],
    activities: [],
    users: [],
    firm_options: [],
    filtered_users: [],
    search_text: '',
    user_rol_id_data: [],
    update_user: null,
    delete_user: null,
    loading: false,
    numberOfPages: 1,
    total_users: null,
    error: {},
    // errors: [],
};


export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_FIRM_ID_DATA:
            return {
                ...state,
                firm_options: payload.data,
                loading: false,
            };
        case GET_STATISTICS:
            return {
                ...state,
                statistics: payload,
                loading: false,
            };
        case GET_INCOME_AND_EXPENSES:
            return {
                ...state,
                incomes: payload?.incomes,
                expenses: payload?.expenses,
                loading: false,
            };

        case GET_USER:
            return {
                ...state,
                user: payload.data,
                loading: false,
            };

        case GET_USERS:
            return {
                ...state,
                users: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_users: payload.meta.total,
                loading: false,
            };

        case GET_USER_ROL_ID_DATA:
            return {
                ...state,
                user_rol_id_data: payload.data,
                loading: false,
            };

        case GET_FILTERED_USERS:
            return {
                ...state,
                filtered_users: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                loading: false,
            };
        case SET_SEARCH_QUERY:
            return {
                ...state,
                search_text: payload,
                loading: false,
            };

        case ADD_USER:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter((item) => item.id !== payload),
                loading: false,
            };

        case USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case REMOVE_DELETE_USER:
            return {
                ...state,
                delete_user: null,
            };

        case SET_DELETE_USER_ID:

            return {
                ...state,
                delete_user: state.users.find((item) => item.id === action.payload.userID)
            };

        case GET_USER_ACTIVITIES:


            return {
                ...state,
                activities: payload.data,
                numberOfPages: payload.last_page,
                currentPage: payload.current_page,
                loading: false,
            };

        case REMOVE_UPDATE_USER:
            return {
                ...state,
                update_user: null,
            };

        case SET_UPDATE_USER_ID:

            return {
                ...state,
                update_user: state.users.find((item) => item.id === action.payload.userID)
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
