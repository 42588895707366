import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_SALARY, CLEAR_ERRORS,
    EMPLOYEES_ID_ERROR,
    GET_EMPLOYEE_TRANSACTIONS,
    GET_EMPLOYEES_ID_DATA,
    GET_SALARIES,
    REMOVE_DELETE_SALARY,
    REMOVE_UPDATE_SALARY,
    SALARY_ERROR, SALE_ERROR,
    SET_DELETE_SALARY_ID,
    SET_UPDATE_SALARY_ID,
    UPDATE_SALARY_TRANSACTION_ERROR,
    UPDATE_SALARY_TRANSACTION_SUCCESS
} from './types';
import {message} from "antd";

export const getEmployeesIDData = (filters = {is_all: true}) => async (dispatch) => {
    try {
        let queryParams = '?';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }

        const res = await axios.get(`/employees${queryParams}`);
        dispatch({
            type: GET_EMPLOYEES_ID_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const getSalaries = (page = 1, filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/salaries?page=${page}${queryParams}`);
        dispatch({
            type: GET_SALARIES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addSalary = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/salaries/', formData, config);

        dispatch(getSalaries());

        dispatch({
            type: ADD_SALARY,
            payload: res.data,
        });

        dispatch(setAlert('Maosh yaratildi !', 'success'));
        getEmployeesIDData({without_salary: true});
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: SALARY_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Maosh yaratilmadi !", 'danger'));
        }
    }
};

export const deleteSalary = (deleteSalaryID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/salaries/${deleteSalaryID}`, config);

        dispatch({
            type: REMOVE_DELETE_SALARY
        });

        dispatch(getSalaries(getPage));

        dispatch(setAlert("Maosh o'chirildi !", 'success'));

        dispatch(clearErrors());

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SALARY_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updateSalary = (updateSalaryID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/salaries/${updateSalaryID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_SALARY});

        dispatch(getSalaries(getPage));

        dispatch(setAlert('Maosh tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SALARY_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteSalaryId = (salaryID) => async (dispatch) => {

    try {
        if (salaryID !== null) {
            dispatch({
                type: SET_DELETE_SALARY_ID,
                payload: {
                    salaryID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_SALARY
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateSalaryId = (salaryID) => async (dispatch) => {

    try {
        if (salaryID !== null) {
            dispatch({
                type: SET_UPDATE_SALARY_ID,
                payload: {
                    salaryID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_SALARY});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};








