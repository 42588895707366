import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {getProductTemplate} from "../../actions/product_template";
import avatar from "../../img/avatar.jpg";

function extractValue(arr, prop) {

    if (arr.length === 0) return [];
    // extract value from property
    let extractedValue = arr.map(item => item[prop]);

    return extractedValue;

}


const ProductTemplatePage = () => {

    useEffect(() => {
        dispatch(getProductTemplate(id));
    }, [getProductTemplate]);

    const dispatch = useDispatch();

    const {id} = useParams();

    const {product_template} = useSelector(state => state.product_template);

    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Mahsulot shabloni</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                {/*Mahsulot shabloni id: {product_template?.id}*/}
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Foydalanuvchi ma'umotlari</h5>
                                </div>
                                <div className="card-body text-center">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <h5 className="card-title text-black mb-0">{product_template?.description}</h5>
                                        </div>
                                        <div className="col-md-9">

                                            <div className="d-flex align-items-center">
                                                <div className="card-title m-2">Mahsulotlar:</div>
                                                <div
                                                    className="text-muted m-0">
                                                    {(extractValue(product_template?.products || [], 'name').join(', '))}
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div className="card-title m-2">Materiallar:</div>
                                                <div
                                                    className="text-muted m-0">
                                                    {(extractValue(product_template?.materials || [], 'name').join(', '))}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </div>
    );
};

export default ProductTemplatePage;