/* eslint-disable import/no-anonymous-default-export */
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    ACCOUNT_DELETED, SET_LOADING, UNSET_LOADING,
} from '../actions/types';

const initalState = {
    token: localStorage.getItem('token'),
    isAuthenticated: true,
    loading: true,
    user: null,
};


export default function (state = initalState, action) {
    const {type, payload} = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: false
            };
        case UNSET_LOADING:
            return {
                ...state,
                loading: false
            };
        case
        USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
            };
        case
        REGISTER_SUCCESS:
        case
        LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            };
        case
        REGISTER_FAIL:
        case
        AUTH_ERROR:
        case
        LOGIN_FAIL:
        case
        LOGOUT:
        case
        ACCOUNT_DELETED:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
            };
        default:
            return state;
    }
}
