import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteTransportId, setUpdateTransportId} from "../../actions/transport";
import {Link, useHistory} from 'react-router-dom'
import {Edit, Eye, Trash2} from "react-feather";

const TransportItem = ({
                           auth,
                           setDeleteTransportId,
                           setUpdateTransportId,
                           index,
                           transport: {
                               id, name, driver_id, plate,
                               driver,
                               date: created_at
                           },
                           showActions,
                           number
                       }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteTransport = (id) => {
        setDeleteTransportId(id);
    };

    const setUpdateTransport = (id) => {
        setUpdateTransportId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{plate}</td>
                <td>{`${driver?.first_name || ''}  ${driver?.last_name || ''}`}</td>
                <td>
                    <div className="actions">
                        <Link to={`transports/${id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'director' || role_name == 'superadmin' || role_name == 'manager' || role_name == 'accountant' ? (
                                <Edit onClick={() => setUpdateTransport(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'director' || role_name == 'superadmin' ? (
                                <Trash2 onClick={() => setDeleteTransport(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

TransportItem.defaultProps = {
    showActions: true,
};

TransportItem.propTypes = {
    transport: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteTransportId, setUpdateTransportId})(
    TransportItem
);
