import {Fragment} from "react";

function numberWithCommas(x) {
    if (typeof x !== 'string')
        x = new String(x);

    return x.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}


const MoneyFormat = props => {
    let {value, currency, decimal} = props;
    if (typeof value === 'undefined' && typeof decimal === 'undefined'){
        decimal = 0;
        // value = value?.toFixed(decimal || 0)
    }
    if (value){
        return <Fragment>{numberWithCommas(parseFloat(value).toFixed(decimal))} {currency}</Fragment>;
    }else return null;
}

export default MoneyFormat;