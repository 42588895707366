import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateFirmId, updateFirm} from "../../actions/firms";

const FirmUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_firm, currentPage, available_firms} = useSelector(state => state.firm);

    const closeModal = () => {
        // removing update_firm from redux by sending null to setUpdateFirmId
        dispatch(setUpdateFirmId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateFirm(update_firm.id, value, currentPage));
    };

    let condition = false;
    if (update_firm !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Yetkazib beruvchini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_firm} onValidSubmit={onSubmit}>

                        <AvField
                            name="name"
                            label="Nomi"
                            type="text"
                            required
                        />

                        <AvField
                            type="select"
                            name="manager_id"
                            label="Menejer"
                        >
                            <option key={0} value={''}>Tanlang</option>
                            {
                                available_firms.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>

                        <AvField
                            name="address"
                            label="Manzil"
                            type="text"
                            required
                        />


                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FirmUpdateForm;