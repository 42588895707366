import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_PRODUCT_TEMPLATE,
    GET_MATERIALS_ON_PRODUCT_TEMPLATES,
    GET_PRODUCT_TEMPLATE,
    GET_PRODUCT_TEMPLATE_UNIT_ALTERNATIVES_DATA,
    GET_PRODUCT_TEMPLATE_UNITS_DATA,
    GET_PRODUCT_TEMPLATES,
    GET_PRODUCTS_ON_PRODUCT_TEMPLATES,
    PRODUCT_ERROR,
    PRODUCT_TEMPLATE_ERROR,
    PRODUCT_UNITS_DATA_ERROR,
    REMOVE_UPDATE_PRODUCT_TEMPLATE,
    SET_DELETE_PRODUCT_TEMPLATE_ID,
    SET_UPDATE_PRODUCT_TEMPLATE_ID
} from './types';

export const getProductTemplateUnits = () => async (dispatch) => {
    try {
        const res = await axios.get('/units/');
        dispatch({
            type: GET_PRODUCT_TEMPLATE_UNITS_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_UNITS_DATA_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getProductTemplateUnitAlternatives = () => async (dispatch) => {
    try {
        const res = await axios.get('/unit-alternatives/');
        dispatch({
            type: GET_PRODUCT_TEMPLATE_UNIT_ALTERNATIVES_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_UNITS_DATA_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getProductTemplate = (productTemplateID) => async (dispatch) => {
    try {
        const res = await axios.get(`/product-templates/${productTemplateID}`);
        dispatch({
            type: GET_PRODUCT_TEMPLATE,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_TEMPLATE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getProductTemplates = (page = 1, filters = {}) => async (dispatch) => {
    console.log(GET_PRODUCT_TEMPLATES)
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/product-templates?page=${page}${queryParams}`);
        dispatch({
            type: GET_PRODUCT_TEMPLATES,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getProducts = () => async (dispatch) => {
    try {
        const res = await axios.get(`/products?is_available=true`);
        dispatch({
            type: GET_PRODUCTS_ON_PRODUCT_TEMPLATES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getMaterials = () => async (dispatch) => {

    try {
        const res = await axios.get(`/materials?is_all=true&without_relationship=true`);
        dispatch({
            type: GET_MATERIALS_ON_PRODUCT_TEMPLATES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const addProductTemplate = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/product-templates/', formData, config);

        dispatch(getProductTemplates());

        dispatch({
            type: ADD_PRODUCT_TEMPLATE,
            payload: res.data,
        });

        dispatch(setAlert('Mahsulot shabloni yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        dispatch({
            type: PRODUCT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const deleteProductTemplate = (deleteProductTemplateID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/product-templates/${deleteProductTemplateID}`, config);

        dispatch({
            type: SET_DELETE_PRODUCT_TEMPLATE_ID,
            payload: null
        });

        dispatch(getProductTemplates(getPage));

        dispatch(setAlert("Mahsulot shabloni o'chirildi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updateProductTemplate = (updateProductTemplateID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/product-templates/${updateProductTemplateID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_PRODUCT_TEMPLATE});

        dispatch(getProductTemplates(getPage));

        dispatch(setAlert('Mahsulot shabloni tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_TEMPLATE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteProductTemplateId = (product_templateID) => async (dispatch) => {

    try {
        if (product_templateID !== null) {
            dispatch({
                type: SET_DELETE_PRODUCT_TEMPLATE_ID,
                payload: {
                    product_templateID
                }
            });

        } else {
            dispatch({
                type: SET_DELETE_PRODUCT_TEMPLATE_ID,
                payload: null
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductTemplateId = (product_templateID) => async (dispatch) => {

    try {
        if (product_templateID !== null) {
            dispatch({
                type: SET_UPDATE_PRODUCT_TEMPLATE_ID,
                payload: {
                    product_templateID
                }
            });

        } else dispatch({type: SET_UPDATE_PRODUCT_TEMPLATE_ID, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};








