import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteFirmId, setUpdateFirmId} from "../../actions/firms";
import FirmaImg from '../../img/airplay.svg'
import Name from "../../img/codesandbox.svg";
import Manager from "../../img/user.svg";
import Map from "../../img/map-pin.svg";
import {Link} from "react-router-dom";
import {useHistory} from 'react-router-dom'
import {Edit, Eye, Trash2} from "react-feather";

const FirmCard = ({
                      auth,
                      setDeleteFirmId,
                      setUpdateFirmId,
                      index,
                      firm: {
                          id, name, manager_id, address, manager,
                          date: created_at
                      },
                      showActions,
                  }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteFirm = (id) => {
        setDeleteFirmId(id);
    };

    const setUpdateFirm = (id) => {
        setUpdateFirmId(id);
    };

    return (
        <Fragment>
            <div className="card mb-3">

                <div className="card-header">
                    <div className="row">

                        <div className="col-md-10 text-center d-flex align-items-center justify-content-center">
                            <img src={FirmaImg} alt=""/>
                            <h5 className="card-title mb-0 ms-2">Filiallar</h5>
                        </div>

                        <div className="col-md-2">
                            <div className="h-100 actions d-flex flex-column justify-content-evenly">
                                <Link to={`/firms/${id}`}><Eye/></Link>
                                {
                                    role_name == 'superadmin' || role_name == 'director' ? (
                                        <Edit onClick={() => setUpdateFirm(id)}/>
                                    ) : ''
                                }
                                {
                                    role_name == 'superadmin' || role_name == 'director' ? (
                                        <Trash2 onClick={() => setDeleteFirm(id)}/>
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body text-center py-3 px-1">
                    <div className="row m-0">

                        <div className="col-md-12">

                            <div>
                                <div className="row">
                                    <div className="col-md-4 align-items-center d-flex justify-content-between">
                                        <img src={Name} alt=""/>
                                        <div className="card-title m-2">
                                            Firma nomi:
                                        </div>
                                    </div>
                                    <div className="col-md-8 d-flex align-items-center">
                                        <div className="text-muted text-start m-0">{name}</div>
                                    </div>
                                </div>
                            </div>

                            {
                                role_name == 'superadmin' || role_name == 'director' ? (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4 align-items-center d-flex justify-content-between">
                                                <img src={Manager} alt=""/>
                                                <div className="card-title m-2">
                                                    Manager:
                                                </div>
                                            </div>
                                            <div className="col-md-8 d-flex align-items-center">
                                                <div className="text-muted text-start m-0">
                                                    <Link to={`/users/${manager?.id}`}>{manager?.name}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) : ''
                            }

                            <div>
                                <div className="row">
                                    <div className="col-md-4 align-items-center d-flex justify-content-between">
                                        <img src={Map} alt=""/>
                                        <div className="card-title m-2">
                                            Manzil:
                                        </div>
                                    </div>
                                    <div className="col-md-8 d-flex align-items-center">
                                        <div className="text-muted text-start m-0">{address}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
};

FirmCard.defaultProps = {
    showActions: true,
};

FirmCard.propTypes = {
    firm: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteFirmId, setUpdateFirmId})(
    FirmCard
);
