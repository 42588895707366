import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addUnitAlternative, clearErrors, getUnitsData} from "../../actions/unitalternative";

const UnitAlternativeCreateForm = ({
                                       addUnitAlternative,
                                       unitAlternatives,
                                       loading,
                                       error,
                                       getUnitsData,
                                       unitsData,
                                       handleShow,
                                       handleClose,
                                       show
                                   }) => {

    useEffect(() => {
        getUnitsData();
    }, [getUnitsData]);

    const formRef = useRef();

    const defaultValues = {
        unit_id: '',
        multiplier: '',
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            unit_id: inputs.unit_id,
            multiplier: inputs.multiplier,
        };
        addUnitAlternative(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [unitAlternatives]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi o'lchov birligi qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="unit_id">O'lchov birligi</label>
                            <select name="unit_id"
                                    value={inputs?.unit_id || ''}
                                    id="unit_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    unitsData.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="error">
                                {errors?.unit_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="multiplier">Miqdori</label>
                            <input
                                name="multiplier"
                                className={'form-control'}
                                type="number"
                                value={inputs?.multiplier || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.multiplier}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

UnitAlternativeCreateForm.propTypes = {
    addUnitAlternative: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    unitAlternative: state.unitalternative,
    unitAlternatives: state.unitalternative.unitAlternatives,
    unitsData: state.unitalternative.unitsData,
    loading: state.unitalternative.loading,
    error: state.unitalternative.error,
});


export default connect(mapStateToProps, {addUnitAlternative, getUnitsData, clearErrors})(UnitAlternativeCreateForm);
