import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";
import {setDeleteProductDeliveryId, setUpdateProductDeliveryId} from "../../actions/product-delivery";
import CurrencyFormat from "react-currency-format";

const ProductDeliveryItem = ({
                      auth,
                      setDeleteProductDeliveryId,
                      setUpdateProductDeliveryId,
                      index,
                      product_delivery: {
                          id, transport, client, driver, round, price,
                          date: created_at
                      },
                      showActions,
                  }) => {

    const history = useHistory();

    const setDeleteFirm = (id) => {
        setDeleteProductDeliveryId(id);
    };

    const setUpdateFirm = (id) => {
        setUpdateProductDeliveryId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{id}</th>
                <td>{transport.name}</td>
                <td>{client.name}</td>
                <td>{driver.first_name} {driver.lastname}</td>
                <td>{round}</td>
                <td>
                    <CurrencyFormat
                        value={price}
                        displayType={'text'}
                        thousandSeparator={true}
                        suffix={"  so'm"}/>
                </td>
                <td>
                    <div className="actions">

                        <svg onClick={()=>history.push(`product-deliveries/${id}`)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-eye align-middle mr-3">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>

                        <svg onClick={() => setUpdateFirm(id)} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-edit align-middle mr-3">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                        <svg onClick={() => setDeleteFirm(id)} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-trash-2 align-middle mr-3">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ProductDeliveryItem.defaultProps = {
    showActions: true,
};

ProductDeliveryItem.propTypes = {
    product_delivery: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteProductDeliveryId, setUpdateProductDeliveryId})(
    ProductDeliveryItem
);
