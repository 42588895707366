import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import { getProduct } from "../../actions/product";
import ProductionDeleteForm from "./ProductionDeleteForm";
import ProductAbout from "./tabs/ProductAbout";
import ProductProductions from "./tabs/ProductProductions";
import ProductSales from "./tabs/ProductSales";

const ProductPage = () => {

    useEffect(() => {
        dispatch(getProduct(id));
    }, [getProduct]);

    const dispatch = useDispatch();
    const {
        product,
    } = useSelector(state => state.product);
    const {id} = useParams();

    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Mahsulot</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>
                    {
                        product ? (
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="card px-4 py-3">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Row>
                                                <Col sm={3} md={3}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first"
                                                                      className="text-center fw-bold">Haqida</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second"
                                                                      className="text-center fw-bold">Ishlab
                                                                chiqarishlar</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="third"
                                                                      className="text-center fw-bold">Sotuvlar</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={9} md={9}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <ProductAbout product={product}/>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="second">

                                                            <ProductProductions />

                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="third">

                                                            <ProductSales />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>

                        ) : ''
                    }
                </div>

                <ProductionDeleteForm/>

            </main>
        </div>
    );
};

export default ProductPage;