import React, {useEffect, useState} from "react";
import useQueryParams from "../../utils/hooks/useQueryParams";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";

const SearchForm = ({getPageItems, search_text}) => {
    const dispatch = useDispatch();

    const {search, page} = useQueryParams();
    const [searchQuery, setSearchQuery] = useState(search ? search : '');
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        setIsFirst(false);
    }, [])
    useEffect(() => {
        if (!isFirst) {
            const timeOutId = setTimeout(() => onChangeSearch(searchQuery), 1000);
            return () => clearTimeout(timeOutId);
        }
    }, [searchQuery]);

    const onChangeSearch = (searchText) => {
        if (searchText !== '') {
            setSearchQuery(searchText);
            const url = new URL(window.location);
            url.searchParams.set('search', searchText);
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['page']) delete params['page'];
            dispatch(getPageItems(1, params));

        } else {
            setSearchQuery('');
            const url = new URL(window.location);
            url.searchParams.delete('search');
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['search']) delete params['search'];
            dispatch(getPageItems(1, params));
        }
    };

    return (
        <input type="search" placeholder="Qidiruv" value={searchQuery}
               className="form-control"
               id="inputSearch"
               onChange={e => setSearchQuery(e.target.value)}/>
    )
};

export default SearchForm;