import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getEmployee, setDeleteEmployeeId, setUpdateEmployeeId} from "../../actions/employee";
import {Link, useHistory} from 'react-router-dom'
import {Edit, Eye, Trash2} from "react-feather";

const EmployeeItem = ({
                          auth,
                          setDeleteEmployeeId,
                          setUpdateEmployeeId,
                          getEmployee,
                          employee: {
                              id,
                              firm,
                              profession,
                              status,
                              first_name,
                              last_name,
                              inn,
                              address,
                              phone,
                              balance,
                              date: created_at
                          },
                          showActions,
                          number
                      }) => {

    const history = useHistory();

    console.log(number)
    const {role_name} = auth?.user || {};

    const setDeleteEmployee = (id) => {
        setDeleteEmployeeId(id);
    };

    const setUpdateEmployee = (id) => {
        setUpdateEmployeeId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{first_name}</td>
                <td>{last_name}</td>
                <td>{inn}</td>
                <td>{address}</td>
                <td>{phone}</td>
                <td>{firm?.name}</td>
                <td>{profession}</td>
                {/*<td>{balance}</td>*/}
                <td>{status == 'hired' ? 'Ishlamoqda' : 'Ishdan ketgan'}</td>
                <td>
                    <div className="actions">

                        <Link to={`employees/${id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'director' || role_name == 'superadmin' || role_name == 'manager' ? (
                                <Edit onClick={() => setUpdateEmployee(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'director' || role_name == 'superadmin' ? (
                                <Trash2 onClick={() => setDeleteEmployee(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

EmployeeItem.defaultProps = {
    showActions: true,
};

EmployeeItem.propTypes = {
    employee: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteEmployeeId, getEmployee, setUpdateEmployeeId})(
    EmployeeItem
);
