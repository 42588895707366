import React, {Component, useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';


const getMonthName = (month) => {
    const months = [
        {
            id: "01",
            name: "Jan",
        },
        {
            id: "02",
            name: "Feb",
        },
        {
            id: "03",
            name: "Mar",
        },
        {
            id: "04",
            name: "Apr",
        },
        {
            id: "05",
            name: "May",
        },
        {
            id: "06",
            name: "Jun",
        },
        {
            id: "07",
            name: "Jul",
        },
        {
            id: "08",
            name: "Aug",
        },
        {
            id: "09",
            name: "Sep",
        },
        {
            id: "10",
            name: "Oct",
        },
        {
            id: "11",
            name: "Nov",
        },
        {
            id: "12",
            name: "Dec",
        }
    ];

    return months.find(item => item.id == month)?.name;
}

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const MonthlyBarChart = (props) => {

    const {metrics} = props;
    const chartRef = useRef();
    const [chartObject, setChartObject] = useState(null);
    const [labels, setLabels] = useState(months);
    const [data, setData] = useState([10000000, 5000000, 30000000]);

    useEffect(() => {


        const ctx = chartRef.current.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        var primaryColor = '#3B7DDD';
        var warningColor = '#fcb92c';
        var dangerColor = '#dc3545';
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        var primaryBorderColor = '#3B7DDD';

        if (!chartObject) {
            setChartObject(new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [{
                        label: (new Date()).getFullYear(),
                        backgroundColor: primaryBorderColor,
                        borderColor: primaryBorderColor,
                        hoverBackgroundColor: primaryBorderColor,
                        hoverBorderColor: primaryBorderColor,
                        data: data,
                        barPercentage: .75,
                        categoryPercentage: .5
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        // yAxes: [{
                        //     gridLines: {
                        //         display: false
                        //     },
                        //     stacked: false,
                        //     ticks: {
                        //         stepSize: 20
                        //     }
                        // }],
                        // xAxes: [{
                        //     stacked: false,
                        //     gridLines: {
                        //         color: "transparent"
                        //     }
                        // }]
                    }
                }
            }));
        }


    }, [])

    useEffect(() => {
        if (metrics) {
            // const labelItems = props.metrics?.map((metric) => getMonthName(metric.month));
            const dataItems = props.metrics?.map((metric) => metric.sale_quantity);

            setData(dataItems)
            setLabels(months);
            var primaryColor = '#3B7DDD';
            var warningColor = '#fcb92c';
            var dangerColor = '#dc3545';
            if (chartObject) {
                chartObject.data = {
                    labels: labels,
                    datasets: [{
                        data: dataItems,
                        label: (new Date()).getFullYear(),
                        backgroundColor: [
                            primaryColor,
                            warningColor,
                            dangerColor
                        ],
                        borderWidth: 5
                    }]
                };
                chartObject.update();
            }
        }
    }, [metrics])

    return (
        <div className="monthly_statistics">
            <canvas
                style={styles}
                id="monthly_statistics"
                ref={chartRef}
            />
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '350px'
}
export default MonthlyBarChart;