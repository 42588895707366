import React, {Component, useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';


const getMonthName = (month) => {
    const months = [
        {
            id: "01",
            name: "Jan",
        },
        {
            id: "02",
            name: "Feb",
        },
        {
            id: "03",
            name: "Mar",
        },
        {
            id: "04",
            name: "Apr",
        },
        {
            id: "05",
            name: "May",
        },
        {
            id: "06",
            name: "Jun",
        },
        {
            id: "07",
            name: "Jul",
        },
        {
            id: "08",
            name: "Aug",
        },
        {
            id: "09",
            name: "Sep",
        },
        {
            id: "10",
            name: "Oct",
        },
        {
            id: "11",
            name: "Nov",
        },
        {
            id: "12",
            name: "Dec",
        }
    ];

    return months.find(item => item.id == month)?.name;
}

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const ClientTransactionLineChart = (props) => {

    const {metrics} = props;
    const chartRef = useRef();
    const [chartObject, setChartObject] = useState(null);
    const [labels, setLabels] = useState(months);
    const [client_transactions, setClientTransactions] = useState([]);
    const [client_transactions2, setClientTransactions2] = useState([]);

    useEffect(() => {

        const ctx = chartRef.current.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        var primaryColor = '#3B7DDD';
        var warningColor = '#fcb92c';
        var dangerColor = '#dc3545';
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        var primaryBorderColor = '#3B7DDD';

        if (!chartObject) {
            setChartObject(new Chart(ctx, {
                type: "line",
                data: {
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    datasets: [
                        {
                            label: 'Klientlardan tushgan pullar',
                            fill: true,
                            backgroundColor: gradient,
                            borderColor: primaryBorderColor,
                            data: client_transactions
                        },
                        {
                            label: "Klientlarga sotuvlar qiymati",
                            fill: true,
                            backgroundColor: gradient,
                            borderColor: primaryBorderColor,
                            data: client_transactions2
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        intersect: false
                    },
                    hover: {
                        intersect: true
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    },
                    // scales: {
                    //     xAxes: [{
                    //         reverse: true,
                    //         gridLines: {
                    //             color: "rgba(0,0,0,0.0)"
                    //         },
                    //         ticks: {
                    //             callback: function (val) {
                    //                 return Number.isInteger(val) ? val : null;
                    //             }
                    //         }
                    //     }],
                    //     yAxes: [{
                    //         ticks: {
                    //             precision: 0,
                    //             stepSize: 1000,
                    //             beginAtZero: true,
                    //         },
                    //         display: true,
                    //         borderDash: [3, 3],
                    //         gridLines: {
                    //             color: "rgba(0,0,0,0.0)"
                    //         }
                    //     }]
                    // }
                }
            }));
        }

    }, [])

    useEffect(() => {
        if (metrics) {
            // const labelItems = props.metrics?.map((metric) => getMonthName(metric.month));
            const dataItems = props.metrics?.map((metric) => metric.total_received);
            setClientTransactions(dataItems);
            const dataItems2 = props.metrics?.map((metric) => metric.total_sold);
            setClientTransactions2(dataItems2);

            setLabels(months);
            var primaryColor = '#3B7DDD';
            var warningColor = '#fcb92c';
            var dangerColor = '#dc3545';
            if (chartObject) {
                chartObject.data = {
                    labels: labels,
                    datasets: [

                        {
                            data: dataItems,
                            label: 'Klientlardan tushgan pullar',
                            backgroundColor: 'transparent',
                            borderColor: 'rgb(82, 136, 255)',
                            lineTension: 0,
                            pointRadius: 4,
                            pointBackgroundColor: 'rgba(255,255,255,1)',
                            pointHoverBackgroundColor: 'rgba(255,255,255,0.6)',
                            pointHoverRadius: 8,
                            pointHitRadius: 30,
                            pointBorderWidth: 2,
                            pointStyle: 'rectRounded',
                            borderWidth: 5
                        },
                        {
                            data: dataItems2,
                            label: "Klientlarga sotuvlar qiymati",
                            backgroundColor: 'transparent',
                            borderColor: 'rgb(255, 9, 15)',
                            lineTension: 0,
                            pointRadius: 4,
                            pointBackgroundColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: 'rgba(255,255,255,0.6)',
                            pointHoverRadius: 8,
                            pointHitRadius: 30,
                            pointBorderWidth: 3,
                            pointStyle: 'rectRounded',
                            borderWidth: 5
                        }
                    ]
                };
                chartObject.update();
            }
        }
    }, [metrics])

    return (
        <div className="annual_statistics">
            <canvas
                style={styles}
                id="annual_statistics"
                ref={chartRef}
            />
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '350px'
}
export default ClientTransactionLineChart;