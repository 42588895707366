import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateSaleId, updateSale} from "../../actions/sale";
import Select from "react-select";

const SaleUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_sale, currentPage, client_ID_DATA, product_ID_DATA, seller_ID_DATA} = useSelector(state => state.sale);

    const [clientOptions, setClientOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [client_id, setClientId] = useState(null);
    const [product_id, setProductId] = useState(null);
    const [defaultClient, setDefaultClient] = useState({value: ''});
    const [defaultProduct, setDefaultProduct] = useState({value: ''});

    useEffect(() => {
        client_ID_DATA.forEach((item) => setClientOptions(clientOptions=>[...clientOptions, {
            label: `${item.name}`,
            value: item.id
        }]));
    }, [client_ID_DATA]);

    useEffect(() => {
        product_ID_DATA.forEach((item) => setProductOptions(productOptions=>[...productOptions, {
            label: `${item.name}`,
            value: item.id
        }]));
    }, [product_ID_DATA]);


    useEffect(() => {
        if (update_sale) {
            if (update_sale.client_id) {
                setClientId(update_sale.client_id);
                setDefaultClient({
                    label: `${update_sale.client_id}`,
                    value: update_sale.client_id
                })
            } else {
                setClientId('');
                setClientId({
                    label: 'Tanlang',
                    value: ''
                })
            }

            if (update_sale.product_id) {
                setProductId(update_sale.product_id);
                setDefaultProduct({
                    label: `${update_sale.product_id}`,
                    value: update_sale.product_id
                })
            } else {
                setProductId('');
                setProductId({
                    label: 'Tanlang',
                    value: ''
                })
            }

        }

    }, [update_sale]);

    const closeModal = () => {
        dispatch(setUpdateSaleId(null));
    };

    const onSelectClient = (data) => {
        setDefaultClient(data);
        setClientId(data.value);

    };

    const onSelectProduct = (data) => {
        setDefaultProduct(data);
        setProductId(data.value);
    };

    let condition = false;
    if (update_sale !== null)
        condition = true;

    const onSubmit = (event, value) => {

        value = {
            ...value,
            client_id: client_id,
            product_id: product_id,
        };

        setUpdateSaleId(null);
        dispatch(updateSale(update_sale.id, value, currentPage));
    };



    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Sotuvni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_sale} onValidSubmit={onSubmit}>

                        <div className="mb-2">
                            <label htmlFor="product_id">Mahsulot</label>
                            <Select
                                name={'product_id'}
                                options={productOptions}
                                onChange={value => onSelectProduct(value)}
                                value={defaultProduct}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                        </div>

                        <AvField
                            type="select"
                            name="seller_id"
                            label="Sotuvchi"
                        >
                            {
                                seller_ID_DATA.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>


                        <div className="mb-2">
                            <label htmlFor="client_id">Mijoz</label>
                            <Select
                                name={'client_id'}
                                options={clientOptions}
                                onChange={value => onSelectClient(value)}
                                value={defaultClient}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                        </div>


                        <AvField
                            name="quantity"
                            label="Miqdori"
                            type="number"
                            required
                        />

                        <AvField
                            name="price"
                            label="Narxi"
                            type="number"
                            required
                        />

                        <AvField
                            name="date"
                            label="Sana"
                            type="date"
                            required
                        />

                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SaleUpdateForm;