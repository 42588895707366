import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateEmployeeId, updateEmployee} from "../../actions/employee";
import {getParams} from "../../utils/hooks/getParams";

const EmployeeUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_employee, currentPage, firm_ID_DATA} = useSelector(state => state.employee);

    const closeModal = () => {
        // removing update_employee from redux by sending null to setUpdateEmployeeId
        dispatch(setUpdateEmployeeId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateEmployee(update_employee.id, value, getParams()));
    };

    let condition = false;
    if (update_employee !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title className="text-black">Xodimni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_employee} onValidSubmit={onSubmit}>

                        <AvField
                            name="first_name"
                            label="Ism"
                            type="text"
                            required
                        />

                        <AvField
                            name="last_name"
                            label="Familiya"
                            type="text"
                            required
                        />

                        <AvField
                            name="inn"
                            label="INN"
                            type="text"
                            required
                        />

                        <AvField
                            name="address"
                            label="Manzil"
                            type="text"
                            required
                        />

                        <AvField
                            name="phone"
                            label="Telefon"
                            type="text"
                            required
                        />

                        <AvField
                            type="select"
                            name="firm_id"
                            label="Firma"
                        >
                            {
                                firm_ID_DATA.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>

                        <AvField
                            name="profession"
                            label="Kasbi"
                            type="text"
                            required
                        />


                        <label htmlFor="radio" className="mb-2">Holati</label>

                        <AvRadioGroup id="radio" inline name="status" required errorMessage="Holatni tanlang !">
                            <AvRadio label="Ishga olingan" value="hired"/>
                            <AvRadio label="Ishdan olingan" value="fired"/>
                        </AvRadioGroup>

                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={closeModal}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EmployeeUpdateForm;