import {
    CLEAR_ERRORS,
    GET_EMPLOYEE_TRANSACTIONS,
    GET_EMPLOYEES_TRANSACTIONS,
    GET_MATERIAL_TRANSACTIONS,
    GET_MATERIALS_TRANSACTIONS,
    GET_PRODUCT_TRANSACTIONS,
    GET_PRODUCTS_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING,
    UPDATE_TRANSACTION_SUCCESS
} from '../actions/types';

const initialState = {
    dates: [],
    materials: [],
    loading: false,
    numberOfPages: 1,
    total_materials: null,
    error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }


        case UPDATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                update_transaction_id: true,
                error: null,
                loading: false,
            };

        case GET_MATERIALS_TRANSACTIONS:
            const {dates, materials} = payload;
            return {
                ...state,
                dates: dates,
                materials: materials.data,
                numberOfPages: materials.last_page,
                current_page: materials.current_page,
                materials_data: payload,
                error: null,
                loading: false,
            };

        case GET_MATERIAL_TRANSACTIONS:
            const {id} = payload;
            return {
                ...state,
                materials: state.materials.map(item => item.id == id ? payload : item),
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
