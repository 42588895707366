// import axios from 'axios';
// import { setAlert } from './alert';
import {
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    TOGGLE_SIDEBAR,
    SET_ACTIVE_MENU_ITEM, EXPAND_SIDEBAR, COLLAPSE_SIDEBAR
} from './types';


export const toggleSideBar = () => (dispatch) => {
    dispatch({
        type: TOGGLE_SIDEBAR,
    });
};
export const expandSideBar = () => (dispatch) => {
    dispatch({
        type: EXPAND_SIDEBAR,
    });
};

export const collapseSideBar = () => (dispatch) => {
    dispatch({
        type: COLLAPSE_SIDEBAR,
    });
};

export const hideSidebar = () => (dispatch) => {
    dispatch({
        type: HIDE_SIDEBAR,
    });
};

export const showSidebar = () => (dispatch) => {
    dispatch({
        type: SHOW_SIDEBAR,
    });
};

export const setActiveItem = (route) => (dispatch) => {
    dispatch({
        type: SET_ACTIVE_MENU_ITEM,
        payload: route
    });
};
