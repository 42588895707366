import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteExpenseId, setUpdateExpenseId} from "../../actions/expense";
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import {Edit, Trash2} from "react-feather";

const formatMoney = (value, seperator = ',') => {
    if (value) {
        return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, seperator);
    } else
        return 0;
}

const ExpenseItem = ({
                         auth,
                         setDeleteExpenseId,
                         setUpdateExpenseId,
                         number,
                         expense: {
                             id, price, quantity, description,
                             category,
                             name,
                             created_at: date
                         },
                         showActions,
                     }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteExpense = (id) => {
        setDeleteExpenseId(id);
    };

    const setUpdateExpense = (id) => {
        setUpdateExpenseId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{category}</td>
                <td>{quantity}</td>
                <td>{formatMoney(price)} so'm</td>
                <td>{formatMoney(price * quantity)} so'm</td>
                <td>{description}</td>
                <td>{moment(date).format("YYYY-MM-DD")}</td>

                {
                    role_name == 'superadmin' || role_name == 'director' || role_name == 'accountant' ? (
                        <td>
                            <div className="actions">
                                <Edit onClick={() => setUpdateExpense(id)}/>
                                <Trash2 onClick={() => setDeleteExpense(id)}/>
                            </div>
                        </td>
                    ) : ''
                }
            </tr>
        </Fragment>
    );
};

ExpenseItem.defaultProps = {
    showActions: true,
};

ExpenseItem.propTypes = {
    expense: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteExpenseId, setUpdateExpenseId})(
    ExpenseItem
);
