import {Link} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatMoney, unformatMoney} from "../../utils/hooks/getParams";
import {getSupplierTransactions} from "../../actions/suppliers_transaction";
import SupplierDataCell from "./SupplierDataCell";

const SupplierDataRow = ({index, item, resetAllRows}) => {
    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        setRowData(itemData.transactions);
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getSupplierTransactions(id));
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`suppliers/${itemData.id}`}>
                    {itemData.name}
                </Link>
            </th>
            {
                rowData.map((subitem, subindex) => (
                    <SupplierDataCell
                        rowData={rowData}
                        key={subindex}
                        row_id={itemData.id}
                        item={subitem}
                        cellItem={subitem}
                        index={subindex}
                        setItemData={setItemData}
                        setRowData={setRowData}
                        refreshRow={refreshRow}
                        resetAllRows={() => console.log(2)}
                    />))
            }
            <th style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                {formatMoney(itemData.prev_month_left_amount)}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                {formatMoney(itemData.transactions_sum_received_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                {formatMoney(itemData.transactions_sum_paid_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                {formatMoney(itemData.current_month_left_amount)}
            </th>
        </tr>
    )
}

export default SupplierDataRow;