import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateSalaryId, updateSalary} from "../../actions/salary";
import Select from 'react-select';

const SalaryUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_salary, currentPage, employees_ID_data} = useSelector(state => state.salary);


    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [employee_id, setEmployeeId] = useState(null);
    const [defaultEmployee, setDefaultEmployee] = useState({value: ''});


    useEffect(() => {
        employees_ID_data.forEach((item) => setEmployeeOptions(employeeOptions=>[...employeeOptions, {
            label: `${item.first_name} ${item.last_name}`,
            value: item.id
        }]));
    }, [employees_ID_data]);


    useEffect(() => {
        if (update_salary) {
            if (update_salary.employee_id) {
                setEmployeeId(update_salary.employee_id);
                setDefaultEmployee({
                    label: `${update_salary.employee.first_name} ${update_salary.employee.last_name}`,
                    value: update_salary.employee_id
                })
            } else {
                setEmployeeId('');
                setEmployeeId({
                    label: 'Tanlang',
                    value: ''
                })
            }
        }
    }, [update_salary]);

    const closeModal = () => {
        dispatch(setUpdateSalaryId(null));
    };

    const onSelect = (data) => {
        setDefaultEmployee(data);
        setEmployeeId(data.value);
    };

    let condition = false;
    if (update_salary !== null)
        condition = true;

    const onSubmit = (event, value) => {

        value = {
            ...value,
            employee_id: employee_id
        };

        setEmployeeId(null);
        dispatch(updateSalary(update_salary.id, value, currentPage));
    };


    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Ish haqqini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_salary} onValidSubmit={onSubmit}>

                        <div className="mb-2">
                            <label htmlFor="product_template_id">Xodim</label>
                            <Select
                                name={'product_template_id'}
                                options={employeeOptions}
                                onChange={value => onSelect(value)}
                                value={defaultEmployee}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                        </div>



                        <AvField
                            name="amount"
                            label="Miqdori"
                            type="number"
                            required
                        />

                        <AvField
                            type="select"
                            name="type"
                            label="Turi"
                        >
                            <option value="fixed">Belgilangan ish haqi</option>
                            <option value="daily">Kunlik</option>
                        </AvField>


                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SalaryUpdateForm;