import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addClient, clearErrors} from "../../actions/client";
import {getAvailableSuppliers} from "../../actions/supplier";
import Select from "react-select";
import {getSelectedOption} from "../../utils/hooks/getParams";

const ClientCreateForm = ({
                              addClient,
                              handleShow,
                              error,
                              loading,
                              clients,
                              available_suppliers,
                              handleClose,
                              getAvailableSuppliers,
                              show
                          }) => {

    const formRef = useRef();

    const defaultValues = {
        is_special_client: '',
        name: '',
        contractor: '',
        inn: '',
        phone: '',
        type: '',
        supplier_id: '',
        bank_account: '',
    };

    useEffect(() => {
        getAvailableSuppliers();
    }, [])

    const [isAvailable_client, setIsAvailable_client] = useState(false);

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});


    const [supplierOptions, setSupplierOptions] = useState([]);

    useEffect(() => {
        const items = [];
        available_suppliers?.forEach((supplier) => items.push({
            label: supplier?.name,
            value: supplier?.id,
        }));
        setSupplierOptions(items);
    }, [available_suppliers]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs?.name,
            contractor: inputs?.contractor,
            inn: inputs?.inn,
            phone: inputs?.phone,
            type: inputs?.type,
            supplier_id: inputs?.supplier_id,
            bank_account: inputs?.bank_account,
            is_special_client: Number(isAvailable_client),

        };
        addClient(data);
    };

    const handleSelect = (data, type) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };


    const handleChange = e => {
        setErrors({});

        e.target.oldValue = e.target.value;
        if (e.target.name === 'is_special_client') {
            setIsAvailable_client(e.target.checked);
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.checked}));
        } else {
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
        }
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error?.errors) {
                if (error && error?.errors) {
                    Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                }
            }
            setErrors(errorObject);

        }
    }, [error]);


    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
        setIsAvailable_client(false);
    }, [clients]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };


    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi mijoz qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">


                    <form onSubmit={handleSubmit} ref={formRef}>


                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                id="name"
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="contractor">Tashkilot xodimi</label>
                            <input
                                id="contractor"
                                name="contractor"
                                className={'form-control'}
                                type="text"
                                value={inputs?.contractor || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.contractor}
                            </div>
                        </div>


                        <div className="mb-3 d-flex align-items-middle">
                            <input
                                name="is_special_client"
                                id="is_special_client"
                                className={"form-check-input"}
                                style={{marginRight: '0.3rem'}}
                                type="checkbox"
                                value={inputs?.is_special_client}
                                checked={inputs?.is_special_client}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />
                            <label htmlFor="is_special_client">Maxsus mijoz!</label>
                        </div>


                        {
                            isAvailable_client ?
                                <div className="mb-3">
                                    <label htmlFor="supplier_id">Yetkazuvchi</label>
                                    <Select
                                        name={'supplier_id'}
                                        options={supplierOptions}
                                        value={getSelectedOption(inputs?.supplier_id, supplierOptions)}
                                        onChange={handleSelect}
                                        defaultValue={{label: 'Tanlang', value: ''}}
                                    />
                                </div>
                                : ''
                        }


                        <div className="mb-3">
                            <label htmlFor="inn">INN</label>
                            <input
                                id="inn"
                                name="inn"
                                className={'form-control'}
                                type="text"
                                value={inputs?.inn || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.inn}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="phone">Telefon</label>
                            <input
                                id="phone"
                                name="phone"
                                className={'form-control'}
                                type="phone"
                                value={inputs?.phone || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.phone}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="type">Turi</label>
                            <select name="type"
                                    value={inputs?.type || ''}
                                    id="type"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                <option value={"jismoniy"}>Jismoniy</option>
                                <option value={"yuridik"}>Yuridik</option>
                            </select>
                            <div className="error">
                                {errors?.type}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="bank_account">Bank hisob raqami</label>
                            <input
                                id="bank_account"
                                name="bank_account"
                                className={'form-control'}
                                type="number"
                                value={inputs?.bank_account || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.bank_account}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

ClientCreateForm.propTypes = {
    addClient: PropTypes.func.isRequired,
    getAvailableSuppliers: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
    loading: state.client.loading,
    clients: state.client.clients,
    available_suppliers: state.supplier.available_suppliers,
    error: state.client.error,
});

export default connect(mapStateToProps, {addClient, clearErrors, getAvailableSuppliers})(ClientCreateForm);
