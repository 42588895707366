import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import UserForm from './UserCreateForm';
import UserItem from './UserItem';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getFilteredUsers, getUsers, setSearchText} from '../../actions/user';
import Spinner from '../layout/Spinner';
import UserDeleteForm from "./UserDeleteForm";
import UserUpdateForm from "./UserUpdateForm";
import CustomPagination from "../layout/CustomPagination";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import FilterByFirm from "../suppliers_material_transactions/FilterByFirm";
import {getWarehouses} from "../../actions/warehouse";

const Users = (props) => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {
        users, loading, numberOfPages, currentPage, total_users
    } = useSelector(state => state.user);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong>Tizimdagi</strong> foydalanuvchilar ({total_users}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3">
                        <FilterByFirm getPageItems={getUsers}/>
                    </div>
                    <div className="col-md-3">
                        <SearchForm getPageItems={getUsers}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            {
                                role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' ? (
                                    <button className="btn btn-primary" onClick={handleShow}>
                                        Foydalanuvchi
                                        <Plus/>
                                    </button>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">FIO</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Firma</th>
                                    <th scope="col">Lavozimi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    users.map((item, index) => (
                                        <UserItem key={`user${item.id}`} number={index + 1} user={item}/>
                                    ))
                                }
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getUsers}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>

            {
                role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' || role_name == 'accountant' ? (
                    <UserForm show={show} handleShow={handleShow} handleClose={handleClose}/>
                ) : ''
            }


            <UserDeleteForm/>
            <UserUpdateForm/>

        </Fragment>
    );
};

// Users.propTypes = {
//     getUsers: PropTypes.func.isRequired,
//     getFilteredUsers: PropTypes.func.isRequired,
//     setSearchText: PropTypes.func.isRequired,
//     user: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//     user: state.user,
// });

// export default connect(mapStateToProps, {getUsers, getFilteredUsers, setSearchText})(Users);
export default Users;
