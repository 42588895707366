import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import UnitDeleteForm from "./UnitDeleteForm";
import UnitUpdateForm from "./UnitUpdateForm";
import {getUnits} from "../../actions/unit";
import UnitCreateForm from "./UnitCreateForm";
import UnitItem from "./UnitItem";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";

const Units = ({getUnits, unit}) => {

    const {units, loading} = unit;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUnits()
    }, []);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> kirim o'lchov birliklari ({units.length})
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getUnits}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                O'lchov birligi <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">O'lchov nomi</th>
                                    <th scope="col">Qisqartmasi</th>
                                    <th scope="col">Qiymati</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {units.map((item, index) => (
                                    <UnitItem key={`unit${item.id}`} number={index + 1} unit={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

            </div>


            <UnitCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <UnitDeleteForm/>

            <UnitUpdateForm/>


        </Fragment>
    );
};


Units.propTypes = {
    getUnits: PropTypes.func.isRequired,
    unit: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    unit: state.unit,
});

export default connect(mapStateToProps, {getUnits})(Units);
