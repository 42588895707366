import {Link} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {getParams, useOuterClick} from "../../utils/hooks/getParams";
import {getClientsTransactions, getClientTransactions, updateTransaction} from "../../actions/client";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import ClientDataCell from "./ProductMaterialDataCell";
import {formatMoney, unformatMoney} from "../../utils/hooks/getParams";
import {getSupplierTransactions} from "../../actions/supplier";
import ProductMaterialDataCell from "./ProductMaterialDataCell";

const ProductMaterialDataRow = ({index, item, resetAllRows}) => {
    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const [itemData, setItemData] = useState({
        material_transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        if (item.hasOwnProperty('material_transactions')) {
            setRowData(itemData.material_transactions);
        }
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getSupplierTransactions(id));
    }


    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`products/${item.id}`}>
                    {item.name}
                </Link>
            </th>
            {
                rowData.map((subitem, subindex) => (
                    <ProductMaterialDataCell
                        rowData={rowData}
                        key={subindex}
                        row_id={itemData.id}
                        item={subitem}
                        cellItem={subitem}
                        index={subindex}
                        setItemData={setItemData}
                        setRowData={setRowData}
                        refreshRow={refreshRow}
                        resetAllRows={() => console.log(2)}
                    />))
            }
            {/*<th style={{textAlign: "center", minWidth: "100px", right: "300px"}}>*/}
            {/*    {formatMoney(itemData.prev_month_left_amount)}*/}
            {/*</th>*/}
            {/*<th style={{textAlign: "center", minWidth: "100px", right: "200px"}}>*/}
            {/*    {formatMoney(itemData.material_transactions_sum_received_amount, ',')}*/}
            {/*</th>*/}
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                {formatMoney(itemData.material_transactions_sum_spent_amount, ',')}
            </th>
            {/*<th style={{textAlign: "center", minWidth: "100px", right: "0px"}}>*/}
            {/*    {formatMoney(itemData.current_month_left_amount)}*/}
            {/*</th>*/}
        </tr>
    )
}

export default ProductMaterialDataRow;