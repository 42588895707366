import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteForTransportProductDelivery, setDeleteTransportProductDeliveryId} from "../../actions/transport";

const TransportProductDeliveryDeleteForm = ({transport_id}) => {

    const dispatch = useDispatch();

    const {delete_transport_product_delivery} = useSelector(state => state.transport);

    const closeModal = () => {
        dispatch(setDeleteTransportProductDeliveryId(null))
    };

    const onConfirm = () => {
        dispatch(deleteForTransportProductDelivery(delete_transport_product_delivery.id, transport_id));
    };

    let condition = false;
    if (delete_transport_product_delivery !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Yetkazib berishni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu yetkazib berishni o'chirmoqchimisiz?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TransportProductDeliveryDeleteForm;