import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import SalaryDeleteForm from "./SalaryDeleteForm";
import SalaryUpdateForm from "./SalaryUpdateForm";
import SalaryCreateForm from "./SalaryCreateForm";
import SalaryItem from "./SalaryItem";
import {getSalaries} from "../../actions/salary";
import "./salary.css";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";

const Salaries = (props) => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {salaries, loading, numberOfPages, currentPage, total_salaries} = useSelector(state => state.salary);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> xodimlarning ish haqqilari ({total_salaries})
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getSalaries}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Oylik
                                <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Xodim</th>
                                    <th scope="col">Miqdori</th>
                                    <th scope="col">Turi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {salaries.map((item, index) => (
                                    <SalaryItem key={`salary${item.id}`} number={index + 1} salary={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>


                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            getPageItems={getSalaries}
                            numberOfPages={numberOfPages}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <SalaryCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <SalaryDeleteForm/>

            <SalaryUpdateForm/>


        </Fragment>
    );
};

Salaries.propTypes = {
    getSalaries: PropTypes.func.isRequired,
    salary: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    salary: state.salary,
});

export default connect(mapStateToProps, {getSalaries})(Salaries);
