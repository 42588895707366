import React, {useEffect, useState} from 'react';
import {Pagination} from 'react-bootstrap';
import useQueryParams from "../../utils/hooks/useQueryParams";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";

const scrollToTop = (x = 0, y = 0) => {
    window.scroll(x, y);
};

const CustomPagination = props => {
    const dispatch = useDispatch();
    const {page} = useQueryParams();
    const params = getParams();
    const {getPageItems, numberOfPages, current_page, items, custom_params} = props;
    const [pageItems, setPageItems] = useState(items);
    const [innerCurrentPage, setInnerCurrentPage] = useState(current_page);

    const [firstRun, setFirstRun] = useState(true);

    useEffect(() => {
        setFirstRun(false);
        const params = getParams();
        if (page && params) {
            if (params['page']) delete params['page'];
            const new_params = {...custom_params, ...params};
            dispatch(getPageItems(page, new_params));
            setInnerCurrentPage(Number(page));
        } else {
            if (params['page']) delete params['page'];
            const new_params = {...params, ...custom_params};
            dispatch(getPageItems(1, new_params));
            setInnerCurrentPage(1);
        }
    }, [])

    useEffect(() => {
        if (Number(page) !== Number(innerCurrentPage)) {
            setInnerCurrentPage(Number(page))
        }
    }, [page])

    const onPageClick = (newPage) => {
        const url = new URL(window.location);
        url.searchParams.set('page', newPage);
        window.history.pushState({}, '', url);
        const params = getParams();
        setInnerCurrentPage(Number(newPage));
        if (delete params['page']) ;
        const new_params = {...custom_params, ...params};
        dispatch(getPageItems(newPage, new_params));
        scrollToTop();
    }


    useEffect(() => {
        let items = [];

        items.push(
            <Pagination.First onClick={(event) => onPageClick(1)} key={`page0`}/>
        );

        for (let number = 1; number <= numberOfPages; number++) {
            items.push(
                <Pagination.Item onClick={(event) => onPageClick(number)} key={`page${number}`}
                                 active={number === Number(current_page)}>
                    {number}
                </Pagination.Item>,
            );
        }
        items.push(
            <Pagination.Last onClick={(event) => onPageClick(numberOfPages)} key={`page${numberOfPages + 1}`}/>
        );
        setPageItems(items);

    }, [current_page, numberOfPages]);

    return numberOfPages > 1 ? <Pagination>{pageItems}</Pagination> : '';
}


export default CustomPagination;
