import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateExpenseTemplateId, updateExpenseTemplate} from "../../actions/expense_template";

const ExpenseTemplateUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_expense_template} = useSelector(state => state.expense_template);

    const closeModal = () => {
        dispatch(setUpdateExpenseTemplateId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateExpenseTemplate(update_expense_template.id, value));
    };

    let condition = false;
    if (update_expense_template !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Harajat shablonini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_expense_template} onValidSubmit={onSubmit}>

                        <AvField
                            type="text"
                            name="name"
                            label="Name"
                            required
                        />

                        <AvField
                            name="category"
                            label="Category"
                            type="text"
                            required
                        />

                        <AvField
                            name="type"
                            label="Type"
                            type="select"
                            required
                        >
                            <option value="">Tanlang</option>
                            <option value="daily">Kunlik</option>
                            <option value="monthly">Oylik</option>
                            <option value="annual">Yillik</option>
                            <option value="one-time">Bir martalik</option>

                        </AvField>

                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ExpenseTemplateUpdateForm;