import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addUnit, clearErrors} from "../../actions/unit";

const UnitCreateForm = ({addUnit, handleShow, handleClose, show, error, loading, units, clearErrors}) => {

    const formRef = useRef();

    const defaultValues = {
        name: '',
        symbol: '',
        value: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            symbol: inputs.symbol,
            value: inputs.value,
        };
        addUnit(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [units]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi o'lchov birligi qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">O'lchov birligi nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="symbol">Qisqartmasi</label>
                            <input
                                name="symbol"
                                className={'form-control'}
                                type="text"
                                value={inputs?.symbol || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.symbol}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="symbol">Qiymati</label>
                            <input
                                name="value"
                                className={'form-control'}
                                type="number"
                                value={inputs?.value || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.value}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

UnitCreateForm.propTypes = {
    addUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    unit: state.unit,
    loading: state.unit.loading,
    units: state.unit.units,
    error: state.unit.error,
});


export default connect(mapStateToProps, {addUnit, clearErrors})(UnitCreateForm);
