import {Link} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {getClientTransactions} from "../../actions/clients_transaction";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import ClientDataCell from "./ProductDataCell";
import {formatMoney, unformatMoney} from "../../utils/hooks/getParams";
import {getEmployeeTransactions} from "../../actions/employees_transaction";
import ProductDataCell from "./ProductDataCell";

const ProductDataRow = ({index, item, resetAllRows}) => {
    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        setRowData(itemData.transactions);
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getEmployeeTransactions(id));
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`products/${itemData.id}`}>
                    {itemData.name}
                </Link>
            </th>
            {
                rowData?.map((subitem, subindex) => (
                    <ProductDataCell
                        rowData={rowData}
                        key={subindex}
                        row_id={itemData.id}
                        item={subitem}
                        cellItem={subitem}
                        index={subindex}
                        setItemData={setItemData}
                        setRowData={setRowData}
                        refreshRow={refreshRow}
                        resetAllRows={() => console.log(2)}
                    />))
            }
            <th style={{textAlign: "center", minWidth: "100px", right: "300px"}}
                title={formatMoney(itemData.prev_month_left_amount)}
            >
                {formatMoney(itemData.prev_month_left_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}
                title={formatMoney(itemData.transactions_sum_produced_amount, ',')}
            >
                {formatMoney(itemData.transactions_sum_produced_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}
                title={formatMoney(itemData.transactions_sum_sold_amount, ',')}
            >
                {formatMoney(itemData.transactions_sum_sold_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}
                title={formatMoney(itemData.current_month_left_amount, ',')}
            >
                {formatMoney(itemData.current_month_left_amount?.toFixed(2))}
            </th>
        </tr>
    )
}

export default ProductDataRow;