import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addWarehouse, getAvailableStorekeeperUsers} from "../../actions/warehouse";
import Select from "react-select";
import {clearErrors} from "../../actions/warehouse";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";

const WarehouseCreateForm = ({
                                 warehouse: {
                                     available_warehouse_users,
                                     error,
                                     loading,
                                     warehouses,
                                     available_warehouse_firms
                                 },
                                 addWarehouse,
                                 getAvailableStorekeeperUsers,
                                 handleShow,
                                 handleClose,
                                 show
                             }) => {

    const [storekeeperOptions, setStorekeeperOptions] = useState([]);

    useEffect(() => {
        let items = [];
        available_warehouse_users.forEach((item) => items.push({
                label: `${item.name}`,
                value: item.id
            })
        );
        setStorekeeperOptions(items);
    }, [available_warehouse_users]);

    useEffect(() => {
        getAvailableStorekeeperUsers();
    }, [getAvailableStorekeeperUsers]);

    const formRef = useRef();

    const defaultValues = {
        name: '',
        firm_id: '',
        storekeeper_id: '',
        type: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const params = getParams(true);
        addWarehouse(inputs, params);
    };

    const handleChange = e => {
        setErrors({});
        if (e.target.name == 'firm_id' && e.target.value != '') {

            getAvailableStorekeeperUsers({firm_id: e.target.value});
        }
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSelect = (data, type) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [warehouses]);
    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi sklad qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>


                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <select name="firm_id"
                                    value={inputs?.firm_id || ''}
                                    id="firm_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    available_warehouse_firms.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="storekeeper_id">Skladchi</label>
                            <Select
                                name={'storekeeper_id'}
                                options={storekeeperOptions}
                                value={getSelectedOption(inputs.storekeeper_id, storekeeperOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.storekeeper_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="type">Turi</label>
                            <select name="type"
                                    value={inputs?.type || ''}
                                    id="type"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                <option value="product">Mahsulot</option>
                                <option value="part">Zapchast</option>
                                <option value="material">Material</option>
                            </select>
                            <div className="error">
                                {errors?.type}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

WarehouseCreateForm.propTypes = {
    addWarehouse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    warehouse: state.warehouse,
    firm: state.firm
});

export default connect(mapStateToProps, {addWarehouse, getAvailableStorekeeperUsers, clearErrors})(WarehouseCreateForm);
