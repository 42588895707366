import {
    ADD_CLIENT,
    ADD_CLIENT_TRANSACTION,
    CLEAR_ERRORS,
    CLIENT_ERROR,
    GET_AVAILABLE_CLIENTS,
    GET_CLIENT,
    GET_CLIENT_SALES,
    GET_CLIENT_TRANSACTIONS,
    GET_CLIENTS,
    GET_CLIENTS_TRANSACTIONS,
    GET_FILTERED_CLIENT_SALES,
    GET_FILTERED_CLIENT_TRANSACTION,
    GET_FILTERED_PRODUCT_SALE,
    GET_FILTERED_SUPPLIER_SALARY,
    GET_PRODUCT_SALES,
    REMOVE_DELETE_CLIENT,
    REMOVE_DELETE_CLIENT_SALE,
    REMOVE_DELETE_PRODUCT_PRODUCTION,
    REMOVE_UPDATE__CLIENT_SALE,
    REMOVE_UPDATE_CLIENT,
    REMOVE_UPDATE_CLIENT_TRANSACTION,
    REMOVE_UPDATE_PRODUCT_SALE,
    SET_DELETE_CLIENT_ID,
    SET_DELETE_CLIENT_SALE_ID,
    SET_DELETE_PRODUCT_PRODUCTION_ID, SET_LOADING,
    SET_UPDATE_CLIENT_ID,
    SET_UPDATE_CLIENT_SALE_ID,
    SET_UPDATE_CLIENT_TRANSACTION_ID,
    SET_UPDATE_PRODUCT_SALE_ID, UNSET_LOADING,
    UPDATE_TRANSACTION_SUCCESS
} from '../actions/types';

const initialState = {
    clients: [],
    client: {},
    client_transactions: [],
    update_client: null,
    delete_client: null,
    loading: false,
    numberOfPages: 1,
    error: null,
    total_clients: null,
    filtered_client_sale_data_date: null,
    client_sales: [],
    update_client_sale: null,
    delete_client_sale: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case GET_AVAILABLE_CLIENTS:

            return {
                ...state,
                available_clients: payload.data,
                loading: false
            };

        case GET_CLIENT:
            return {
                ...state,
                client: payload.data,
                error: null,
                loading: false,
            };

        case GET_CLIENTS:
            return {
                ...state,
                clients: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_clients: payload.meta.total,
                error: null,
                loading: false,
            };
        case GET_FILTERED_SUPPLIER_SALARY:
            return {
                ...state,
                filtered_client_transaction_data_date: payload.data,
                loading: false,
            };

        case GET_CLIENT_TRANSACTIONS:
            return {
                ...state,
                client_transactions: payload.data,
                loading: false,
            };


        case ADD_CLIENT:
            return {
                ...state,
                clients: [payload],
                error: null,
                loading: false,
            };

        case ADD_CLIENT_TRANSACTION:
            return {
                ...state,
                client_transactions: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_CLIENT:
            return {
                ...state,
                delete_client: null,
            };

        case REMOVE_UPDATE_CLIENT:
            return {
                ...state,
                update_client: null,
            };

        case SET_DELETE_CLIENT_ID:

            return {
                ...state,
                delete_client: state.clients.find((item) => item.id === action.payload.clientID)
            };

        case SET_UPDATE_CLIENT_ID:

            return {
                ...state,
                update_client: state.clients.find((item) => item.id === action.payload)
            };

        case CLIENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };


        case GET_CLIENT_SALES:
            return {
                ...state,
                client_sales: payload.data,
                error: null,
                loading: false,
            };

        case REMOVE_UPDATE__CLIENT_SALE:
            return {
                ...state,
                update_client_sale: null,
            };

        case REMOVE_DELETE_CLIENT_SALE:
            return {
                ...state,
                delete_client_sale: null,
            };

        case SET_UPDATE_CLIENT_SALE_ID:
            return {
                ...state,
                update_client_sale: payload
            };

        case SET_DELETE_CLIENT_SALE_ID:
            return {
                ...state,
                delete_client_sale: payload
            };

        default:
            return state;
    }
}
