import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addSalary, clearErrors, getEmployeesIDData} from "../../actions/salary";
import Select from 'react-select'
import {getSelectedOption} from "../../utils/hooks/getParams";

const SalaryCreateForm = ({
                              salary: {employees_ID_data, error, loading, salaries},
                              addSalary,
                              getEmployeesIDData,
                              handleShow,
                              handleClose,
                              show
                          }) => {

    const [employeeOptions, setEmployeeOptions] = useState([]);


    useEffect(() => {
        getEmployeesIDData({without_salary: true});
    }, [getEmployeesIDData]);

    useEffect(() => {
        employees_ID_data.forEach((item) => setEmployeeOptions(employeeOptions => [...employeeOptions, {
            label: `${item.first_name} ${item.last_name}`,
            value: item.id
        }]));
    }, [employees_ID_data]);

    
    const handleSelect = (option, action) => {
        inputs[action.name] = option.value;
        setInputs(inputs);
    };

    const formRef = useRef();

    const defaultValues = {
        employee_id: '',
        amount: '',
        type: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            employee_id: inputs.employee_id,
            amount: inputs.amount,
            type: inputs.type,
        };
        addSalary(data);
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [salaries]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };


    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi ish haqqi qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">


                    <form onSubmit={handleSubmit} ref={formRef}>


                        <div className="mb-3">
                            <label htmlFor="employee_id">Xodim</label>
                            <Select
                                name={'employee_id'}
                                options={employeeOptions}
                                onChange={handleSelect}
                                value={getSelectedOption(inputs.employee_id, employeeOptions)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                            <div className="error">
                                {errors?.employee_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="amount">Miqdori</label>
                            <input
                                name="amount"
                                className={'form-control'}
                                type="number"
                                value={inputs?.amount || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.amount}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="type">Turi</label>
                            <select name="type"
                                    value={inputs?.type || ''}
                                    id="type"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                <option value="fixed">Belgilangan ish haqi</option>
                                <option value="daily">Kunlik</option>
                            </select>
                            <div className="error">
                                {errors?.type}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

SalaryCreateForm.propTypes = {
    addSalary: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    salary: state.salary,
});


export default connect(mapStateToProps, {addSalary, getEmployeesIDData, clearErrors})(SalaryCreateForm);
