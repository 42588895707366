import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_UNIT_ALTERNATIVE, CLEAR_ERRORS, GET_UNIT_ALTERNATIVE,
    GET_UNIT_ALTERNATIVES,
    GET_UNITS_DATA,
    GET_UNITS_DATA_ERROR,
    REMOVE_DELETE_UNIT_ALTERNATIVE,
    REMOVE_UPDATE_UNIT_ALTERNATIVE,
    SET_DELETE_UNIT_ALTERNATIVE_ID,
    SET_UPDATE_UNIT_ALTERNATIVE_ID,
    UNIT_ALTERNATIVE_ERROR, UNIT_ERROR
} from './types';

export const getUnitAlternative = (unitAlternativeID) => async (dispatch) => {
    try {
        const res = await axios.get(`/unit-alternatives/${unitAlternativeID}`);
        dispatch({
            type: GET_UNIT_ALTERNATIVE,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getUnitsData = () => async (dispatch) => {
    try {
        const res = await axios.get('/units/');
        dispatch({
            type: GET_UNITS_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getUnitAlternatives = (page = 1, filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `${key}=${filters[key]}`);
        }
        const res = await axios.get(`/unit-alternatives?${queryParams}`);

        dispatch({
            type: GET_UNIT_ALTERNATIVES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addUnitAlternative = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/unit-alternatives/', formData, config);

        dispatch(getUnitAlternatives());

        dispatch({
            type: ADD_UNIT_ALTERNATIVE,
            payload: res.data,
        });

        dispatch(setAlert("O'lchov birligi yaratildi !", 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: UNIT_ALTERNATIVE_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("O'lchov birligi yaratilmadi !", 'danger'));
        }
    }
};

export const deleteUnitAlternative = (deleteUnitAlternativeID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/unit-alternatives/${deleteUnitAlternativeID}`, config);

        dispatch({
            type: REMOVE_DELETE_UNIT_ALTERNATIVE
        });

        dispatch(getUnitAlternatives());

        dispatch(setAlert("O'lchov birligi o'chirildi !", 'success'));

        dispatch(clearErrors());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateUnitAlternative = (updateUnitAlternativeID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/unit-alternatives/${updateUnitAlternativeID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_UNIT_ALTERNATIVE});

        dispatch(getUnitAlternatives());

        dispatch(setAlert("O'lchov birligi tahrirlandi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteUnitAlternativeId = (unitAlternativeID) => async (dispatch) => {

    try {
        if (unitAlternativeID !== null) {
            dispatch({
                type: SET_DELETE_UNIT_ALTERNATIVE_ID,
                payload: {
                    unitAlternativeID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_UNIT_ALTERNATIVE
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateUnitAlternativeId = (unitAlternativeID) => async (dispatch) => {

    try {
        if (unitAlternativeID !== null) {
            dispatch({
                type: SET_UPDATE_UNIT_ALTERNATIVE_ID,
                payload: {
                    unitAlternativeID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_UNIT_ALTERNATIVE});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};








