import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteClientId, setUpdateClientId} from "../../actions/client";
import {Link, useHistory} from 'react-router-dom'
import {Edit, Eye, Trash2} from "react-feather";

const ClientItem = ({
                        auth,
                        setDeleteClientId,
                        setUpdateClientId,
                        client: {
                            id,
                            name,
                            contractor,
                            inn,
                            phone,
                            type,
                            bank_account,
                            date: created_at,
                            supplier
                        },
                        showActions,
                        number
                    }) => {

    const {role_name} = auth?.user || {};

    const setDeleteClient = (id) => {
        setDeleteClientId(id);
    };

    const setUpdateClient = (id) => {
        setUpdateClientId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>
                    <Link to={`suppliers/${supplier?.id}`}>
                        {supplier?.name || ''}
                    </Link>
                </td>
                <td>{contractor}</td>
                <td>{inn}</td>
                <td>{phone}</td>
                <td>{type}</td>
                <td>{bank_account}</td>
                <td>
                    <div className="actions">
                        <Link to={`clients/${id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'director' || role_name == 'superadmin' || role_name == 'accountant' || role_name == 'manager' ? (
                                <Edit onClick={() => setUpdateClient(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'director' || role_name == 'superadmin' ? (
                                <Trash2 onClick={() => setDeleteClient(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ClientItem.defaultProps = {
    showActions: true,
};

ClientItem.propTypes = {
    client: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteClientId, setUpdateClientId})(
    ClientItem
);
