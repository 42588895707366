import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory, Link} from "react-router-dom";

import {setDeleteSaleId, setUpdateSaleId} from "../../actions/sale";
import MoneyFormat from "../layout/MoneyFormat";
import {Edit, Eye, Trash2} from "react-feather";

const SaleItem = ({
                      auth,
                      setDeleteSaleId,
                      setUpdateSaleId,
                      number,
                      sale: {
                          id, client_id, seller_id, product_id, price, quantity, date,
                          sum,
                          client, seller, products,
                      },
                      showActions,
                  }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteSale = (id) => {
        setDeleteSaleId(id);
    };

    const setUpdateSale = (id) => {
        setUpdateSaleId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>
                    {
                        products?.map((item, index, items) => (
                            <Fragment key={index}>
                                {items.length - 1 !== index ?
                                    <Fragment>
                                        <a href={`/products/${item.id}`}
                                           rel="noreferrer">{item.name}</a>,
                                    </Fragment> :
                                    <Fragment>
                                        <a href={`/products/${item.id}`}
                                           rel="noreferrer">{item.name}</a>
                                    </Fragment>
                                }
                            </Fragment>
                        ))
                    }
                    {/*<Link to={{pathname: `/products/${product?.id}`}}></Link>*/}
                </td>
                <td>
                    <Link to={`/users/${seller?.id}`}>{seller?.name}</Link>
                </td>
                <td>
                    <Link to={`/clients/${client?.id}`}>{client?.name}</Link>
                </td>
                <td><MoneyFormat value={sum}/></td>
                <td>{date}</td>
                <td>
                    <div className="actions">

                        <Link to={`sales/${id}`}>
                            <Eye/>
                        </Link>
                        {/*{*/}
                        {/*    role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' || role_name == 'storekeeper' ? (*/}
                        {/*        <Edit onClick={() => setUpdateSale(client?.id)}/>*/}
                        {/*    ) : ''*/}
                        {/*}*/}
                        {
                            role_name == 'superadmin' || role_name == 'director' ? (
                                <Trash2 onClick={() => setDeleteSale(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

SaleItem.defaultProps = {
    showActions: true,
};

SaleItem.propTypes = {
    sale: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteSaleId, setUpdateSaleId})(
    SaleItem
);
