import React, {} from 'react';
import {Route, Switch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import PrivateRoute from '../routing/PrivateRoute';
import Register from '../auth/Register';
import Login from '../auth/Login';
// import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';

import Users from '../users/Users';
// import Employees from '../employees/Employees';
// import Post from '../post/Post';
import NotFound from '../layout/NotFound';
import Navbar from '../layout/Navbar';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Employees from "../employees/Employees";
import Clients from "../clients/Clients";
import Suppliers from "../suppliers/Suppliers";
import Firms from "../firms/Firms";
import Warehouses from "../warehouses/Warehouses";
import Transports from "../transports/Transports";
import Units from "../units/Units";
import UnitAlternatives from "../unitalternatives/UnitAlternatives";
import Products from "../products/Products";
import Materials from "../materials/Materials";
import ProductTemplates from "../product_templates/ProductTemplates";
import Salaries from "../salaries/Salaries";
import Expenses from "../expenses/Expenses";
import ExpenseTemplates from "../expense_templates/ExpenseTemplates";
import Profile from "../dashboard/Profile";
import UserPage from "../users/UserPage";
import EmployeePage from "../employees/EmployeePage";
import SpareParts from "../spare_parts/SpareParts";
import MaterialPage from "../materials/MaterialPage";
import WarehousePage from "../warehouses/WarehousePage";
import UnitPage from "../units/UnitPage";
import UnitAlternativePage from "../unitalternatives/UnitAlternativePage";
import SupplierPage from "../suppliers/SupplierPage";
import ClientPage from "../clients/ClientPage";
import TransportPage from "../transports/TransportPage";
import ExpansePage from "../expenses/ExpensePage";
import ExpenseTemplatePage from "../expense_templates/ExpenseTemplatePage";
import FirmPage from "../firms/FirmPage";
import ProductPage from "../products/ProductPage";
import ProductTemplatePage from "../product_templates/ProductTemplatePage";
import SparePartPage from "../spare_parts/SparePartPage";
import ProductDeliveries from "../product_deliveries/ProductDeliveries";
import ProductDeliveryPage from "../product_deliveries/ProductDeliveryPage";
import Sales from "../sales/Sales";
import SalePage from "../sales/SalePage";
import EmployeeCalculations from "../employees_transactions/EmployeeCalculations";
import SupplierCalculations from "../suppliers_transactions/SupplierCalculations";
import ClientCalculations from "../clients_transactions/ClientCalculations";
import MyWarehouses from "../warehouses/MyWarehouses";
import {clearAlerts} from "../../actions/alert";
import SuppliersMaterialCalculations from "../suppliers_material_transactions/SuppliersMaterialCalculations";
import ProductsMaterialCalculations from "../products_material_transactions/ProductsMaterialCalculations";
import ProductCalculations from "../product_transactions/ProductCalculations";
import MaterialCalculations from "../material_transactions/MaterialCalculations";


const Routes = ({toggleSideBar, clearAlerts, alert, sidebar: {isShown, isExpanded}, auth: {isAuthenticated}}) => {


    let condition = true;

    if (isShown && isExpanded === false) {
        condition = false;
    }

    if (isShown !== true) {
        condition = false;
    }

    if (isMobile) {
        condition = false;
    }

    return (
        <div className="main">
            {/* <Alert /> */}

            <Navbar/>
            <div className={`content ${condition ? 'is-expanded' : ''}`}>
                <Switch>
                    {/*<Route exact path="/register" component={Register}/>*/}
                    <Route exact path="/login" component={Login} toggleSideBar={toggleSideBar}/>
                    {
                        isAuthenticated ? (
                            <>
                                <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                                <PrivateRoute exact path="/users" component={Users}/>
                                <PrivateRoute exact path="/users/:id" component={UserPage}/>
                                <PrivateRoute exact path="/employees" component={Employees}/>
                                <PrivateRoute exact path="/employees/:id" component={EmployeePage}/>
                                <PrivateRoute exact path="/clients" component={Clients}/>
                                <PrivateRoute exact path="/clients/:id" component={ClientPage}/>
                                <PrivateRoute exact path="/suppliers" component={Suppliers}/>
                                <PrivateRoute exact path="/suppliers/:id" component={SupplierPage}/>
                                <PrivateRoute exact path="/firms" component={Firms}/>
                                <PrivateRoute exact path="/firms/:id" component={FirmPage}/>
                                <PrivateRoute exact path="/my-warehouses" component={MyWarehouses}/>
                                <PrivateRoute exact path="/warehouses/:id" component={WarehousePage}/>
                                <PrivateRoute exact path="/warehouses" component={Warehouses}/>
                                <PrivateRoute exact path="/transports" component={Transports}/>
                                <PrivateRoute exact path="/transports/:id" component={TransportPage}/>
                                <PrivateRoute exact path="/units" component={Units}/>
                                <PrivateRoute exact path="/units/:id" component={UnitPage}/>
                                <PrivateRoute exact path="/unitalternatives" component={UnitAlternatives}/>
                                <PrivateRoute exact path="/unitalternatives/:id" component={UnitAlternativePage}/>
                                <PrivateRoute exact path="/products" component={Products}/>
                                <PrivateRoute exact path="/products/:id" component={ProductPage}/>
                                <PrivateRoute exact path="/materials" component={Materials}/>
                                <PrivateRoute exact path="/materials/:id" component={MaterialPage}/>
                                <PrivateRoute exact path="/product-templates" component={ProductTemplates}/>
                                <PrivateRoute exact path="/product-templates/:id" component={ProductTemplatePage}/>
                                <PrivateRoute exact path="/employee-calculations" component={EmployeeCalculations}/>
                                <PrivateRoute exact path="/supplier-calculations" component={SupplierCalculations}/>
                                <PrivateRoute exact path="/client-calculations" component={ClientCalculations}/>
                                <PrivateRoute exact path="/supplier-material-calculations"
                                              component={SuppliersMaterialCalculations}/>
                                <PrivateRoute exact path="/product-material-calculations"
                                              component={ProductsMaterialCalculations}/>
                                <PrivateRoute exact path="/product-calculations" component={ProductCalculations}/>
                                <PrivateRoute exact path="/material-calculations" component={MaterialCalculations}/>

                                <PrivateRoute exact path="/salaries" component={Salaries}/>
                                <PrivateRoute exact path="/expenses" component={Expenses}/>
                                <PrivateRoute exact path="/expenses/:id" component={ExpansePage}/>
                                <PrivateRoute exact path="/expense-templates" component={ExpenseTemplates}/>
                                <PrivateRoute exact path="/expense-templates/:id" component={ExpenseTemplatePage}/>
                                <PrivateRoute exact path="/profile" component={Profile}/>
                                <PrivateRoute exact path="/spare-parts" component={SpareParts}/>
                                <PrivateRoute exact path="/spare-parts/:id" component={SparePartPage}/>
                                <PrivateRoute exact path="/spare-parts/:id" component={SparePartPage}/>
                                <PrivateRoute exact path="/product-deliveries" component={ProductDeliveries}/>
                                <PrivateRoute exact path="/product-deliveries/:id" component={ProductDeliveryPage}/>
                                <PrivateRoute exact path="/sales" component={Sales}/>
                                <PrivateRoute exact path="/sales/:id" component={SalePage}/>
                            </>
                        ) : 'no page found'
                    }

                    {/*<PrivateRoute exact path="/suppliers-transactions" component={SupplierTransactions}/>*/}

                    {/* <PrivateRoute exact path="/users/:id" component={User} /> */}
                    <Route component={NotFound}/>
                </Switch>

            </div>
        </div>
    );
};
Routes.propTypes = {
    auth: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    alert: state.alert,
    sidebar: state.sidebar,
});


export default connect(mapStateToProps, {clearAlerts})(
    Routes
);
