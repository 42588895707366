import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteMaterial, setDeleteMaterialId} from "../../actions/material";

const MaterialDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_material, currentPage, numberOfPages, materials} = useSelector(state => state.material);

    const closeModal = () =>{
        // removing materialt from redux state by sending null setDeleteMaterialId
        dispatch(setDeleteMaterialId(null))
    };

    let getPage = null;

    const onConfirm = () =>{

        if ((currentPage == numberOfPages) && (materials.length == 1) ){
            getPage = (currentPage - 1);
        }
        else{
            getPage = currentPage;
        }
        dispatch(deleteMaterial(delete_material.id, getPage))
    };

    let condition = false;
    if(delete_material !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white" >Materialni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu materialni o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MaterialDeleteForm;