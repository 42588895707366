import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {loadUser, updateProfile} from '../../actions/auth';
import {showSidebar} from '../../actions/sidebar';
import {showNavbar} from '../../actions/navbar'
import {AvForm, AvField} from "availity-reactstrap-validation";

import avatar from '../../img/avatar.jpg'
import Button from "react-bootstrap/Button";

const Profile = ({
                     getCurrentUser,
                     loadUser,
                     updateProfile,
                     auth: {user, loading, isAuthenticated},
                     /*user: {users, loading},*/
                     showSidebar
                 }) => {
    useEffect(() => {
        loadUser();
        showSidebar();
        showNavbar();

    }, [getCurrentUser]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isAuthenticated !== true) {
        return <Redirect to="/login"/>;
    }

    const onSubmit = (event, value) => {
        updateProfile(value)
    };


    return loading && user === null ? (
        <Spinner/>
    ) : (
        <div className="container-fluid p-0">

            <div className="row">
                <div className="col-md-2 col-xl-3">
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5 className="card-title text-center mb-0">Akkaunt ma'umotlari</h5>
                        </div>
                        <div className="card-body text-center">
                            <img src={avatar} alt="Christina Mason" className="img-fluid rounded-circle mb-2"
                                 width="128" height="128"/>
                            <h5 className="card-title mb-0">{user?.name}</h5>
                            <div className="text-muted mb-2">{user?.email}</div>

                        </div>

                        <hr className="my-0"/>
                        {/*<div className="card-body">*/}
                        {/*    <h5 className="h6 card-title">Elsewhere</h5>*/}
                        {/*    <ul className="list-unstyled mb-0">*/}
                        {/*        <li className="mb-1"><a href="#">staciehall.co</a></li>*/}
                        {/*        <li className="mb-1"><a href="#">Twitter</a></li>*/}
                        {/*        <li className="mb-1"><a href="#">Facebook</a></li>*/}
                        {/*        <li className="mb-1"><a href="#">Instagram</a></li>*/}
                        {/*        <li className="mb-1"><a href="#">LinkedIn</a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="col-md-10 col-xl-9">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Akkountni tahrirlash</h5>
                        </div>
                        <div className="card-body h-100">

                            <AvForm onValidSubmit={onSubmit}>

                                <AvField
                                    label="Name"
                                    type="text"
                                    name="name"
                                    required
                                    value={user?.name}
                                />

                                <AvField
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={user?.email}
                                    required
                                />

                                <AvField
                                    label="Password"
                                    type="password"
                                    name="password"
                                    required
                                />

                                <Button className="w-100" variant="primary" type="submit">Saqlash</Button>

                            </AvForm>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

Profile.propTypes = {
    showSidebar: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    // user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    // user: state.user,
    navbar: state.navbar,
});

export default connect(mapStateToProps, {updateProfile, loadUser, showSidebar, showNavbar})(
    Profile
);
