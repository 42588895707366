import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import Select from 'react-select';
import {FormFeedback, FormGroup, Input, InputGroup, Label} from "reactstrap";
import {Form} from "react-bootstrap";
import {
    addSparepartImport,
    getSparepartOptions,
    clearErrors
} from "../../actions/spare_part";
import {getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";

const SparepartImportForm = ({
                                 loading,
                                 materials,
                                 error,
                                 spare_part_options,
                                 firm_options,
                                 clearErrors,
                                 getFirmOptions,
                                 addSparepartImport,
                                 getSparepartOptions,
                                 getWarehouseOptions
                             }) => {

        const [sparepartOptions, setSparepartOptions] = useState([]);
        const [firmOptions, setFirmOptions] = useState([]);

        useEffect(() => {
            let items = [];
            firm_options.forEach((item) => items.push({
                label: `${item.name}`,
                value: item.id
            }));
            setFirmOptions(items);

        }, [firm_options]);


        const formRef = useRef();
        const [inputs, setInputs] = useState({});
        const [errors, setErrors] = useState({});
        const handleChange = e => {
            setErrors({});
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
        }
        const handleSelect = (data, type) => {
            if (type.name == 'firm_id') {
                getSparepartOptions({firm_id: data.value});
            }
            setErrors({});
            setInputs(prevState => ({...prevState, [type.name]: data.value}))
        };

        // useEffect(() => {
        //     getSparepartOptions();
        // }, [getSparepartOptions, getWarehouseOptions]);

        useEffect(() => {
            let options = [];

            spare_part_options?.forEach((item) => options.push({
                label: item.name,
                value: item.id
            }));
            setSparepartOptions(options);
        }, [spare_part_options]);


        const handleSubmit = async (e) => {
            e.preventDefault();
            if (await addSparepartImport(inputs)) {
                setInputs({});
            } else {
                if (error) {
                    const errorsItems = error?.errors;
                    let errorObject = {};
                    if (error.hasOwnProperty('errors')) {
                        Object.keys(error.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                    }
                    setErrors(errorObject);
                }
            }
        };

        useEffect(() => {
            setInputs({});
            clearErrors();
            setErrors({});
        }, [materials])

        return (
            <Fragment>

                <div className="col-md-12 col-lg-8">
                    <h4>Skladga zapchast<strong> qabul qilish</strong></h4>
                </div>
                <div className="col-12">

                    <Form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={getSelectedOption(inputs?.firm_id, firmOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-2">
                            <label htmlFor="sparepart_id">Zapchast</label>
                            <Select
                                name={'sparepart_id'}
                                options={sparepartOptions}
                                value={getSelectedOption(inputs.sparepart_id, sparepartOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.sparepart_id}
                            </div>
                        </div>

                        <div className="mb-2">
                            <label htmlFor="quantity">Miqdori</label>
                            <Input
                                name="quantity"
                                value={inputs.quantity || ''}
                                type="number"
                                placeholder={10}
                                onChange={handleChange}
                                required
                            />
                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="price">Narxi</label>
                            <Input
                                name="price"
                                value={inputs.price || ''}
                                type="number"
                                placeholder={1000}
                                onChange={handleChange}
                                required
                            />
                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="mb-2">
                            <Button className="" variant="primary" type="submit">Skladga qo'shish</Button>
                        </div>
                    </Form>

                </div>
            </Fragment>
        );
    }
;

SparepartImportForm.propTypes = {
    addSparepartImport: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getSparepartOptions: PropTypes.func.isRequired,
    getFirmOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    spare_part: state.spare_part,
    spare_parts: state.spare_part.spare_parts,
    error: state.spare_part.error,
    loading: state.spare_part.loading,
    firm_options: state.user.firm_options,
    material_units: state.spare_part.material_units,
    spare_part_options: state.spare_part.spare_part_options,
    warehouse_options: state.spare_part.warehouse_options,
});


export default connect(mapStateToProps, {
    addSparepartImport,
    clearErrors,
    getSparepartOptions,
    getFirmOptions
})(SparepartImportForm);
