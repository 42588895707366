import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import SaleDeleteForm from "./SaleDeleteForm";
import SaleUpdateForm from "./SaleUpdateForm";
import SaleItem from "./SaleItem";
import SaleCreateForm from "./SaleCreateForm";
import {getSales} from "../../actions/sale";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import FilterByFirm from "../products_material_transactions/FilterByFirm";
import FilterByClient from "./FilterByClient";
import FilterByProduct from "./FilterByProduct";

const Sales = (props) => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {sales, loading, numberOfPages, currentPage, total_sales} = useSelector(state => state.sale);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-9 ">
                        <h1 className="h3 mb-3"><strong></strong> sotuv bo'limi ({total_sales}) </h1>
                    </div>

                    <div className="col-md-3 justify-content-end">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Sotuv <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <FilterByClient getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <FilterByFirm getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <FilterByProduct getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <SearchForm getPageItems={getSales}/>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Mahsulotlar</th>
                                    <th scope="col">Sotuvchi</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Summa</th>
                                    <th scope="col">Sana</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sales.map((item, index) => (
                                    <SaleItem key={`sale${item.id}`} sale={item} number={index + 1}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getSales}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <SaleCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <SaleDeleteForm/>

            <SaleUpdateForm/>


        </Fragment>
    );
};


Sales.propTypes = {
    getSales: PropTypes.func.isRequired,
    sale: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    sale: state.sale,
});

export default connect(mapStateToProps, {getSales})(Sales);
