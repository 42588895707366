import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_USER, CLEAR_ERRORS,
    GET_FILTERED_USERS, GET_STATISTICS,
    GET_USER, GET_USER_ACTIVITIES, GET_USER_ROL_ID_DATA,
    GET_USERS,
    REMOVE_DELETE_USER,
    REMOVE_UPDATE_USER,
    SET_DELETE_USER_ID, SET_SEARCH_QUERY,
    SET_UPDATE_USER_ID,
    USER_ERROR,
    GET_FIRM_ID_DATA, EMPLOYEE_ERROR, GET_INCOME_AND_EXPENSES
} from './types';
import setAuthToken from "../utils/setAuthToken";

export const getFirmOptions = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/firms?is_all=true`);
        dispatch({
            type: GET_FIRM_ID_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const getStatisticsData = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/statistics/numbers`);
        dispatch({
            type: GET_STATISTICS,
            payload: res.data,
        });

    } catch (err) {

        // dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};
export const getIncomeAndExpenses = (params = {}) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    let queryParams = '?';
    console.log(params);
    if (Object.keys(params).length > 0) {
        Object.keys(params).map(item => queryParams += `${item}=${params[item]}&`);
    }
    try {
        const res = await axios.get(`/statistics/incomes-and-expenses${queryParams}`);
        dispatch({
            type: GET_INCOME_AND_EXPENSES,
            payload: res.data,
        });

    } catch (err) {
        // dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getFilteredUsers = (page = 1, filters = []) => async (dispatch) => {

    try {
        let queryParams = '';
        if (filters.length > 0) {
            filters.map(item => queryParams += `&${item.name}=${item.value}`);

        }
        const res = await axios.get(`/users?page=${page}${queryParams}`);
        dispatch({
            type: GET_FILTERED_USERS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getUserRolIdData = () => async (dispatch) => {
    try {
        const res = await axios.get(`/auth/user/roles`);
        dispatch({
            type: GET_USER_ROL_ID_DATA,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getUser = (userID) => async (dispatch) => {
    try {
        const res = await axios.get(`/users/${userID}`);
        dispatch({
            type: GET_USER,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};
export const getUserActivities = (page = 1, filters = {}) => async (dispatch) => {

    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }


        const res = await axios.get(`/user-activities/?page=${page}${queryParams}`);
        dispatch({
            type: GET_USER_ACTIVITIES,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addUser = (formData, filters = {}) => async (dispatch) => {

    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/users/', formData, config);
        dispatch({
            type: ADD_USER,
            payload: res.data,
        });
        dispatch(getUsers(filters.page, filters));
        dispatch(setAlert('Foydalanuvchi yaratildi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data) {
            dispatch({
                type: USER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch({
                type: USER_ERROR,
                payload: {msg: err},
            });
        }
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};


export const deleteUser = (deleteUserID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/users/${deleteUserID}`, config);

        dispatch({
            type: REMOVE_DELETE_USER
        });

        dispatch(getUsers(getPage));

        dispatch(setAlert("Foydalanuvchi o'chirildi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateUser = (updateUserID, updateformData, filters) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/users/${updateUserID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_USER});
        dispatch(getUsers(filters.page, filters));
        dispatch(setAlert("Foydalanuvchi tahrirlandi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err.response?.data?.hasOwnProperty('errors')) {
            dispatch(setAlert(err.response?.data?.message, 'warning'));
            dispatch({
                type: USER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert(err.response.statusText, 'danger'));
        }
    }
};

export const getUsers = (page = 1, filters = {}) => async (dispatch) => {

    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);

        }
        const res = await axios.get(`/users?page=${page}${queryParams}`);


        dispatch({
            type: GET_USERS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setSearchText = (searchQuery = '') => async (dispatch) => {

    try {
        dispatch({
            type: SET_SEARCH_QUERY,
            payload: searchQuery
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteUserId = (userID) => async (dispatch) => {


    try {
        if (userID !== null) {
            dispatch({
                type: SET_DELETE_USER_ID,
                payload: {
                    userID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_USER
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateUserId = (userID) => async (dispatch) => {

    try {
        if (userID !== null) {
            dispatch({
                type: SET_UPDATE_USER_ID,
                payload: {
                    userID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_USER});


    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

