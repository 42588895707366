import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {getSale} from "../../actions/sale";
import MoneyFormat from "../layout/MoneyFormat";
import Moment from "react-moment";

const SalePage = () => {

    useEffect(() => {
        dispatch(getSale(id));
    }, [getSale]);

    const dispatch = useDispatch();

    const {id} = useParams();

    const {sale} = useSelector(state => state.sale);

    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Sotuv</strong> ma'lumotlari </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card ">
                                <div className="card border-0 mb-3">
                                    <div className="card-header">
                                        <h5 className="card-title text-dark text-decoration-underline mb-0">Klient
                                            ma'lumotlari</h5>
                                    </div>
                                    <div className="card-body text-center">
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Nomi:</div>
                                            <div className="text-muted  m-0">
                                                <Link to={`/clients/${sale?.client?.id}`}>{sale?.client?.name}</Link>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Javobgar shaxs:</div>
                                            <div className="text-muted  m-0">
                                                {sale?.client?.contractor}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Telefon:</div>
                                            <div className="text-muted  m-0">
                                                {sale?.client?.phone}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Turi:</div>
                                            <div className="text-muted  m-0">
                                                {sale?.client?.type}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Bazaga qo'shilgan:</div>
                                            <div className="text-muted  m-0">
                                                <Moment date={sale?.client?.created_at} format={'YYYY-MM-DD'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-0 mb-3">
                                    <div className="card-header">
                                        <h5 className="card-title text-dark text-decoration-underline mb-0">Sotuvchi</h5>
                                    </div>
                                    <div className="card-body text-center">
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Ismi:</div>
                                            <div className="text-muted  m-0">
                                                <Link to={`/users/${sale?.seller?.id}`}>{sale?.seller?.name}</Link>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Email:</div>
                                            <div className="text-muted  m-0">
                                                {sale?.seller?.email}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Tashkilot manzili:</div>
                                            <div className="text-muted  m-0">
                                                {sale?.client?.address}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card border-0 mb-3">
                                    <div className="card-header">
                                        <h5 className="card-title text-dark text-decoration-underline mb-0">Mahsulotlar</h5>
                                    </div>
                                    <div className="card-body text-center">
                                        {
                                            sale?.products?.map((item, index, items) => (
                                                <div className="d-flex align-items-center">
                                                    <div className="card-title m-2">
                                                        <Link
                                                            to={`/products/${item.product_id}`}
                                                            rel="noreferrer">{item.name}</Link>:
                                                    </div>
                                                    <div className="text-muted  m-0">
                                                        {item?.quantity} {item?.unit?.symbol} x <MoneyFormat
                                                        value={item.price}/> =
                                                        <MoneyFormat value={item?.price * item?.quantity}/> so'm.
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="card border-0 mb-3">
                                    <div className="card-header">
                                        <h4 className="card-title text-dark text-decoration-underline mb-0">Umumiy
                                            ma'lumotlar</h4>
                                    </div>
                                    <div className="card-body text-center">
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Sotuv sanasi:</div>
                                            <div className="text-muted  m-0">
                                                <Moment date={sale?.date} format={'YYYY-MM-DD'}/>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-title m-2">Umumiy summa:</div>
                                            <div className="text-muted  m-0">
                                                <MoneyFormat value={sale?.sum}/> so'm.
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </div>
    )
        ;
};

export default SalePage;