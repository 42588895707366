import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';


import {
    getExpenseTemplate,
    setDeleteExpenseTemplateId,
    setUpdateExpenseTemplateId
} from "../../actions/expense_template";
import {useHistory} from 'react-router-dom';

import {getExpenseTemplateType} from "../../utils/hooks/getParams";
import {Edit, Trash2} from "react-feather";

const ExpenseTemplateItem = ({
                                 auth,
                                 setDeleteExpenseTemplateId,
                                 setUpdateExpenseTemplateId,
                                 index,
                                 expense_template: {
                                     id, name, category, type,
                                     date: created_at
                                 },
                                 showActions,
                             }) => {

    const history = useHistory();

    const {role_name} = auth?.user || {};

    const setDeleteExpenseTemplate = (id) => {
        setDeleteExpenseTemplateId(id);
    };

    const setUpdateExpenseTemplate = (id) => {
        setUpdateExpenseTemplateId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{index}</th>
                <td>{name}</td>
                <td>{category}</td>
                <td>{getExpenseTemplateType(type)}</td>
                <td>
                    <div className="actions">
                        {/*<svg onClick={()=>history.push(`expense-templates/${id}`)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                        {/*     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"*/}
                        {/*     className="feather feather-eye align-middle mr-3">*/}
                        {/*    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>*/}
                        {/*    <circle cx="12" cy="12" r="3"></circle>*/}
                        {/*</svg>*/}
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'accountant' || role_name == 'manager' ? (
                                <Edit onClick={() => setUpdateExpenseTemplate(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'accountant' ? (
                                <Trash2 onClick={() => setDeleteExpenseTemplate(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ExpenseTemplateItem.defaultProps = {
    showActions: true,
};

ExpenseTemplateItem.propTypes = {
    expense_template: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteExpenseTemplateId, setUpdateExpenseTemplateId})(
    ExpenseTemplateItem
);
