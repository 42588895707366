import {
    ADD_SUPPLIER_TRANSACTION,
    CLEAR_ERRORS, GET_CLIENT_TRANSACTIONS, GET_CLIENTS_TRANSACTIONS,
    GET_FILTERED_SUPPLIER_TRANSACTIONS,
    GET_SUPPLIER_TRANSACTION,
    GET_SUPPLIER_TRANSACTION_SUPPLIERS_DATA,
    GET_SUPPLIER_TRANSACTIONS, GET_SUPPLIERS_TRANSACTIONS,
    REMOVE_DELETE_SUPPLIER_TRANSACTION,
    REMOVE_UPDATE_SUPPLIER_TRANSACTION, REMOVE_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER,
    SET_DELETE_SUPPLIER_TRANSACTION_ID, SET_LOADING, SET_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER_ID,
    SET_UPDATE_SUPPLIER_TRANSACTION_ID,
    SUPPLIER_TRANSACTION_ERROR, UNSET_LOADING, UPDATE_TRANSACTION_SUCCESS
} from '../actions/types';

const initialState = {
    dates: [],
    clients_transactions: [],
    clients_transaction: {},
    loading: false,
    numberOfPages: 1,
    total_clients: null,
    error: null,
    clients: [],
    client: {},
    client_transactions: [],
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }


        case UPDATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                update_transaction_id: true,
                error: null,
                loading: false,
            };

        case GET_CLIENTS_TRANSACTIONS:
            const {dates, clients} = payload;
            return {
                ...state,
                dates: dates,
                client_transactions: clients.data,
                numberOfPages: clients.last_page,
                current_page: clients.current_page,
                suppliers_data: payload,
                error: null,
                loading: false,
            };

        case GET_CLIENT_TRANSACTIONS:
            const {id} = payload;
            return {
                ...state,
                client_transactions: state.client_transactions.map(item => item.id == id ? payload : item),

                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
