import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteProductId, setUpdateProductId} from "../../actions/product";
import {Link, useHistory} from 'react-router-dom'
import MoneyFormat from "../layout/MoneyFormat";
import {Edit, Edit2, Eye, Trash2} from "react-feather";

const ProductItem = ({
                         auth,
                         setDeleteProductId,
                         setUpdateProductId,
                         product: item,
                         showActions,
                         index
                     }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteProduct = (id) => {
        setDeleteProductId(id);
    };

    const setUpdateProduct = (id) => {
        setUpdateProductId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{index + 1}</th>
                <td>{item?.name}</td>
                <td>
                    {item?.description}
                </td>
                <td>
                    {item?.warehouse?.name}
                </td>
                <td>{item?.quantity}</td>
                <td>{item?.unit?.name}</td>
                <td><MoneyFormat value={item?.price}/></td>
                <td>
                    <div className="actions">
                        <Link to={`products/${item?.id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' ? (
                                <Edit onClick={() => setUpdateProduct(item?.id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'superadmin' || role_name == 'director' ? (
                                <Trash2 onClick={() => setDeleteProduct(item?.id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ProductItem.defaultProps = {
    showActions: true,
};

ProductItem.propTypes = {
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteProductId, setUpdateProductId})(
    ProductItem
);
