import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateWarehouseId, updateWarehouse} from "../../actions/warehouse";
import Select from "react-select";

const WarehouseUpdateForm = () => {

    const dispatch = useDispatch();

    const {
        update_warehouse,
        currentPage,
        available_warehouse_users,
        available_warehouse_firms
    } = useSelector(state => state.warehouse);


    const [storekeeperOptions, setStorekeeperOptions] = useState([]);
    const [storekeeper_id, setStorekeeperId] = useState(null);
    const [defaultStorekeeper, setDefaultStorekeeper] = useState({value: ''});


    useEffect(() => {
        let items = [];
        available_warehouse_users.forEach((item) => items.push({
                label: `${item.name}`,
                value: item.id
            })
        );
        setStorekeeperOptions(items);
    }, [available_warehouse_users]);


    useEffect(() => {
        if (update_warehouse) {
            if (update_warehouse.storekeeper_id) {
                setStorekeeperId(update_warehouse.storekeeper_id);
                setDefaultStorekeeper({
                    label: `${update_warehouse.storekeeper.name}`,
                    value: update_warehouse.storekeeper_id
                })
            } else {
                setStorekeeperId('');
            }
        }
    }, [update_warehouse]);

    const closeModal = () => {
        dispatch(setUpdateWarehouseId(null));
    };

    const onSelect = (data) => {
        setDefaultStorekeeper(data);
        setStorekeeperId(data.value);
    };
    const onSubmit = (event, value) => {

        value = {
            ...value,
            storekeeper_id: storekeeper_id
        };

        setStorekeeperId(null);
        dispatch(updateWarehouse(update_warehouse.id, value, currentPage));
    };


    let condition = false;
    if (update_warehouse !== null)
        condition = true;


    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Skladni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_warehouse} onValidSubmit={onSubmit}>

                        <AvField
                            name="name"
                            label="Nomi"
                            type="text"
                            required
                        />

                        <AvField
                            type="select"
                            name="firm_id"
                            label="Firma"
                            required
                        >
                            <option value={''}>Tanlang</option>
                            {
                                available_warehouse_firms.map((item, index) => (
                                    <option key={`firm${index}`} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>


                        <div className="mb-2">
                            <label htmlFor="storekeeper_id">Skladchi</label>
                            <Select
                                name={'storekeeper_id'}
                                options={storekeeperOptions}
                                onChange={value => onSelect(value)}
                                value={defaultStorekeeper}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                        </div>


                        <AvField
                            type="select"
                            name="type"
                            label="Sklad turi"
                            required
                        >
                            <option value="product">Mahsulot</option>
                            <option value="part">Zapchast</option>
                            <option value="material">Material</option>

                        </AvField>


                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WarehouseUpdateForm;