import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateTransportId, updateTransport} from "../../actions/transport";

const TransportUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_transport, currentPage, available_drivers} = useSelector(state => state.transport);

    const closeModal = () => {
        // removing update_transport from redux by sending null to setUpdateTransportId
        dispatch(setUpdateTransportId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateTransport(update_transport.id, value, currentPage));
    };

    let condition = false;
    if (update_transport !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Mashinani tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_transport} onValidSubmit={onSubmit}>

                        <AvField
                            name="name"
                            label="Nomi"
                            type="text"
                            required
                        />

                        <AvField
                            name="plate"
                            label="Mashina raqami"
                            type="text"
                            required
                        />

                        <AvField
                            type="select"
                            name="driver_id"
                            label="Haydovchi"
                        >
                            <option key={0} value={''}>Tanlang</option>
                            {
                                available_drivers.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>


                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TransportUpdateForm;