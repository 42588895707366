import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import ExpenseDeleteForm from "./ExpenseDeleteForm";
import ExpenseUpdateForm from "./ExpenseUpdateForm";
import {getExpenses} from "../../actions/expense";
import ExpenseCreateForm from "./ExpenseCreateForm";
import ExpenseItem from "./ExpenseItem";
import SearchForm from "../layout/SearchForm";
import {Edit, Plus} from "react-feather";

const Expenses = (props) => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {expenses, loading, numberOfPages, currentPage, total_expenses} = useSelector(state => state.expense);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> harajatlar ({total_expenses}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getExpenses}/>
                    </div>
                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Harajat
                                <Plus/>
                            </button>
                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Kategoriya</th>
                                    <th scope="col">Miqdori</th>
                                    <th scope="col">Narxi</th>
                                    <th scope="col">Summa</th>
                                    <th scope="col">Tavsifi</th>
                                    <th scope="col">Sana</th>
                                    {
                                        role_name == 'superadmin' || role_name == 'director' || role_name == 'accountant' ? (
                                            <th scope="col">Amallar</th>
                                        ) : ''
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {expenses.map((item, index) => (
                                    <ExpenseItem key={`firm${item.id}`} number={index + 1} expense={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getExpenses}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <ExpenseCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <ExpenseDeleteForm/>

            <ExpenseUpdateForm/>


        </Fragment>
    );
};


Expenses.propTypes = {
    getExpenses: PropTypes.func.isRequired,
    expense: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    expense: state.expense,
});

export default connect(mapStateToProps, {getExpenses})(Expenses);
