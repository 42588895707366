import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";

const FilterByMonth = (props) => {

    const dispatch = useDispatch();

    const {getPageItems} = props;

    const [currentMonth, setCurrentMonth] = useState(getParams().month || moment(new Date()).format('YYYY-MM'));
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        const params = getParams();
        params.month = currentMonth;
        const url = new URL(window.location);
        url.searchParams.set('month', currentMonth);
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
        if (!isFirst) {
            dispatch(getPageItems(1, params));
        } else {
            setIsFirst(false);
        }
    }, [currentMonth])

    return (
        <Fragment>
            <input min="2018-03"
                   value={currentMonth}
                   onChange={e => setCurrentMonth(e.target.value)}
                   max={moment(new Date()).format('YYYY-MM')}
                   className={'form-control'} type="month"
                   id="salary_month" name="salary_month"/>
        </Fragment>
    )
}

export default FilterByMonth