import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {
    addSale,
    clearErrors,
    getClientOptions,
    getProductOptions,
    getSpecialClientMaterialTransactions,
    // getWarehouseOptions
} from "../../actions/sale";
import {Accordion, CloseButton} from "react-bootstrap";
import {ArrowLeft, FilePlus, Plus, X} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";
import {getSelectedOption} from "../../utils/hooks/getParams";
import moment from "moment";
import {Input} from "reactstrap";

const SaleCreateForm = ({
                            client_options,
                            sales,
                            loading,
                            special_client_materials,
                            error,
                            product_options,
                            handleClose,
                            show,
                            getClientOptions,
                            addSale,
                            getProductOptions,
                            getSpecialClientMaterialTransactions,
                        }) => {

    useEffect(() => {
        if (show) {
            getProductOptions({is_for_sale: true});
        }
    }, [show])
    useEffect(() => {
        getProductOptions({is_for_sale: true})
        getClientOptions()
    }, [getProductOptions, getClientOptions]);

    const formRef = useRef();
    const defaultValues = {
        is_special_sale: '',
        client_id: '',
        seller_id: '',
        warehouse_id: '',
        date: '',
        products: []
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };
    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);

    }, [sales]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
        setProductOptions([]);
        setSelectedProductOptions([]);
    };
    useEffect(() => {
        product_options.map(item => {
            item.price = 0;
            item.quantity = 0;
            return item
        });
        setProductOptions(product_options);
    }, [product_options]);

    const [clientOptions, setClientOptions] = useState([]);
    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options])
    const [productOptions, setProductOptions] = useState(product_options);
    const [selectedDynamicProductOptions, setSelectedDynamicProductOptions] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);

    const [isSpecialSale, setIsSpecialSale] = useState(false);


    const onSelectProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };

    useEffect(() => {
        inputs.is_special_sale = Number(isSpecialSale);
        setInputs(inputs);
        if (isSpecialSale) {
            getClientOptions({with_supplier: true})
        } else {
            getClientOptions()
        }
    }, [isSpecialSale])

    const onRemoveProduct = (data) => {
        setSelectedDynamicProductOptions(data)
    };

    const onClickMoveSelectedMaterials = () => {
        // console.log(selectedDynamicProductOptions)
        if (selectedDynamicProductOptions.length > 0) {
            setSelectedProductOptions([...selectedProductOptions, ...selectedDynamicProductOptions]);
            let ids = selectedDynamicProductOptions.map(({id}) => id);
            const filteredMaterials = productOptions.filter((item) => !ids.includes(item.id));
            setProductOptions(filteredMaterials);
            setSelectedDynamicProductOptions([]);
            const inputProducts = [];
            selectedProductOptions.map(item => inputProducts.push({
                id: item.id,
                quantity: item?.quantity || 0,
                net_price: item?.net_price || 0,
            }));
            inputs.products = [...inputs.products, ...inputProducts];

            setInputs(inputs);
        }
    };

    const [selectedSpecialClient, setSelectedSpecialClient] = useState({});

    const [availableMaterials, setAvailableMaterials] = useState([])
    const [selectedProductsMaterialsCalculations, setSelectedProductsMaterialsCalculations] = useState([]);

    const handleSelect = (data, type) => {

        if (isSpecialSale && type.name == 'client_id') {
            const specialClient = client_options?.find(item => item.id === data.value);
            getSpecialClientMaterialTransactions(data.value);
            setSelectedSpecialClient(specialClient);
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const onChangeInput = (e) => {
        let product_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedProductOptions.map((item) => {
            if (item.id === product_id) {
                if (e.target.getAttribute('name') == 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= item?.in_stock && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = e.target.value;
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = e.target.value;
                    }
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }

            }
            // cleanItems.push({...item})
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity,
            });
            return item;
        });

        setSelectedProductOptions(items);

        setErrors({});
        setInputs(prevState => ({...prevState, products: [...cleanItems]}));
    };


    const onClickRemoveFromSelectedMaterials = (id) => {
        const filteredSelectedMaterials = selectedProductOptions.filter(item => item.id !== Number(id));
        setSelectedProductOptions([...filteredSelectedMaterials]);
        let ids = filteredSelectedMaterials.map(({id}) => id);
        const filteredMaterials = product_options.filter((item) => !ids.includes(item.id));
        setProductOptions(filteredMaterials);
        setSelectedDynamicProductOptions([]);
    }


    useEffect(() => {

        if (special_client_materials) {
            const items = [];
            special_client_materials?.forEach((item) => {

                    return items.push({
                        id: item.id,
                        material_id: item.material_id,
                        name: item.material?.name,
                        left_amount: item.total_left_amount * Number(item.material?.multiplier),
                        // amount: Number(item.total_left_amount) * Number(item.material?.multiplier),
                        multiplier: item.material?.multiplier,
                        unit: {
                            symbol: item.material?.unit?.symbol,
                        },
                        unit_alternative: {
                            symbol: item.material?.unit_alternative?.symbol,
                        }
                    })
                }
            );
            setAvailableMaterials(items);
        }
    }, [special_client_materials]);

    useEffect(() => {

        if (isSpecialSale) {

            let items = [];
            selectedProductOptions.map(item => item.materials.map((subitem) => {
                subitem.product_id = item.id;
                items.push(subitem)
            }));
            let materialItems = {};
            items.map((item) => {

                if (materialItems[item.material_id]) {
                    const tempProduct = selectedProductOptions.find(product_item => item.product_id == product_item.id);
                    if (tempProduct) {
                        materialItems[item.material_id].amount += Number(item.unit_value) * tempProduct.quantity;
                    }
                } else {
                    materialItems[item.material_id] = item;
                    materialItems[item.material_id].amount = 0;
                    const tempProduct = selectedProductOptions.find(product_item => item.product_id == product_item.id);
                    if (tempProduct) {
                        materialItems[item.material_id].amount = Number(item.unit_value) * tempProduct.quantity;
                    }
                }
            });

            const recalced = JSON.parse(JSON.stringify(availableMaterials)).map(item => {
                const tempMaterial = materialItems[item.material_id];
                if (tempMaterial) {
                    item.left_amount = item.left_amount - tempMaterial.amount;
                }
                return item
            });
            setSelectedProductsMaterialsCalculations(recalced);
        }
    }, [selectedProductOptions])

    const handleMaterialCheckbox = (e) => {
        console.log(e.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            is_special_sale: inputs?.is_special_sale,
            seller_id: inputs?.supplier_id,
            date: inputs?.date,
            client_id: inputs?.client_id,
            products: inputs.products,
        };
        const response = await addSale(data);
        if (response === true) {
            setInputs(defaultValues);
            setSelectedDynamicProductOptions([]);
            setSelectedProductOptions([]);
            setAvailableMaterials([]);
            setSelectedProductsMaterialsCalculations([]);
            setIsSpecialSale(false);
        } else {
            if (response && response.hasOwnProperty('errors')) {

            }
        }
    };

    useEffect(() => {
        getProductOptions({is_for_sale: true});
        if (error && error.hasOwnProperty('errors')) {
            const errorsItems = error.errors;
            let errorObject = {};
            if (error?.errors) {
                Object.keys(error.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [sales])

    return (
        <Fragment>
            <Modal size="lg" show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi sotuv amalga oshirish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckChecked"
                               onChange={e => setIsSpecialSale(!isSpecialSale)}
                               checked={isSpecialSale}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                            Maxsus sotuv
                        </label>
                    </div>
                    {
                        isSpecialSale ? (
                            <form onSubmit={handleSubmit} ref={formRef}>

                                <div className="mb-3">
                                    <label htmlFor="client_id" className="form-label">
                                        Klient(yetkazuvchiga bog'langan)
                                    </label>
                                    <Select
                                        name={'client_id'}
                                        options={clientOptions}
                                        value={getSelectedOption(inputs?.client_id, clientOptions)}
                                        onChange={handleSelect}
                                        defaultValue={{label: 'Tanlang', value: ''}}
                                    />

                                    <div className="error">
                                        {errors?.client_id}
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="warehouse_id" className="form-label text-black bold">
                                        Balansidagi materiallar
                                    </label>
                                    <div className="material_data_table_wrapper">
                                        {
                                            availableMaterials?.length ? (
                                                <table className={'table table-striped table-responsive'}>
                                                    <tbody>

                                                    <tr>
                                                        {
                                                            availableMaterials.map((item, key) => (
                                                                <td key={key} className={"w-25"}>
                                                                    <Input
                                                                        type="checkbox"
                                                                        className={'checkbox'}
                                                                        data-material_id={item.material_id}
                                                                        onChange={handleMaterialCheckbox}
                                                                    />
                                                                    <label className={'form-check-label'}>{item.name}</label>
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            availableMaterials.map((item, key) => (
                                                                <td key={key}>
                                                                    {item.left_amount.toFixed(3)} {item.unit_alternative?.symbol}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className={'text-muted'}>
                                                    Balansida material mavjud emas
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>


                                <div className="mb-3">
                                    <div className="row materials__mover">
                                        <div className="col-lg-5 col-md-12">
                                            <label className="form-label mb-2">Tarkibi</label>

                                            <div className="card p-2">
                                                <Accordion>
                                                    {
                                                        selectedProductOptions ? selectedProductOptions?.map((item, index) => (
                                                                <Accordion.Item key={index} eventKey={item.id}>
                                                                    <Accordion.Header>{item.name} ({item?.in_stock} {item.unit?.symbol})</Accordion.Header>
                                                                    <Accordion.Body>

                                                                        <div className="form-group ">

                                                                            <div
                                                                                className="input-group mb-1 justify-content-between">
                                                                                <label htmlFor="ID">Tan narxi</label>
                                                                                <X className={'pointered-icon'}
                                                                                   onClick={e => onClickRemoveFromSelectedMaterials(item.id)}
                                                                                   color={'red'}
                                                                                />
                                                                            </div>
                                                                            <input
                                                                                className="form-control my-2 float-right"
                                                                                type="text"
                                                                                name="id"
                                                                                id="ID"
                                                                                value={`${item?.net_price} so'm`}
                                                                                required
                                                                                disabled
                                                                            />
                                                                            <div className="input-group mb-3">
                                                                                <input type="text" className="form-control"
                                                                                       placeholder="Miqdori"
                                                                                       name='quantity'
                                                                                       type={'text'}
                                                                                       min={1}
                                                                                       value={item?.quantity}
                                                                                       data-id={item.id}
                                                                                       id={`quantity${item.id}`}
                                                                                       required
                                                                                       max={item?.in_stock}
                                                                                       onChange={onChangeInput}
                                                                                />
                                                                                <span className="input-group-text"
                                                                                      id="integer">
                                                                            {item.unit?.symbol}
                                                                        </span>
                                                                            </div>
                                                                            <div className="input-group mb-3">
                                                                                <input type="text" className="form-control"
                                                                                       placeholder="Narxi"
                                                                                       name='price'
                                                                                       type={'text'}
                                                                                       min={1}
                                                                                       value={item?.price}
                                                                                       data-id={item.id}
                                                                                       id={`price${item.id}`}
                                                                                       required
                                                                                       onChange={onChangeInput}
                                                                                />
                                                                                <span className="input-group-text"
                                                                                      id="price">
                                                                            dan
                                                                        </span>
                                                                            </div>
                                                                        </div>

                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            ))
                                                            :
                                                            <h6 className="text-secondary">
                                                                O'ng tomondan mahsulotlar tanlab qo'shing!
                                                            </h6>
                                                    }
                                                </Accordion>
                                            </div>


                                        </div>

                                        <div className="col-md-12 justify-content-center col-lg-2 text-center">
                                            <br/>
                                            <br/>
                                            <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                                    className="btn-primary py-2 px-3">
                                                <ArrowLeft/>
                                            </button>
                                        </div>

                                        <div className="col-lg-5 col-md-12">

                                            <div className="mt-2">
                                                <label htmlFor="" className="mb-2">Mahsulotlar</label>
                                                <Multiselect
                                                    className="form-control mt-2"
                                                    options={productOptions}
                                                    displayValue="name"
                                                    onSelect={onSelectProduct}
                                                    onRemove={onRemoveProduct}
                                                    selectedValues={selectedDynamicProductOptions}
                                                    placeholder="Mahsulotlarni tanlang"
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="warehouse_id" className="form-label text-black bold">
                                        sotuvdan keyin balansidagi materiallar
                                    </label>
                                    <div className="material_data_table_wrapper">
                                        {
                                            selectedProductsMaterialsCalculations?.length ? (
                                                <table className={'table table-striped table-responsive'}>
                                                    <tbody>

                                                    <tr>
                                                        {
                                                            selectedProductsMaterialsCalculations.map((item, key) => (
                                                                <td key={key} className={"w-25"}>
                                                                    {item.name}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            selectedProductsMaterialsCalculations.map((item, key) => (
                                                                <td key={key}>
                                                                    {item.left_amount.toFixed(3)} {item.unit_alternative?.symbol}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className={'text-muted'}>
                                                    Balansida material mavjud emas
                                                </div>
                                            )
                                        }

                                    </div>

                                </div>


                                <div className="mb-3">
                                    <label htmlFor="date">Sana</label>
                                    <input
                                        name="date"
                                        className={'form-control'}
                                        type="date"
                                        value={inputs?.date || ''}
                                        placeholder={'kiriting'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                        max={moment().format('YYYY-MM-DD')}
                                    />

                                    <div className="error">
                                        {errors?.date}
                                    </div>
                                </div>

                                <div className="d-flex gap-2">
                                    <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                        qilish</Button>
                                    <Button className="w-50" variant="primary" type="submit">
                                        Qo'shish <Plus/>
                                    </Button>
                                </div>

                            </form>
                        ) : (
                            <form onSubmit={handleSubmit} ref={formRef}>

                                <div className="mb-3">
                                    <label htmlFor="client_id" className="form-label">
                                        Klient
                                    </label>
                                    <Select
                                        name={'client_id'}
                                        options={clientOptions}
                                        value={getSelectedOption(inputs?.client_id, clientOptions)}
                                        onChange={handleSelect}
                                        defaultValue={{label: 'Tanlang', value: ''}}
                                    />

                                    <div className="error">
                                        {errors?.client_id}
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="row materials__mover">
                                        <div className="col-lg-5 col-md-12">
                                            <label className="form-label mb-2">Tarkibi</label>

                                            <div className="card p-2">
                                                <Accordion>
                                                    {
                                                        selectedProductOptions ? selectedProductOptions.map((item, index) => (
                                                                <Accordion.Item key={index} eventKey={item.id}>
                                                                    <Accordion.Header>{item.name} ({item?.in_stock} {item?.unit?.symbol})</Accordion.Header>
                                                                    <Accordion.Body>

                                                                        <div className="form-group ">

                                                                            <div
                                                                                className="input-group mb-1 justify-content-between">
                                                                                <label htmlFor="ID">Tan narxi</label>
                                                                                <X className={'pointered-icon'}
                                                                                   onClick={e => onClickRemoveFromSelectedMaterials(item.id)}
                                                                                   color={'red'}
                                                                                />
                                                                            </div>
                                                                            <input
                                                                                className="form-control my-2 float-right"
                                                                                type="text"
                                                                                name="id"
                                                                                id="ID"
                                                                                value={`${item?.net_price} so'm`}
                                                                                required
                                                                                disabled
                                                                            />
                                                                            <div className="input-group mb-3">

                                                                                <input type="text" className="form-control"
                                                                                       placeholder="Miqdori"
                                                                                       name='quantity'
                                                                                       type={'text'}
                                                                                       min={1}
                                                                                       value={item?.quantity}
                                                                                       data-id={item.id}
                                                                                       id={`quantity${item.id}`}
                                                                                       required
                                                                                       onChange={onChangeInput}
                                                                                />
                                                                                <span className="input-group-text"
                                                                                      id="integer">
                                                                                    {item.unit?.symbol}
                                                                                </span>
                                                                            </div>
                                                                            <div className="input-group mb-3">
                                                                                <input type="text" className="form-control"
                                                                                       placeholder="Narxi"
                                                                                       name='price'
                                                                                       type={'text'}
                                                                                       min={1}
                                                                                       value={item?.price}
                                                                                       data-id={item.id}
                                                                                       id={`price${item.id}`}
                                                                                       required
                                                                                       onChange={onChangeInput}
                                                                                />
                                                                                <span className="input-group-text"
                                                                                      id="price">
                                                                            dan
                                                                        </span>
                                                                            </div>
                                                                        </div>

                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            ))
                                                            :
                                                            <h6 className="text-secondary">
                                                                O'ng tomondan mahsulotlar tanlab qo'shing!
                                                            </h6>
                                                    }
                                                </Accordion>
                                            </div>

                                            {
                                                Object.keys(errors).forEach((error, index, items) => console.log(items[error]))
                                            }


                                        </div>

                                        <div className="col-md-12 justify-content-center col-lg-2 text-center">
                                            <br/>
                                            <br/>
                                            <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                                    className="btn-primary py-2 px-3">
                                                <ArrowLeft/>
                                            </button>
                                        </div>

                                        <div className="col-lg-5 col-md-12">

                                            <div className="mt-2">
                                                <label htmlFor="" className="mb-2">Mahsulotlar</label>
                                                <Multiselect
                                                    className="form-control mt-2"
                                                    options={productOptions}
                                                    displayValue="name"
                                                    onSelect={onSelectProduct}
                                                    onRemove={onRemoveProduct}
                                                    selectedValues={selectedDynamicProductOptions}
                                                    placeholder="Mahsulotlarni tanlang"
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                O'ng tomondan mahsulotlar tanlab qo'shing!
                                <div className="mb-3">
                                    <label htmlFor="date">Sana</label>
                                    <input
                                        name="date"
                                        className={'form-control'}
                                        type="date"
                                        value={inputs?.date || ''}
                                        placeholder={'kiriting'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                        max={moment().format('YYYY-MM-DD')}
                                    />

                                    <div className="error">
                                        {errors?.date}
                                    </div>
                                </div>


                                <div className="d-flex gap-2">
                                    <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                        qilish</Button>
                                    <Button className="w-50" variant="primary" type="submit">
                                        Sotish <Plus/>
                                    </Button>
                                </div>

                            </form>
                        )
                    }


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

SaleCreateForm.propTypes = {
    addSale: PropTypes.func.isRequired,
    getClientOptions: PropTypes.func.isRequired,
    getProductOptions: PropTypes.func.isRequired,
    // getWarehouseOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sale: state.sale,
    sales: state.sale.sales,
    seller_options: state.sale.seller_options,
    special_client_materials: state.sale.special_client_materials,
    client_options: state.sale.client_options,
    warehouse_options: state.sale.warehouse_options,
    product_options: state.sale.product_options,
    products: state.sale.products,
    loading: state.sale.loading,
    error: state.sale.error,
});


export default connect(mapStateToProps, {
    addSale,
    clearErrors,
    getClientOptions,
    getSpecialClientMaterialTransactions,
    getProductOptions,
    // getWarehouseOptions
})(SaleCreateForm);
