import {
    ADD_SUPPLIER_TRANSACTION,
    CLEAR_ERRORS,
    GET_FILTERED_SUPPLIER_TRANSACTIONS,
    GET_SUPPLIER_TRANSACTION,
    GET_SUPPLIER_TRANSACTION_SUPPLIERS_DATA,
    GET_SUPPLIER_TRANSACTIONS, GET_SUPPLIERS_TRANSACTIONS,
    REMOVE_DELETE_SUPPLIER_TRANSACTION,
    REMOVE_UPDATE_SUPPLIER_TRANSACTION, REMOVE_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER,
    SET_DELETE_SUPPLIER_TRANSACTION_ID, SET_LOADING, SET_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER_ID,
    SET_UPDATE_SUPPLIER_TRANSACTION_ID,
    SUPPLIER_TRANSACTION_ERROR, UNSET_LOADING
} from '../actions/types';

const initialState = {
    supplier_transactions: [],
    dates: [],

    suppliers_transactions: [],
    suppliers_transaction: {},

    suppliers_DATA: [],
    filtered_suppliers_transactions: [],
    update_suppliers_transaction: null,
    update_suppliers_transaction_for_supplier: null,
    delete_suppliers_transaction: null,
    loading: true,
    numberOfPages: 1,
    total_suppliers_transactions: null,
    total_suppliers: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case GET_SUPPLIERS_TRANSACTIONS:

            const {dates, suppliers} = payload;
            return {
                ...state,
                dates: dates,
                supplier_transactions: suppliers.data,
                numberOfPages: suppliers.last_page,
                current_page: suppliers.current_page,
                suppliers_data: payload,
                error: null,
                loading: false,
            };

        case GET_SUPPLIER_TRANSACTIONS:

            return {
                ...state,
                supplier_transactions: state.supplier_transactions.map(item => item.id == payload.id ? payload : item),
                loading: false,
            };


        case GET_SUPPLIER_TRANSACTIONS:
            return {
                ...state,
                suppliers_transactions: payload.suppliers.data,
                numberOfPages: payload.suppliers.last_page,
                currentPage: payload.suppliers.current_page,
                total_products: payload.suppliers.total,
                error: null,
                loading: false,
            };

        case SUPPLIER_TRANSACTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
