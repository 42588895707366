import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_EXPENSE, CLEAR_ERRORS,
    EXPENSE_ERROR,
    EXPENSE_TEMPLATE_ID_ERROR, GET_EXPENSE,
    GET_EXPENSE_TEMPLATE_ID_DATA,
    GET_EXPENSES,
    REMOVE_DELETE_EXPENSE,
    REMOVE_UPDATE_EXPENSE, SALE_ERROR,
    SET_DELETE_EXPENSE_ID,
    SET_UPDATE_EXPENSE_ID
} from './types';

export const getExpenseTemplateIdData = () => async (dispatch) => {
    try {
        const res = await axios.get('/expense-templates/');
        dispatch({
            type: GET_EXPENSE_TEMPLATE_ID_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

    }
};

export const getExpense = (expenseID) => async (dispatch) => {
    try {
        const res = await axios.get(`/expenses/${expenseID}`);
        dispatch({
            type: GET_EXPENSE,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getExpenses = (page = 1, filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/expenses?page=${page}${queryParams}`);

        dispatch({
            type: GET_EXPENSES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addExpense = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/expenses/', formData, config);

        dispatch(getExpenses());

        dispatch({
            type: ADD_EXPENSE,
            payload: res.data,
        });

        dispatch(setAlert('Xarajat yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: EXPENSE_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Xarajat yaratilmadi !", 'danger'));
        }
    }
};

export const deleteExpense = (deleteExpenseID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/expenses/${deleteExpenseID}`, config);

        dispatch({
            type: REMOVE_DELETE_EXPENSE
        });

        dispatch(getExpenses(getPage));

        dispatch(setAlert("Xarajat o'chirildi !", 'success'));

        dispatch(clearErrors());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateExpense = (updateExpenseID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/expenses/${updateExpenseID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_EXPENSE});

        dispatch(getExpenses(getPage));

        dispatch(setAlert('Xarajat tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: EXPENSE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteExpenseId = (expenseID) => async (dispatch) => {

    try {
        if (expenseID !== null) {
            dispatch({
                type: SET_DELETE_EXPENSE_ID,
                payload: {
                    expenseID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_EXPENSE
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateExpenseId = (expenseID) => async (dispatch) => {

    try {
        if (expenseID !== null) {
            dispatch({
                type: SET_UPDATE_EXPENSE_ID,
                payload: {
                    expenseID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_EXPENSE});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};








