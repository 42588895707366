import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import {Col, Nav, Row, Tab} from 'react-bootstrap'
import {
    getSupplier,
} from "../../actions/supplier";
import GoBackButton from "../layout/GoBackButton";
import SupplierAbout from "./tabs/SupplierAbout";
import SupplierMaterialImports from "./tabs/SupplierMaterialImports";
import SupplierTransactions from "./tabs/SupplierTransactions";
import {setDeleteMaterialImport} from "../../actions/material";
import SupplierMaterialDeleteForm from "./SupplierMaterialDeleteForm";

const SupplierPage = () => {

    const {
        supplier,
        supplier_transactions,
        supplier_material_imports,
    } = useSelector(state => state.supplier);

    useEffect(() => {
        dispatch(getSupplier(id));
    }, [getSupplier]);

    const dispatch = useDispatch();

    const {id} = useParams();

    return (
        <div>
            <div className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"> Yetkazuvchi <strong>{supplier?.name}</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="card px-4 py-3">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={3} md={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"
                                                          className="text-center fw-bold">Haqida</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"
                                                          className="text-center fw-bold">Hisob-kitob</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third" className="text-center fw-bold">Yetkazgan
                                                    materiallari</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9} md={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">

                                                <SupplierAbout supplier={supplier}/>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">

                                                <SupplierTransactions supplier={supplier}
                                                                      supplier_transactions={supplier_transactions}/>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="third">

                                                <SupplierMaterialImports supplier={supplier}
                                                                         supplier_material_imports={supplier_material_imports}/>

                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>

                    </div>

                </div>

                {/*<SupplierMaterialCreateForm*/}
                {/*    supplier_id={supplier?.id} show={material_show}*/}
                {/*    handleShowMaterial={handleShowMaterial}*/}
                {/*    handleCloseMaterial={handleCloseMaterial}*/}
                {/*/>*/}

                {/*<SupplierMaterialUpdateForm supplier_id={supplier?.id}/>*/}

                {/*<SupplierMaterialImport supplier_id={supplier?.id}/>*/}

            </div>
        </div>
    );
};

export default SupplierPage;