import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import TransportDeleteForm from "./TransportDeleteForm";
import TransportUpdateForm from "./TransportUpdateForm";
import TransportCreateForm from "./TransportCreateForm";
import TransportItem from "./TransportItem";
import {getTransports} from "../../actions/transport";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";

const Transports = (props) => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {transports, loading, numberOfPages, currentPage, total_transports} = useSelector(state => state.transport);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> mashinalar ({total_transports}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getTransports}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Transport <Plus/>
                            </button>
                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Mashina raqami</th>
                                    <th scope="col">Haydovchi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {transports.map((item, index) => (
                                    <TransportItem number={index + 1} key={`firm${item.id}`} transport={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getTransports}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <TransportCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <TransportDeleteForm/>

            <TransportUpdateForm/>


        </Fragment>
    );
};


Transports.propTypes = {
    getTransports: PropTypes.func.isRequired,
    transport: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    transport: state.transport,
});

export default connect(mapStateToProps, {getTransports})(Transports);
