import React, {Fragment, useEffect, useState} from "react";
import {getParams, useForceUpdate, useOuterClick, formatMoney, unformatMoney} from "../../utils/hooks/getParams";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {
    getSupplierMaterialsTransactions,
    updateTransaction
} from "../../actions/suppliers_materials_transaction";

const SupplierMaterialDataCell = ({index, row_id, rowData, resetRowData, item}) => {
    const dispatch = useDispatch();
    const [itemData, setItemData] = useState({});
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    useEffect(() => item ? setItemData(item) : setItemData({}), [item]);
    // useEffect(() => 

    const editTransaction = async (transaction) => {
        return await dispatch(
            updateTransaction(
                transaction, getParams(true)
            )
        );
    };

    const handleKeyDown = async (e, updatedValue) => {

        if (parseFloat(updatedValue) < 0) {
            e.target.classList.add('invalid_cell_value');
            return false;
        } else {
            e.target.classList.remove('invalid_cell_value');
        }

        if (e.key === 'Enter' || e.key == 'Tab' || e.nativeEvent.key == "NumpadEnter") {
            e.preventDefault();
            if (e.target.getAttribute('contenteditable') === 'plaintext-only') {

                const cell = rowData.find((item) => item.id == e.target.dataset.cell_id);
                // const cell = rowData.find((item) => 

                const edited_cell = {...cell};
                if (edited_cell) {
                    if (e.target.classList.contains('spent_amount')) {
                        edited_cell.spent_amount = unformatMoney(updatedValue);
                    } else {
                        edited_cell.received_amount = unformatMoney(updatedValue);
                    }
                    if (await editTransaction(edited_cell)) {
                        const params = getParams();
                        dispatch(getSupplierMaterialsTransactions(e.target.dataset.row_id, params));
                        e.target.setAttribute('contenteditable', false);
                        e.target.classList.remove('editing_cell');
                        e.target.classList.remove('invalid_cell');
                    } else {
                        e.target.setAttribute('contenteditable', 'plaintext-only');
                        e.target.classList.add('invalid_cell');
                        e.target.classList.remove('editing_cell');
                        setTimeout(function () {
                            e.target.focus();
                        }, 500);
                    }
                } else {

                }
            } else {
                e.target.setAttribute('contenteditable', 'plaintext-only');
                e.target.classList.remove('invalid_cell');
                e.target.classList.add('editing_cell');
            }
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
                e.target.setAttribute('contenteditable', false);
                e.target.classList.remove('editing_cell');
                e.target.classList.remove('invalid_cell');
                const cell = rowData.find((item) => item.id == e.target.dataset.cell_id);
                e.target.textContent = formatMoney(e.target.dataset.old_value)
            }
        }
    }

    const onDoubleClick = async (e, updatedValue) => {

        if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
            const cell = rowData.find((item) => item.id == e.target.dataset.cell_id);
            if (cell) {
                if (e.target.classList.contains('spent_amount')) {
                    cell.spent_amount = unformatMoney(updatedValue);
                } else {
                    cell.received_amount = unformatMoney(updatedValue);
                }
            }

            if (await editTransaction(cell)) {
                const params = getParams();
                dispatch(getSupplierMaterialsTransactions(e.target.dataset.row_id, params));
                e.target.setAttribute('contenteditable', false);
                e.target.classList.remove('editing_cell');
                e.target.classList.remove('invalid_cell');
            } else {
                e.target.setAttribute('contenteditable', 'plaintext-only');
                e.target.classList.add('invalid_cell');
                e.target.classList.remove('editing_cell');
                setTimeout(function () {
                    e.target.focus();
                }, 500);
            }
        } else {
            e.target.setAttribute('contenteditable', 'plaintext-only');
            e.target.classList.remove('invalid_cell');
            e.target.classList.add('editing_cell');
        }
    }


    return (
        <Fragment key={`td${index}`}>
            {
                role_name !== 'manager' && role_name !== 'storekeeper' ? (
                    <td
                        className={'received_amount'}
                        // onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                        // onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                        data-row_id={row_id} data-cell_id={itemData.id}
                        data-old_value={itemData.received_amount}
                    >
                        {formatMoney(itemData.received_amount)}
                    </td>
                ) : (
                    <Fragment>
                        {
                            (
                                moment(moment(itemData.date, 'YYYY-MM-DD')).isSame(moment(), 'day')
                                ||
                                moment(moment(itemData.date, 'YYYY-MM-DD')).isSame(moment().subtract(1, 'day'), 'day')
                            ) ? (
                                <td
                                    className={'cell received_amount'}
                                    // onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                                    // onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                                    data-old_value={itemData.received_amount}
                                    data-row_id={row_id} data-cell_id={itemData.id}
                                >{formatMoney(itemData.received_amount)}</td>
                            ) : (

                                <td
                                    className={'cell received_amount'}
                                    data-row_id={row_id} data-cell_id={itemData.id}
                                >{formatMoney(itemData.received_amount)}</td>

                            )
                        }
                    </Fragment>

                )
            }

            {
                role_name !== 'manager' && role_name !== 'storekeeper' ? (
                    <td onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                        onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                        className={'cell spent_amount'}
                        data-old_value={itemData.spent_amount}
                        data-row_id={row_id} data-cell_id={itemData.id}
                    >{formatMoney(itemData.spent_amount)}</td>
                ) : (
                    <Fragment>
                        {
                            (
                                moment(moment(itemData.date, 'YYYY-MM-DD')).isSame(moment(), 'day')
                                ||
                                moment(moment(itemData.date, 'YYYY-MM-DD')).isSame(moment().subtract(1, 'day'), 'day')
                            ) ? (
                                <td onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                                    onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                                    className={'cell spent_amount'}
                                    data-old_value={itemData.spent_amount}
                                    data-row_id={row_id} data-cell_id={itemData.id}
                                >{formatMoney(itemData.spent_amount)}</td>
                            ) : (

                                <td
                                    className={'cell spent_amount'}
                                    data-row_id={row_id} data-cell_id={itemData.id}
                                >{formatMoney(itemData.spent_amount)}</td>
                            )
                        }
                    </Fragment>
                )
            }
        </Fragment>
    )
}

export default SupplierMaterialDataCell;