import avatar from "../../../img/avatar.jpg";
import React from "react";

const UserAbout = ({user}) => {
    return (

        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title mb-0">Foydalanuvchi ma'umotlari</h5>
            </div>
            <div className="card-body text-center">
                <div className="row">
                    <div className="col-md-3">
                        <img src={avatar} alt="Christina Mason"
                             className="img-fluid rounded-circle m-2 mb-2"
                             width="128" height="128"/>
                        <h5 className="card-title mb-0">{user?.name}</h5>
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex align-items-center">
                            <div className="card-title m-2">Ism familiya:</div>
                            <div className="text-muted m-0">{user?.name}</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="card-title m-2">Email:</div>
                            <div className="text-muted  m-0">{user?.email}</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="card-title m-2">Rol:</div>
                            <div
                                className="text-muted m-0">{user?.role?.title}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default UserAbout;