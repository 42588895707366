import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import FirmDeleteForm from "./FirmDeleteForm";
import FirmUpdateForm from "./FirmUpdateForm";
import FirmCreateForm from "./FirmCreateForm";
import {getFirm, getFirms} from "../../actions/firms";
import FirmCard from "./FirmCard";
// import { useHistory, useLocation } from "react-router-dom";
import CustomPagination from "../layout/CustomPagination";
import {Plus} from "react-feather";

const Firms = (props) => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {firms: firmItems, loading, numberOfPages, currentPage} = useSelector(state => state.firm);

    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // useEffect(() => {
    //     getFirms(page)
    // }, [getFirms, page]);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong>Firmalar</strong>({firmItems.length}) </h1>
                    </div>
                    <div className="col-lg-4">
                        <div className="crud_actions text-end">
                            {
                                role_name == 'superadmin' || role_name == 'director' ? (
                                    <button className="btn btn-primary" onClick={handleShow}>
                                        Firma <Plus/>
                                    </button>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>


                <div className="row mt-3">

                    {firmItems.map((item, index) => (
                        <div key={`firm${item.id}`} className="col-md-4 col-lg-4">
                            <FirmCard firm={item} index={index}/>
                        </div>
                    ))}

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getFirms}
                            current_page={currentPage}
                        />
                    </div>
                </div>


            </div>

            {
                role_name == 'superadmin' || role_name == 'director' ? (
                    <FirmCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>
                ) : ''
            }

            <FirmDeleteForm/>

            <FirmUpdateForm/>


        </Fragment>
    );
};


Firms.propTypes = {
    getFirms: PropTypes.func.isRequired,
    firm: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    firm: state.firm,
});

export default connect(mapStateToProps, {getFirms})(Firms);
