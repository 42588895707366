import React, {useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import moment from "moment/moment";
import {Plus} from "react-feather";
import {getParams} from "../../../utils/hooks/getParams";
import {getSupplier, getSupplierMaterialImports, getSupplierTransactions} from "../../../actions/supplier";
import {useDispatch, useSelector} from "react-redux";
import MoneyFormat from "../../layout/MoneyFormat";
import {getClientTransactions} from "../../../actions/client";
import {getMaterialImports, getMaterialSpends} from "../../../actions/material";
import CustomPagePagination from "../../layout/CustomPagePagination";

const MaterialSpends = () => {
    const dispatch = useDispatch();

    const {
        material,
        material_spends
    } = useSelector(state => state.material);

    console.log(material, material_spends)

    const {meta, data} = material_spends;

    console.log(meta, data)

    const {id} = material;
    useEffect(() => {
        if (id) {
            dispatch(getMaterialSpends(id, {}));
        }
    }, [getMaterialSpends, id]);

    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');


    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('from_date', event.target.value);
        url.searchParams.set('to_date', to_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getMaterialSpends(id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('to_date', event.target.value);
        url.searchParams.set('from_date', from_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getMaterialSpends(id, params))
    };
    return (
        <div className="w-100 table-responsive px-2">
            <div className="row mb-3">
                <div className="col-md-6">
                    <h4>Material sarfi</h4>
                </div>
                {/*<div className="col-md-6">*/}
                {/*    <div className="crud_actions text-end">*/}
                {/*        <button className="btn btn-primary"*/}
                {/*                onClick={() => handleShow()}>*/}
                {/*            Import*/}
                {/*            <Plus/>*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="mb-2 align-items-center d-flex
                                                        justify-content-between">
                <div className="mb-3">
                    <label htmlFor="date1"
                           className="mb-2">dan</label>
                    <input onChange={onChangeFromDate}
                           className="form-control"
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2"
                           className="mb-2">gacha</label>
                    <input onChange={onChangeToDate}
                           className="form-control"
                           id="date2" type="date"/>
                </div>
            </div>

            <table className="table custom-table table-hover">
                <thead className="table-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Mahsulot</th>
                    <th scope="col">Miqdori</th>
                    {/*<th scope="col">Narxi</th>*/}
                    <th scope="col">Sana</th>
                    {/*<th scope="col">Tahrirlash</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                {item?.product?.name}
                            </td>
                            <td>
                                {item?.spent_amount}
                            </td>
                            <td>
                                {moment(item?.date).format("YYYY-MM-DD")}
                            </td>
                        </tr>
                    ))
                }

                <tr key={"last_import"}>
                    <th scope="row">#</th>
                    <th>
                        Hamma
                    </th>
                    <th>
                        {data?.reduce((acc, cur) => acc + parseFloat(cur.spent_amount), 0)}
                    </th>
                    <th>
                        Jami
                    </th>
                </tr>
                </tbody>
            </table>


            <CustomPagePagination
                numberOfPages={meta?.last_page}
                getPageItems={getMaterialSpends}
                current_page={meta?.current_page}
            />
        </div>
    )
}

export default MaterialSpends;