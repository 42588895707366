import {Link} from "react-router-dom";
import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import PropTypes from "prop-types";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getSuppliersTransactions,
    getSupplierTransactions,
    updateTransaction
} from "../../actions/suppliers_transaction";
import CustomPagination from "../layout/CustomPagination";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import SupplierDataRow from "./SupplierDataRow";
import {Accordion} from "react-bootstrap";
import AddSupplierTransactionForm from "./AddSupplierTransactionForm";
import SupplierReport from "./SupplierReport";

const SupplierCalculations = (props) => {

    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {
        dates,
        supplier_transactions,
        loading,
        numberOfPages,
        current_page
    } = useSelector(state => state.suppliers_transaction);

    const scrollableContainer = useRef();
    useEffect(() => {
        if (scrollableContainer.current) {
            scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
        }
    }, [scrollableContainer])

    return (
        <div className={'px-4'}>
            <div className="row">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className={'fs-4'}>Tezkor<strong> tranzaksiya</strong></div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AddSupplierTransactionForm/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-6">
                    <SearchForm getPageItems={getSuppliersTransactions}/>
                </div>
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth getPageItems={getSuppliersTransactions}/>
                </div>
            </div>

            <div className="row mt-3">

                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead  ${loading ? 'data_loader' : ''}`}>

                    {
                        supplier_transactions && supplier_transactions.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Yetkazuvchi</th>
                                    {dates.map((item, index, items) => (
                                        items.length - 1 === index ? (
                                            <th key={`date${index}`} className="sticky-col"
                                                style={{textAlign: "center"}}
                                                colSpan="2">
                                                {item}
                                                {moment(new Date()).format('YYYY-MM-DD') == item ? '(bugun)' : ''}
                                            </th>
                                        ) : (
                                            <th key={`date${index}`} className="sticky-col"
                                                style={{textAlign: "center"}}
                                                colSpan="2">{item}
                                            </th>
                                        )
                                    ))}
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="4">Umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Kirim
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>chiqim
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                        O'tgan oy qoldig'i
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                        Kirim qiymati
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "100px"}}> To'langan
                                        qiymati
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "0px"}}> Shu oy qoldig'i
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {supplier_transactions.map((item, index) => (
                                    <SupplierDataRow item={item} key={item.id} index={index}
                                                     resetAllRows={{}}
                                    />
                                ))}
                                </tbody>
                            </table>
                        ) : ''
                    }

                </div>
            </div>

            <div className="row mt-3">
                <CustomPagination
                    numberOfPages={numberOfPages}
                    getPageItems={getSuppliersTransactions}
                    current_page={current_page}
                />
            </div>
            <div className="row mt-3">
                <SupplierReport/>
            </div>
        </div>

    )
}
SupplierCalculations.propTypes = {
    getSupplierTransactions: PropTypes.func.isRequired,
    supplier: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    supplier: state.supplier,
});

export default connect(mapStateToProps, {getSupplierTransactions, updateTransaction})(SupplierCalculations);

