import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteMaterialId, setUpdateMaterialId} from "../../actions/material";
import {Link, useHistory} from 'react-router-dom'
import MoneyFormat from "../layout/MoneyFormat";
import {Edit, Edit2, Eye, Trash2} from "react-feather";
import warehouses from "../warehouses/Warehouses";

const MaterialItem = ({
                          auth,
                          setDeleteMaterialId,
                          setUpdateMaterialId,
                          material: {
                              id, name, unit_alternative, unit, quantity,
                              multiplier,
                              price,
                              date: created_at,
                              firm,
                              warehouse
                          },
                          showActions,
                          number
                      }) => {

    const history = useHistory();
    const {role_name} = auth?.user || {};

    const setDeleteMaterial = (id) => {
        setDeleteMaterialId(id);
    };

    const setUpdateMaterial = (id) => {
        setUpdateMaterialId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>
                    {firm?.name}
                </td>
                <td>
                    <Link to={`/warehouses/${warehouse?.id}`}>{warehouse?.name}</Link>
                </td>
                <td>{unit?.name} </td>
                <td>{quantity}</td>
                {/*<td>{multiplier} x</td>*/}
                {/*<td> {unit_alternative?.name}</td>*/}
                <td><MoneyFormat value={price}/> so'm</td>
                <td>
                    <div className="actions">
                        <Link to={`materials/${id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' || role_name == 'storekeeper' ? (
                                <Edit onClick={() => setUpdateMaterial(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'superadmin' || role_name == 'director' ? (
                                <Trash2 onClick={() => setDeleteMaterial(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

MaterialItem.defaultProps = {
    showActions: true,
};

MaterialItem.propTypes = {
    material: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteMaterialId, setUpdateMaterialId})(
    MaterialItem
);
