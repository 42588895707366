import React, {Component, useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';


const getMonthName = (month) => {
    const months = [
        {
            id: "01",
            name: "Jan",
        },
        {
            id: "02",
            name: "Feb",
        },
        {
            id: "03",
            name: "Mar",
        },
        {
            id: "04",
            name: "Apr",
        },
        {
            id: "05",
            name: "May",
        },
        {
            id: "06",
            name: "Jun",
        },
        {
            id: "07",
            name: "Jul",
        },
        {
            id: "08",
            name: "Aug",
        },
        {
            id: "09",
            name: "Sep",
        },
        {
            id: "10",
            name: "Oct",
        },
        {
            id: "11",
            name: "Nov",
        },
        {
            id: "12",
            name: "Dec",
        }
    ];

    return months.find(item => item.id == month)?.name;
}

function getRandomColor(n) {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    var colors = [];
    for (var j = 0; j < n; j++) {
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        colors.push(color);
        color = '#';
    }
    return colors;
}

function getExpenseColor(length, initialColor = "#ff00") {
    const colors = [];
    var letters = 'ABCDEF'.split('');
    const presetColors = ['#3B7DDD', '#fcb92c', '#dc3545']
    for (var j = 0; j < length; j++) {
        let color = presetColors[Math.floor(Math.random() * 3)];

        for (var i = 0; i < 2; i++) {
            color += letters[Math.floor(Math.random() * 6)];
        }
        colors.push(color);
    }

    return colors;
}


const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const ExpenseMonthlyBarChart = (props) => {

    const {metrics} = props;

    const chartRef = useRef();
    const [chartObject, setChartObject] = useState(null);
    const [labels, setLabels] = useState(months);
    const [data, setData] = useState([10000000, 5000000, 30000000]);

    useEffect(() => {

        const ctx = chartRef.current.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        var primaryColor = '#3B7DDD';
        var warningColor = '#fcb92c';
        var dangerColor = '#dc3545';
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        var primaryBorderColor = '#3B7DDD';

        if (!chartObject) {
            setChartObject(new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "01"
                        },
                        {
                            label: "01"
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        // yAxes: [{
                        //     gridLines: {
                        //         display: false
                        //     },
                        //     stacked: false,
                        //     ticks: {
                        //         stepSize: 20
                        //     }
                        // }],
                        // xAxes: [{
                        //     stacked: false,
                        //     gridLines: {
                        //         color: "transparent"
                        //     }
                        // }]
                    }
                }
            }));
        }

    }, [])

    useEffect(() => {
        if (metrics) {
            // const labelItems = props.metrics?.map((metric) => getMonthName(metric.month));
            // const dataItems = Object.keys(props.metrics)?.map((metric) => metric);
            const datasetItems = [];
            Object.keys(props.metrics).forEach((expense, index, all_metrics) => {

                const expenses_items = props.metrics[expense];

                const dataItems = expenses_items?.map((metric) => metric.sum);
                datasetItems.push({
                    data: dataItems,
                    label: expense,
                    backgroundColor: getExpenseColor(Object.keys(props.metrics).length),
                    borderWidth: 5
                })
            })


            // setData(dataItems)
            // setLabels(months);
            var primaryColor = '#3B7DDD';
            var warningColor = '#fcb92c';
            var dangerColor = '#dc3545';
            if (chartObject) {
                chartObject.data = {
                    labels: labels,
                    datasets: datasetItems
                };
                chartObject.update();
            }
        }
    }, [metrics])

    return (
        <div className="expense_monthly_statistics">
            <canvas
                style={styles}
                id="expense_monthly_statistics"
                ref={chartRef}
            />
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '420px'
}
export default ExpenseMonthlyBarChart;