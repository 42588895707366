import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import PropTypes from "prop-types";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getMaterialOptions,
} from "../../actions/material";
import CustomPagination from "../layout/CustomPagination";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import {getParams} from "../../utils/hooks/getParams";
import FilterByMaterial from "./FilterByMaterial";
import FilterByFirm from "./FilterByFirm";
import SupplierMaterialDataRow from "./SupplierMaterialDataRow";
import {getSuppliersMaterialsTransactions} from "../../actions/suppliers_materials_transaction";

const SuppliersMaterialCalculations = (props) => {

    const dispatch = useDispatch();
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};
    const {
        dates,
        suppliers,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.suppliers_materials_transaction);


    const {material_id} = getParams();

    const scrollableContainer = useRef();
    useEffect(() => {
        if (scrollableContainer.current) {
            scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
        }
    }, [scrollableContainer])


    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                {
                    role_name === 'superadmin' || role_name === 'director' || role_name === 'accountant' ? (
                        <div className="col-md-3 align-self-start ">
                            <FilterByFirm getPageItems={getMaterialOptions} is_filter={true}/>
                        </div>
                    ) : (
                        <div className="col-md-0 align-self-start ">

                        </div>
                    )
                }
                <div className="col-md-3 align-self-start ">

                    {
                        role_name === 'manager' ? (
                            <FilterByMaterial getPageItems={getSuppliersMaterialsTransactions}
                                              getMaterialOptions={getMaterialOptions} auto={true}/>
                        ) : (
                            <FilterByMaterial getPageItems={getSuppliersMaterialsTransactions}
                                              getMaterialOptions={getMaterialOptions}/>
                        )
                    }

                </div>
                <div className="col-md-3">
                    <SearchForm getPageItems={getSuppliersMaterialsTransactions}/>
                </div>
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth getPageItems={getSuppliersMaterialsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    {
                        material_id && suppliers && suppliers.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Yetkazuvchi</th>
                                    {dates && dates.length ? (
                                        dates?.map((item, index, items) => (
                                            items.length - 1 === index ? (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">
                                                    {item}
                                                    {moment(new Date()).format('YYYY-MM-DD') == item ? '(bugun)' : ''}
                                                </th>
                                            ) : (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">{item}
                                                </th>
                                            )
                                        ))
                                    ) : (
                                        ''
                                    )
                                    }
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="4">Umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Kirim
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>chiqim
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                        O'tgan oy qoldig'i
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                        Umumiy yetkazgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                        Sarflangani
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                                        Qoldig'i
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {suppliers.map((item, index) => <SupplierMaterialDataRow index={index} item={item}
                                                                                         key={index}/>)}
                                </tbody>
                            </table>
                        ) : 'Firma va material tanlang!'
                    }

                </div>
            </div>

            <div className="row mt-3">
                <CustomPagination
                    numberOfPages={numberOfPages}
                    getPageItems={getSuppliersMaterialsTransactions}
                    current_page={current_page}
                />
            </div>
        </div>

    )
}

SuppliersMaterialCalculations.propTypes = {
    getSuppliersMaterialsTransactions: PropTypes.func.isRequired,
    getMaterialOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    // supplier: state.supplier,
    material: state.material,
});

export default connect(mapStateToProps, {
    getSuppliersMaterialsTransactions,
    getMaterialOptions
})(SuppliersMaterialCalculations);

