import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {AvField, AvForm} from "availity-reactstrap-validation";
import {addProductProduction, getProductOptions, getProductWarehouses} from "../../actions/product";
import Select from 'react-select'
import {FormGroup, Input, Label} from "reactstrap";
import {Form} from "react-bootstrap";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";
import moment from "moment";

const ProductProducingForm = ({
                                  firm_options,
                                  error,
                                  product_options,
                                  addProductProduction,
                                  getProductOptions,
                              }) => {

    const formRef = useRef();

    const [productOptions, setProductOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);
    const defaultValues = {
        firm_id: '',
        product_id: '',
        quantity: '',
        date: '',
    };
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        getFirmOptions();
    }, [getFirmOptions]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);

    useEffect(() => {
        let options = [];
        product_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setProductOptions(options);
    }, [product_options]);

    const handleChange = e => {
        if (e.target.name === 'quantity' && parseFloat(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const handleSelect = (data, type) => {
        if (type.name == 'firm_id') {
            getProductOptions({firm_id: data.value});
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await addProductProduction(inputs, getParams(true))) {
            setInputs({});
        } else {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error?.errors) {
                if (error && error?.errors) {
                    Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                }
                setErrors(errorObject);
            }
        }
    };

    useEffect(() => {

    }, [errors])


    return (
        <Fragment>

            <div className="col-md-12 col-lg-8">
                <h3 className="h4 mb-1"><strong>Yangi mahsulot</strong> ishlab chiqarish</h3>
            </div>
            <div className="col-12">

                <Form onSubmit={handleSubmit} ref={formRef}>


                    <div className="mb-3">
                        <label htmlFor="firm_id">Firma</label>
                        <Select
                            name={'firm_id'}
                            options={firmOptions}
                            value={getSelectedOption(inputs?.firm_id, firmOptions)}
                            onChange={handleSelect}
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.firm_id}
                        </div>
                    </div>

                    <div className="mb-2">
                        <label htmlFor="product_id">Mahsulot</label>
                        <Select
                            name={'product_id'}
                            options={productOptions}
                            value={getSelectedOption(inputs?.product_id || '', productOptions)}
                            onChange={handleSelect}
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.product_id}
                        </div>
                    </div>

                    {/*<div className="mb-2">*/}
                    {/*    <label htmlFor="warehouse_id">Mahsulot sklad</label>*/}
                    {/*    <Select*/}
                    {/*        name={'warehouse_id'}*/}
                    {/*        options={warehouseOptions}*/}
                    {/*        value={getSelectedOption(inputs?.warehouse_id || '', warehouseOptions)}*/}
                    {/*        onChange={handleSelect}*/}
                    {/*        defaultValue={{label: 'Tanlang', value: ''}}*/}
                    {/*    />*/}
                    {/*    <div className="error">*/}
                    {/*        {errors?.warehouse_id}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="mb-2">
                        <label htmlFor="quantity">Miqdori</label>
                        <input
                            name="quantity"
                            className={'form-control'}
                            value={inputs?.quantity || ''}
                            type="text"
                            min={1}
                            placeholder={1000}
                            onChange={handleChange}
                            required
                        />
                        <div className="error">
                            {errors?.quantity}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date">Sana</label>
                        <input
                            name="date"
                            className={'form-control'}
                            type="date"
                            value={inputs?.date || ''}
                            placeholder={'kiriting'}
                            onChange={handleChange}
                            max={moment().format('YYYY-MM-DD')}
                            // onKeyUp={e => e.target.oldValue = e.target.value}
                        />

                        <div className="error">
                            {errors?.date}
                        </div>
                    </div>


                    <div className="mb-2">
                        <Button className="" variant="primary" type="submit">Skladga qo'shish</Button>
                    </div>
                </Form>
            </div>
        </Fragment>
    );
};

ProductProducingForm.propTypes = {
    addProductProduction: PropTypes.func.isRequired,
    getProductOptions: PropTypes.func.isRequired,
    getFirmOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
    products: state.product.products,
    firm_options: state.user.firm_options,
    error: state.product.error,
    loading: state.product.loading,
    product_units: state.product.product_units,
    product_options: state.product.product_options,
    warehouse_options: state.product.warehouse_options,
});


export default connect(mapStateToProps, {
    addProductProduction,
    getProductOptions,
    getFirmOptions
})(ProductProducingForm);
