import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteUnitAlternativeId, setUpdateUnitAlternativeId} from "../../actions/unitalternative";
import {useHistory} from 'react-router-dom'

const UnitAlternativeItem = ({
                                 auth,
                                 setDeleteUnitAlternativeId,
                                 setUpdateUnitAlternativeId,
                                 unitAlternative: {
                                     id, unit, multiplier,
                                     date: created_at
                                 },
                                 showActions,
                             }) => {

    const history = useHistory();

    const setDeleteUnitAlternative = (id) => {
        setDeleteUnitAlternativeId(id);
    };

    const setUpdateUnitAlternative = (id) => {
        setUpdateUnitAlternativeId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{id}</th>
                <td>{unit?.name}</td>
                <td> <strong className="text-danger">x</strong> {multiplier}</td>
                <td>
                    <div className="actions">
                        <svg  onClick={()=>history.push(`unitalternatives/${id}`)}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-eye align-middle mr-3">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                        <svg onClick={() => setUpdateUnitAlternative(id)} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-edit align-middle mr-3">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                        <svg onClick={() => setDeleteUnitAlternative(id)} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-trash-2 align-middle mr-3">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

UnitAlternativeItem.defaultProps = {
    showActions: true,
};

UnitAlternativeItem.propTypes = {
    unitAlternative: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteUnitAlternativeId, setUpdateUnitAlternativeId})(
    UnitAlternativeItem
);
