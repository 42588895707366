import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteSalaryId, setUpdateSalaryId} from "../../actions/salary";
import CurrencyFormat from "react-currency-format";
import {Link} from "react-router-dom";
import {Edit, Edit2, Eye, Trash2} from "react-feather";

const SalaryItem = ({
                        auth,
                        setDeleteSalaryId,
                        setUpdateSalaryId,
                        index,
                        salary: {
                            id, amount, type, employee,
                            date: created_at
                        },
                        showActions,
                        number
                    }) => {

    const {role_name} = auth?.user || {};

    const setDeleteSalary = (id) => {
        setDeleteSalaryId(id);
    };

    const setUpdateSalary = (id) => {
        setUpdateSalaryId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{employee?.first_name} {employee?.last_name}</td>
                <td>
                    <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} suffix={"  so'm"}/>
                </td>
                <td>{type == "fixed" ? "Belgilangan ish haqi" : "Kunlik"}</td>
                <td>
                    <div className="actions">
                        {/*<Link to={`warehouses/${id}`}>*/}
                        {/*    <Eye/>*/}
                        {/*</Link>*/}
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' ? (
                                <Edit onClick={() => setUpdateSalary(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' ? (
                                <Trash2 onClick={() => setDeleteSalary(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

SalaryItem.defaultProps = {
    showActions: true,
};

SalaryItem.propTypes = {
    salary: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteSalaryId, setUpdateSalaryId})(
    SalaryItem
);
