import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {setDeleteWarehouseId, setUpdateWarehouseId} from "../../actions/warehouse";
import {Link, useHistory} from 'react-router-dom'
import {Edit, Edit2, Eye, Trash2} from "react-feather";

const getWarehouseType = (type) => {
    const types = {
        product: 'Mahsulot',
        part: 'Zapchast',
        material: "Material"
    };
    return types[type] || ''
}
const WarehouseItem = (props) => {

    const {number} = props;
    const {
        id,
        name,
        firm,
        storekeeper,
        type,
        date: created_at,
        showActions,
    } = props.warehouse;

    const dispatch = useDispatch();

    const {
        auth,
    } = useSelector(state => state);

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteWarehouse = (id) => {
        dispatch(setDeleteWarehouseId(id));
    };

    const setUpdateWarehouse = (id) => {

        dispatch(setUpdateWarehouseId(id));
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{firm?.name}</td>
                <td>{storekeeper?.name}</td>
                <td>{getWarehouseType(type)}</td>
                <td>
                    <div className="actions">
                        <Link to={`warehouses/${id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'superadmin' || role_name == 'director' || role_name == 'manager' ? (
                                <Edit onClick={() => setUpdateWarehouse(id)}/>
                            ) : ''
                        }
                        {
                            role_name == 'superadmin' || role_name == 'director' ? (
                                <Trash2 onClick={() => setDeleteWarehouse(id)}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

WarehouseItem.defaultProps = {
    showActions: true,
};

WarehouseItem.propTypes = {
    warehouse: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteWarehouseId, setUpdateWarehouseId})(
    WarehouseItem
);
