import {
    ADD_PRODUCT_DELIVERY, CLEAR_ERRORS, GET_CLIENT_AND_DRIVER_ID_DATA,
    GET_PRODUCT_DELIVERIES,
    GET_PRODUCT_DELIVERY,
    PRODUCT_DELIVERY_ERROR,
    REMOVE_DELETE_PRODUCT_DELIVERY,
    REMOVE_UPDATE_PRODUCT_DELIVERY,
    SET_DELETE_PRODUCT_DELIVERY_ID,
    SET_UPDATE_PRODUCT_DELIVERY_ID
} from '../actions/types';

const initialState = {
    product_deliveries: [],
    client_options: [],
    driver_options: [],
    transport_options: [],
    product_delivery: {},
    update_product_delivery: null,
    delete_product_delivery: null,
    loading: false,
    numberOfPages: 1,
    total_product_deliveries: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_CLIENT_AND_DRIVER_ID_DATA:
            return {
                ...state,
                client_options: payload.client_options,
                driver_options: payload.driver_options,
                transport_options: payload.transport_options,
                loading: false,
            };

        case GET_PRODUCT_DELIVERY:
            return {
                ...state,
                product_delivery: payload.data,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_DELIVERIES:
            return {
                ...state,
                product_deliveries: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_product_deliveries: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_PRODUCT_DELIVERY:
            return {
                ...state,
                product_deliveries: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_PRODUCT_DELIVERY:
            return {
                ...state,
                delete_product_delivery: null,
            };

        case REMOVE_UPDATE_PRODUCT_DELIVERY:
            return {
                ...state,
                update_product_delivery: null,
            };

        case SET_DELETE_PRODUCT_DELIVERY_ID:

            return {
                ...state,
                delete_product_delivery: state.product_deliveries.find((item) => item.id === action.payload.product_deliveryID)
            };

        case SET_UPDATE_PRODUCT_DELIVERY_ID:
            return {
                ...state,
                update_product_delivery: state.product_deliveries.find((item) => item.id === action.payload.product_deliveryID)
            };

        case PRODUCT_DELIVERY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
