import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import PropTypes from "prop-types";
import {connect, useDispatch, useSelector} from "react-redux";
import CustomPagination from "../layout/CustomPagination";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import FilterByFirm from "../suppliers_material_transactions/FilterByFirm";
import ProductDataRow from "./ProductDataRow";
import {getProductsTransactions, updateTransaction, downloadProductReport} from "../../actions/products_transaction";
import ProductReport from "./ProductReport";

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const ProductCalculations = (props) => {

    // const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();

    const resetAllRows = (e) => {

        forceUpdate();
    }
    const {
        auth
    } = useSelector(state => state);

    const {role_name} = auth?.user || {};

    const {
        dates,
        products,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.products_transaction);

    const scrollableContainer = useRef();
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        if (products && products.length > 0 && products[0].hasOwnProperty('transactions')) {
            if (isFirst == true && scrollableContainer.current) {
                scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
                setIsFirst(false);
            }
        }
    }, [products])

    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-3">
                    <SearchForm getPageItems={getProductsTransactions}/>
                </div>
                {
                    role_name !== 'manager' && role_name !== 'storekeeper' ? (
                        <div className="col-md-2">
                            <FilterByFirm getPageItems={getProductsTransactions}/>
                        </div>
                    ) : ''
                }
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth getPageItems={getProductsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">

                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    {
                        products && products.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Mahsulot</th>
                                    {
                                        dates?.map((item, index, items) => (
                                            items.length - 1 === index ? (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">
                                                    {item}
                                                    {moment(new Date()).format('YYYY-MM-DD') == item ? '(bugun)' : ''}
                                                </th>
                                            ) : (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">{item}
                                                </th>
                                            )
                                        ))
                                    }
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="4">Oy bo'yicha umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Chiqarilgan
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Sotilgan
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                        O'tgan oy qoldig'i
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                        Umumiy chiqarilgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                        Umumiy sotilgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "0px"}}> Qoldiq
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {products?.map((item, index) => (
                                    <ProductDataRow item={item} key={item.id} index={index}
                                                    resetAllRows={resetAllRows}/>
                                ))}
                                </tbody>
                            </table>
                        ) : ''
                    }

                </div>

            </div>

            <div className="row mt-3">
                <CustomPagination
                    numberOfPages={numberOfPages}
                    getPageItems={getProductsTransactions}
                    current_page={current_page}
                />
            </div>
            <ProductReport downloadProductReport={downloadProductReport}/>
        </div>

    )
}

ProductCalculations.propTypes = {
    getProductsTransactions: PropTypes.func.isRequired,
    products_transaction: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    products_transaction: state.products_transaction,
});

export default connect(mapStateToProps, {getProductsTransactions, updateTransaction})(ProductCalculations);

