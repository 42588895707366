import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import material from "../../reducers/material";
import {getMaterialOptions, clearMaterialTransactions} from "../../actions/material";

import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";

const FilterByMaterial = (props) => {

    const dispatch = useDispatch();
    const {getPageItems, getMaterialOptions} = props;

    const {
        material_options,
        load = false
    } = useSelector(state => state.material);

    useEffect(() => {
        setIsFirst(false);
    }, [])

    useEffect(() => {
        if (getMaterialOptions)
            dispatch(getMaterialOptions())
    }, [getMaterialOptions])


    const [currentMaterial, setCurrentMaterial] = useState(Number(getParams().material_id) || '');
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        const params = getParams();
        if (isFirst) {

            if (params.hasOwnProperty('material_id')) {
                setCurrentMaterial(Number(params.material_id));
            }
        }
    }, [isFirst])

    const [materialItems, setMaterialItems] = useState([
        {
            label: 'Tanlang',
            value: ''
        }
    ]);
    const handleSelect = (data, type) => {
        dispatch(clearMaterialTransactions())
        if (data.value !== '') {
            setCurrentMaterial(data.value);
            const url = new URL(window.location);
            url.searchParams.set('material_id', data.value);
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['quantity']) delete params['page'];
            dispatch(getPageItems(1, params));

        } else {
            setCurrentMaterial('');
            const url = new URL(window.location);
            url.searchParams.delete('material_id');
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['material_id']) delete params['material_id'];
            dispatch(getPageItems(1, params));
        }
    };
    useEffect(() => {
        if (material_options.length > 0) {
            let items = [
                {
                    label: 'Tanlang',
                    value: ''
                }
            ];
            material_options.map(item => items.push({
                label: item?.name,
                value: item?.id
            }))
            setMaterialItems(items);
        }
    }, [material_options])
    return (
        <Fragment>

            <Select styles={customStyles}
                    value={getSelectedOption(currentMaterial, materialItems)}
                    options={materialItems}
                    onChange={handleSelect}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
            />
        </Fragment>
    )
}
const customStyles = {
    ///.....
    menuPortal: provided => ({...provided, zIndex: 9999}),
    menu: provided => ({...provided, zIndex: 9999})
    ///.....
}
export default FilterByMaterial;