import {
    CLEAR_ERRORS,
    GET_EMPLOYEE_TRANSACTIONS,
    GET_EMPLOYEES_TRANSACTIONS, GET_PRODUCT_TRANSACTIONS, GET_PRODUCTS_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING,
    UPDATE_TRANSACTION_SUCCESS
} from '../actions/types';

const initialState = {
    dates: [],
    products: [],
    loading: false,
    numberOfPages: 1,
    total_products: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }


        case UPDATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                update_transaction_id: true,
                error: null,
                loading: false,
            };

        case GET_PRODUCTS_TRANSACTIONS:
            const {dates, products} = payload;
            return {
                ...state,
                dates: dates,
                products: products.data,
                numberOfPages: products.last_page,
                current_page: products.current_page,
                products_data: payload,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_TRANSACTIONS:
            const {id} = payload;
            return {
                ...state,
                products: state.products.map(item => item.id == id ? payload : item),
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
