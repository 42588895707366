import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import UnitAlternativeDeleteForm from "./UnitAlternativeDeleteForm";
import UnitAlternativeUpdateForm from "./UnitAlternativeUpdateForm";
import UnitAlternativeCreateForm from "./UnitAlternativeCreateForm";
import UnitAlternativeItem from "./UnitAlternativeItem";
import {getUnitAlternatives} from "../../actions/unitalternative";
import SearchForm from "../layout/SearchForm";

const UnitAlternatives = ({getUnitAlternatives, unitAlternative}) => {

    const {unitAlternatives, loading} = unitAlternative;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUnitAlternatives()
    }, []);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> chiqim o'lchov birliklari
                            ({unitAlternatives.length}) </h1>
                    </div>
                </div>

                <div className="row">
                    <SearchForm getPageItems={getUnitAlternatives}/>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Chiqim o'lchov birligi
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-plus align-middle mr-3">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">O'lchov birligi</th>
                                    <th scope="col">Ekvivalent o'lchov birligi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {unitAlternatives.map((item) => (
                                    <UnitAlternativeItem key={`unit-alternative${item.id}`} unitAlternative={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

            </div>


            <UnitAlternativeCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <UnitAlternativeDeleteForm/>

            <UnitAlternativeUpdateForm/>


        </Fragment>
    );
};


UnitAlternatives.propTypes = {
    getUnitAlternatives: PropTypes.func.isRequired,
    unitAlternative: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    unitAlternative: state.unitalternative,
});

export default connect(mapStateToProps, {getUnitAlternatives})(UnitAlternatives);
