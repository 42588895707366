import {
    ADD_EMPLOYEE, CLEAR_ERRORS,
    EMPLOYEE_ERROR,
    GET_EMPLOYEE, GET_EMPLOYEE_TRANSACTIONS,
    GET_EMPLOYEES,
    GET_FILTERED_EMPLOYEE_SALARY,
    GET_FILTERED_EMPLOYEES,
    GET_FIRM_ID_DATA, GET_PROFESSION_OPTIONS,
    REMOVE_DELETE_EMPLOYEE,
    REMOVE_UPDATE_EMPLOYEE,
    SET_DELETE_EMPLOYEE_ID,
    SET_SEARCH_QUERY,
    SET_UPDATE_EMPLOYEE_ID
} from '../actions/types';

const initialState = {
    employees: [],
    employee: {},
    firm_ID_DATA: [],
    filtered_employees: [],
    employee_transactions: [],
    profession_options: [],
    filtered_employee_salary_data_date: null,
    update_employee: null,
    delete_employee: null,
    loading: false,
    numberOfPages: 1,
    total_employees: null,
    error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_FIRM_ID_DATA:
            return {
                ...state,
                firm_ID_DATA: payload.data,
                loading: false,
            };

        case GET_PROFESSION_OPTIONS:
            return {
                ...state,
                profession_options: payload.data,
                loading: false,
            };

        case GET_EMPLOYEE:
            return {
                ...state,
                employee: payload.data,
                loading: false,
            };

        case GET_EMPLOYEES:
            return {
                ...state,
                employees: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_employees: payload.meta.total,
                error: null,
                loading: false,
            };

        case GET_FILTERED_EMPLOYEES:
            return {
                ...state,
                filtered_employees: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                error: null,
                loading: false,
            };

        case GET_EMPLOYEE_TRANSACTIONS:
            return {
                ...state,
                employee_transactions: payload.data,
                loading: false,
            };

        case ADD_EMPLOYEE:
            return {
                ...state,
                employees: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_EMPLOYEE:
            return {
                ...state,
                delete_employee: null,
            };

        case REMOVE_UPDATE_EMPLOYEE:
            return {
                ...state,
                update_employee: null,
            };

        case SET_SEARCH_QUERY:

            return {
                ...state,
                search_text: payload
            };

        case SET_DELETE_EMPLOYEE_ID:

            return {
                ...state,
                delete_employee: state.employees.find((item) => item.id === action.payload.employeeID)
            };

        case SET_UPDATE_EMPLOYEE_ID:
            return {
                ...state,
                update_employee: state.employees.find((item) => item.id === action.payload.employeeID)
            };

        case EMPLOYEE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
