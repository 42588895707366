import {
    ADD_FIRM,
    AVAILABLE_MANAGER_USER_ERROR, CLEAR_ERRORS,
    FIRM_ERROR,
    GET_AVAILABLE_MANAGER_USER,
    GET_FIRM,
    GET_FIRMS,
    REMOVE_DELETE_FIRM,
    REMOVE_UPDATE_FIRM,
    SET_DELETE_FIRM_ID,
    SET_UPDATE_FIRM_ID,
    GET_FIRM_OPTIONS
} from '../actions/types';

const initialState = {
    firms: [],
    firm: {},
    available_firms: [],
    firm_options: [],
    update_firm: null,
    delete_firm: null,
    loading: false,
    numberOfPages: 1,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_AVAILABLE_MANAGER_USER:

            return {
                ...state,
                available_firms: payload.data,
                loading: false
            };

        case AVAILABLE_MANAGER_USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_FIRM:
            return {
                ...state,
                firm: payload.data,
                error: null,
                loading: false,
            };

        case GET_FIRMS:
            return {
                ...state,
                firms: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                error: null,
                loading: false,
            };

        case GET_FIRM_OPTIONS:
            return {
                ...state,
                firms: payload.data,
                error: null,
                loading: false,
            };

        case ADD_FIRM:
            return {
                ...state,
                firms: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_FIRM:
            return {
                ...state,
                delete_firm: null,
            };

        case REMOVE_UPDATE_FIRM:
            return {
                ...state,
                update_firm: null,
            };

        case SET_DELETE_FIRM_ID:

            return {
                ...state,
                delete_firm: state.firms.find((item) => item.id === action.payload.firmID)
            };

        case SET_UPDATE_FIRM_ID:
            return {
                ...state,
                update_firm: state.firms.find((item) => item.id === action.payload.firmID)
            };

        case FIRM_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
