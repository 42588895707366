import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteSalary, setDeleteSalaryId} from "../../actions/salary";

const SalaryDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_salary, currentPage, numberOfPages, salaries} = useSelector(state => state.salary);

    const closeModal = () =>{
        dispatch(setDeleteSalaryId(null))
    };

    let getPage = null;

    const onConfirm = () =>{

        if ((currentPage == numberOfPages) && (salaries.length == 1) ){
            getPage = (currentPage - 1);
        }
        else{
            getPage = currentPage;
        }
        dispatch(deleteSalary(delete_salary.id, getPage))
    };

    let condition = false;
    if(delete_salary !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white" >Maoshni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu ish haqqini o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SalaryDeleteForm;