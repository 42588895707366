import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
// import {deleteProduct, setDeleteProductId} from "../../actions/product";
import {deleteProductTemplate, setDeleteProductTemplateId} from "../../actions/product_template";

const ProductTemplateDeleteForm = () => {

    const dispatch = useDispatch();

    const {
        delete_product_template,
        currentPage,
        numberOfPages,
        product_templates
    } = useSelector(state => state.product_template);

    const closeModal = () => {
        // removing product from redux state by sending null setDeleteProductTemplateId
        dispatch(setDeleteProductTemplateId(null))
    };

    let getPage = null;

    const onConfirm = () => {

        if ((currentPage == numberOfPages) && (product_templates.length == 1)) {
            getPage = (currentPage - 1);
        } else {
            getPage = currentPage;
        }
        dispatch(deleteProductTemplate(delete_product_template.id, getPage))
    };

    let condition = false;
    if (delete_product_template !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Mahsulot shablonini o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu mahsulotni o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductTemplateDeleteForm;