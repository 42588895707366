import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUser, setDeleteUserId, setUpdateUserId} from "../../actions/user";
import {Link, useHistory} from 'react-router-dom';
import {Edit, Eye, Trash2} from "react-feather";

const UserItem = ({
                      auth,
                      setDeleteUserId,
                      setUpdateUserId,
                      getUser,
                      index,
                      user: {id, name, email, role, date: created_at, firm},
                      showActions,
                      number
                  }) => {

    const history = useHistory();

    const {role_name} = auth?.user || {};

    const setDeleteUser = (id) => {
        setDeleteUserId(id);
    };

    const setUpdateUser = (id) => {
        setUpdateUserId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{email}</td>
                <td>{firm?.name}</td>
                <td>{role?.title}</td>
                <td>
                    <div className="actions">
                        <Link to={`users/${id}`}>
                            <Eye/>
                        </Link>
                        {
                            role_name == 'director' || role_name == 'superadmin' || role_name == 'manager' ? (
                                <Edit onClick={() => {
                                    setUpdateUser(id);
                                }}/>
                            ) : ''
                        }
                        {
                            role_name == 'director' || role_name == 'superadmin' ? (
                                <Trash2 onClick={() => {
                                    setDeleteUser(id);
                                }}/>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

UserItem.defaultProps = {
    showActions: true,
};

UserItem.propTypes = {
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    setDeleteUserId,
    setUpdateUserId,
    getUser
})(
    UserItem
);
