import moment from "moment";
import {useEffect, useState} from "react";
import YearPicker from "react-year-picker";
import {downloadSingleProductReport} from "../../actions/product";
import {useDispatch} from "react-redux";

const yearInputStyle = {padding: '6px 0', fontSize: '16px'};


const SingleProductReport = ({id}) => {
    const dispatch = useDispatch();
    const [type, setType] = useState('');
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    useEffect(() => {

    }, [type]);
    useEffect(() => {

    }, [year]);

    const [fileFormat, setFileFormat] = useState('');


    const onDownload = async (e) => {
        e.preventDefault();
        let data = {};
        switch (type) {
            case 'range':
                data = {period_type: 'range', from_date: fromDate, to_date: toDate, file_format: fileFormat};
                break;
            case 'monthly':
                data = {period_type: 'monthly', month: month, file_format: fileFormat};
                break;
            case 'yearly':
                data = {period_type: 'yearly', year: year, file_format: fileFormat};
                break;

        }
        data.product_id = id;

        const link = await dispatch(downloadSingleProductReport(data));

        if (link !== false && link !== undefined)
            window.open(link);
    }
    return (
        <form className="row g-3">

            <div className="col-auto">
                <label htmlFor="to_date" className="visually-hidden">Hisobot turi</label>
                <select className={'form-control form-select'}
                        value={type}
                        onChange={e => setType(e.target.value)}
                >
                    <option value="">Hisobot turi</option>
                    <option value="range">Dan-Gacha</option>
                    <option value="monthly">Oylik</option>
                    <option value="yearly">Yillik</option>
                </select>
            </div>

            {
                'range' == type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="from_date" className="visually-hidden">Dan</label>
                            <input type="date" className="form-control" id="from_date"
                                   onChange={e => setFromDate(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                   value={fromDate}
                            />
                        </div>
                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">Gacha</label>
                            <input type="date" className="form-control" id="to_date"
                                   onChange={e => setToDate(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                   value={toDate}
                            />
                        </div>
                    </>
                )
            }
            {
                'monthly' == type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="month" className="visually-hidden">Dan</label>
                            <input type="month" className="form-control" id="month"
                                   onChange={e => setMonth(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM')}
                                   value={month}
                            />
                        </div>
                    </>
                )
            }
            {
                'yearly' == type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="year" className="visually-hidden">Dan</label>
                            <YearPicker style={yearInputStyle} onChange={y => setYear(y)}/>
                        </div>
                    </>
                )
            }

            {
                type != '' && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">Fayl formati</label>
                            <select className={'form-control form-select'}
                                    value={fileFormat}
                                    onChange={e => setFileFormat(e.target.value)}
                            >
                                <option value="">Fayl formati</option>
                                <option value="pdf">pdf</option>
                                <option value="xls">xls</option>
                                <option value="xlsx">xlsx</option>
                            </select>
                        </div>

                        <div className="col-auto">
                            <button type="button" className="btn btn-primary mb-3" onClick={onDownload}>Yuklash</button>
                        </div>
                    </>
                )
            }
        </form>
    )
}

export default SingleProductReport;