import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateSparePartId, updateSparePart, getWarehouseOptions} from "../../actions/spare_part";

const SparePartUpdateForm = () => {

    const dispatch = useDispatch();
    const {update_spare_part, currentPage, warehouse_options, unit_options} = useSelector(state => state.spare_part);

    useEffect(() => {
        dispatch(getWarehouseOptions());
    }, [getWarehouseOptions])

    const closeModal = () => {
        dispatch(setUpdateSparePartId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateSparePart(update_spare_part.id, value, currentPage));
    };

    let condition = false;
    if (update_spare_part !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Ehtiyot qismini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <AvForm model={update_spare_part} onValidSubmit={onSubmit}>

                        <AvField
                            name="name"
                            label="Nomi"
                            type="text"
                            required
                        />

                        <AvField
                            name="quantity"
                            label="Miqdori"
                            type="number"
                            required
                        />

                        <AvField
                            name="price"
                            label="Narxi"
                            type="number"
                            required
                        />

                        <AvField
                            type="select"
                            name="warehouse_id"
                            label="Sklad"
                        >
                            {
                                warehouse_options.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>

                        <AvField
                            type="select"
                            name="unit_id"
                            label="Kirim o'lchov birligi"
                        >
                            {
                                unit_options.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))
                            }
                        </AvField>


                        <div className="d-flex gap-2">
                            <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </AvForm>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SparePartUpdateForm;