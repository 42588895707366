import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/Button";
import {setUpdateUnitId, updateUnit} from "../../actions/unit";

const UnitUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_unit, currentPage} = useSelector(state => state.unit);

    const closeModal = () => {
        // removing update_unit from redux by sending null to setUpdateUnitId
        dispatch(setUpdateUnitId(null));
    };

    const refForm = useRef();
    const defaultValues = {
        name: '-',
        symbol: '',
        value: 0
    };

    const [inputs, setInputs] = useState(defaultValues);

    useEffect(() => {

        const updateData = {
            name: update_unit?.name,
            symbol: update_unit?.symbol,
            value: update_unit?.value,
        };
        setInputs(updateData);
    }, [update_unit]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            name: inputs.name,
            symbol: inputs.symbol,
            value: Number(inputs.value)
        };

        dispatch(updateUnit(update_unit.id, data, currentPage));
        refForm.current.reset();
        setInputs(defaultValues);
    };

    const handleChange = e => {
        e.target.oldValue = e.target.value;

        setInputs(prevState => {
            return ({...prevState, [e.target.name]: e.target.value})
        });
    };

    let condition = false;
    if (update_unit !== null)
        condition = true;


    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>O'lchov birligini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={refForm}>

                        <div className="mb-2">
                            <label htmlFor="name">O'lchov birligi nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />
                        </div>

                        <div className="mb-2">
                            <label htmlFor="symbol">Qisqartmasi</label>
                            <input
                                name="symbol"
                                className={'form-control'}
                                type="text"
                                value={inputs?.symbol || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />
                        </div>

                        <div className="mb-2">
                            <label htmlFor="symbol">Qiymati</label>
                            <input
                                name="value"
                                className={'form-control'}
                                type="number"
                                value={inputs?.value || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={closeModal}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UnitUpdateForm;